const workSessionEventsTableStyle = (theme) => ({
  date: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.lsyPalette.primary.mainLight,
    margin: `0 ${theme.spacing(0.5)}`
  },
  description: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.palette.text.primary
  },
  icon: {
    margin: "0",
    color: theme.lsyPalette.primary.main,
    marginRight: theme.spacing(1),
    width: "25px",
    height: "25px"
  },
  item: {
    display: "flex",
    width: "100%",
    margin: `${theme.spacing(1.5)} 0`,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.text.light,
    alignItems: "center"
  },
  link: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  pagination: {
    "float": "right",
    "display": "flex",
    "alignItems": "center",
    "width": "fit-content",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  placeholderText: {
    fontSize: theme.lsyPalette.body2
  },
  placeholderIcon: {
    fontSize: "38px"
  },
  table: {
    marginTop: theme.spacing(2)
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  time: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.lsyPalette.primary.mainLight,
    marginLeft: "auto",
    alignSelf: "start"
  }
});

export default workSessionEventsTableStyle;