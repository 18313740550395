import { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";

import { SYSTEM_LOG_TYPE, SYSTEM_LOG_CATEGORIES } from "_constants/systemLog.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { systemLogService, userService } from "_services";
import { convertToHex, getLocalTZNameFormatted, lsyRouter, isNullOrUndefined } from "_helpers";

import {
  Button,
  Divider,
  Grid,
  Skeleton,
  TablePagination,
  Typography
} from "@mui/material";

import CustomModal from "_components/Modal/CustomModal";
import Placeholder from "_components/Helper/Placeholder";

import {
  AccountCircle, FolderOff, Help, PeopleAlt, 
  PeopleOutlineOutlined, Settings, VpnKey, StackedBarChart
} from "@mui/icons-material";

import GenericLock from "assets/teleporte/GenericLock";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";
import SiteIcon from "assets/teleporte/SiteIcon";
import SwapIcon from "assets/teleporte/SwapIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/systemLogsStyle";

const useStyles = makeStyles()(styles);

function SystemLogsWidget(props) {
  const { state, setState, setEventType, getFormattedFilterOptions, baseUrl, org, history } = props;

  const { classes } = useStyles();
  const { t } = useTranslation(["systemEvents", "default", "auth"]);
  const { setValue, errors, control, clearErrors } = useForm();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const userGroupMap = lsyAdminDataContext.userGroupMap;
  const language = useSelector((state) => state.locale.language);
  moment.locale(language);

  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const setUserSummaryOpen = (userSummaryOpen) => setState({ userSummaryOpen });
  const setSortDirection = (sortDirection) => setState({ sortDirection });

  const fetchSystemLogs = useCallback(async() => {
    try {
      const options = {
        ...getFormattedFilterOptions(),
        sort: state.sortDirection,
        page: state.currentPage,
        page_size: state.rowsPerPage
      };
      const resp = await systemLogService.fetchSystemLogs(options);

      let obj = {};
      resp.data.forEach((item) => {
        if (!obj[moment(item.created_at).format("ddd, MMM DD, YYYY")]) {
          obj[moment(item.created_at).format("ddd, MMM DD, YYYY")] = [];
        }
        obj[moment(item.created_at).format("ddd, MMM DD, YYYY")].push(item);
      });

      setState({
        data: resp.data,
        formattedData: obj,
        totalData: resp.meta.pagination.total,
        loading: false
      });
    } catch(e) {
      console.warn(e);
    }
  }, [state.currentPage, state.rowsPerPage, state.sortDirection, setState, getFormattedFilterOptions]);

  useCustomCompareEffect(() => {
    setState({loading: true});
    fetchSystemLogs();
  }, [state.currentPage, state.start_date, state.end_date, state.filterVariables, state.sortDirection, state.rowsPerPage], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getLogType = (logType) => {
    let logGroup;
    let icon;
    switch(true) {
      case SYSTEM_LOG_CATEGORIES.auth.includes(logType):
        logGroup = t("systemGroups.1");
        icon = <AccountCircle className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.key.includes(logType):
        logGroup = t("systemGroups.2");
        icon = <VpnKey className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.lock.includes(logType):
        logGroup = t("systemGroups.3");
        icon = <GenericLock className={classes.icon} closedColor="black"/>;
        break;
      case SYSTEM_LOG_CATEGORIES.lock_collection.includes(logType):
        logGroup = t("systemGroups.4");
        icon = <SiteIcon className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.lock_collection_access.includes(logType):
        logGroup = t("systemGroups.5");
        icon = <SiteIcon className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.lock_group.includes(logType):
        logGroup = t("systemGroups.6");
        icon = <LockGroupIcon className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.lock_group_access.includes(logType):
        logGroup = t("systemGroups.7");
        icon = <LockGroupIcon className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.mfa.includes(logType):
        logGroup = t("systemGroups.8");
        icon = <VpnKey className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.org.includes(logType):
        logGroup = t("systemGroups.9");
        icon = <PeopleAlt className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.system_settings.includes(logType):
        logGroup = t("systemGroups.10");
        icon = <Settings className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.user.includes(logType):
        logGroup = t("systemGroups.11");
        icon = <PeopleAlt className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.user_access.includes(logType):
        logGroup = t("systemGroups.12");
        icon = <PeopleOutlineOutlined className={classes.icon}/>;
        break;
      case SYSTEM_LOG_CATEGORIES.report.includes(logType):
        logGroup = t("systemGroups.13");
        icon = <StackedBarChart className={classes.icon}/>;
        break;
      default:
        logGroup = t("systemGroups.99");
        icon = <Help className={classes.icon}/>;
    }

    return {logGroup: logGroup, icon: icon};  
  };

  const handleUserClick = (e) => {
    if (e.metaKey) {
      window.open(`${baseUrl}/${org}${lsyRouter("user", state.userData.membership_id)}`, "_blank", "noreferrer");
    } else {
      history.push(lsyRouter("user", state.userData.membership_id));
    }
  };

  const renderUserSummary = () => {
    return (
      <>
        {state.userData.email ? <div className={classes.maxSize}><b>{`${t("default:form.email")}: `}</b><span>{state.userData.email}</span><br/></div> : null}
        {state.userData.username ? <div className={classes.maxSize}><b>{`${t("auth:login.username")}: `}</b><span>{state.userData.username}</span><br/></div> : null}
        {state.userData.user_group ? <div className={classes.maxSize}><b>{`${t("default:label.role")}: `}</b><span>{state.userData.user_group.charAt(0).toUpperCase() + state.userData.user_group.slice(1)}</span><br/></div> : null}
        {state.userData?.last_seen?.web ? <div className={classes.maxSize}><b>{`${t("default:form.lastSeen")}: `}</b><span>{moment(state.userData?.last_seen?.web).format("MMM DD, YYYY")}</span><br/></div> : null}
      </>
    );
  };  

  const handleUserSummary = async (e, user, icon) => {
    setUserAnchorEl(e.currentTarget);
    try {
      let result = await userService.fetchUser({id: user.membership_id});
      setState({
        userData: result.data,
        calloutIcon: icon,
        userSummaryOpen: true
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setState({currentPage: newPage + 1});
  };

  const handleRowChange = (event) => {
    setState({
      rowsPerPage: parseInt(event.target.value, 10),
      currentPage: 1
    });
  };

  const handleDirectionChange = () => {
    if (state.sortDirection === "DESC") {
      setSortDirection("ASC");
    } else {
      setSortDirection("DESC");
    }
  };

  const renderDirectionText = () => {
    if (state.sortDirection === "DESC") {
      return t("default:label.viewingLatest");
    } else {
      return t("default:label.viewingOldest");
    }
  };

  const handleLogGroupClick = (logType, name) => {
    setEventType([{label: name, value: logType}]);
  };

  const getSystemSettingsChanges = (log) => {
    let toggleVar = log.data.modify;
    let identifier = "modify";

    if (Object.keys(log.data).includes("remove")) {
      toggleVar = log.data.remove;
      identifier = "remove";
    } else if (Object.keys(log.data).includes("add")) {
      toggleVar = log.data.add;
      identifier = "add";
    }

    return toggleVar.map((changeString) => {
      const parts = changeString.split(";");
      const key = parts.find((part) => part.startsWith("key=")).split("=")[1];
      const oldValue = parts.find((part) => part.startsWith("from_value=")).split("=")[1]?.replace(",", " ");
      const newValue = parts.find((part) => part.startsWith("to_value=")).split("=")[1]?.replace(",", " ");

      if (key === "default_key_time") {
        return t("systemEvents.62_default_key_time", {
          from: oldValue,
          to: newValue
        });
      } else if (key === "authorizations_required") {
        if (identifier === "remove") {
          return t("systemEvents.62_pwl_optional");
        }
        if (identifier === "add") {
          return t("systemEvents.62_pwl_required");
        }
      } else if (key === "license_expiry_alerts") {
        return newValue === "false" ?
          t("systemEvents.62_license_expiry_alerts_disabled") :
          t("systemEvents.62_license_expiry_alerts_enabled");
      }
    });
  };

  const checkNestedTranslations = (log) => {
    let params = {...log.data};

    if (log.log_type === SYSTEM_LOG_TYPE.userAttributeChanged) {
      params = {
        attribute: t(`user.${log.data.attribute}`),
        user: log.data.user,
        from_value: log.data.from_value,
        to_value: log.data.to_value
      };
    } else if (log.log_type === SYSTEM_LOG_TYPE.userTypeChanged || log.log_type === SYSTEM_LOG_TYPE.userCreated) {
      params = {
        user: log.data.user,
        new_type: log.log_type === SYSTEM_LOG_TYPE.userCreated ?
          userGroupMap[log.data.role?.name] : userGroupMap[log.data.new_type]
      };
    } else if (log.log_type === SYSTEM_LOG_TYPE.systemSettingsUpdated) {
      const changes = getSystemSettingsChanges(log);

      params = {
        modified: changes.join("; ")
      };
    } else if ([SYSTEM_LOG_TYPE.lockDeactivation, SYSTEM_LOG_TYPE.lockReactivation].includes(log.log_type)) {
      params = {
        lock: `Lock-${convertToHex(log.data.lock_id, 4)}`
      };
    }

    return t(`systemEvents.${log.log_type}`, params);
  };

  const getFormattedLink = log => {
    if (isNullOrUndefined(log.initiator)) {
      return null;
    }

    const logInfo = getLogType(log.log_type);
    const handleLinkClick = (e) => handleUserSummary(e, log.initiator, logInfo.icon);
    
    return <>
       -- <span className={classes.link} onClick={handleLinkClick} data-testid="initiator">
        {log.initiator.name}
      </span>
    </>;
  };
  
  const renderMessage = log => {
    const isEnablingDisablingMFA = [SYSTEM_LOG_TYPE.mfaEnabled, SYSTEM_LOG_TYPE.mfaDisabled].includes(log.log_type);

    if (isEnablingDisablingMFA && !log.initiator) {
      return <div className={classes.description}>
        {t(`systemEvents.${log.log_type}`, log.data)}
      </div>;
    }

    return <Typography className={classes.description} data-testid="logMessage">
      {checkNestedTranslations(log)} {getFormattedLink(log)}
    </Typography>;
  };

  const renderListHeader = () => {
    return <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <div className={classes.description}>
          <span>
            <b>{`${t("default:label.timezone")}: `}</b>
            {`${getLocalTZNameFormatted()}`}
          </span>
        </div>
      </Grid>
      <Grid item>
        <TablePagination
          className={classes.pagination}
          data-testid="pagination"
          component="div"
          count={state.totalData || 0}
          rowsPerPage={state.rowsPerPage}
          page={state.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 15, 25, 50]}
          onRowsPerPageChange={handleRowChange}
        />
      </Grid>
    </Grid>;
  };

  const renderList = () => {
    return <div>
      {state.loading ? 
        [...Array(state.rowsPerPage)].map((v, i) => {
          return (
            <div className={classes.logItem} key={i}>
              <Skeleton className={classes.skeleton} variant="circular" width={40} height={40}/>
              <div className={classes.logTextContainer}>
                <div className={classes.header}>
                  <Skeleton className={classes.skeleton} variant="rectangular" width={100} height={20}/>
                  <div className={classes.time}><Skeleton className={classes.skeleton} variant="rectangular" width={50} height={20}/></div>
                </div>
                <Skeleton className={classes.skeleton} variant="rectangular" width={200} height={15}/>
              </div>
            </div>);
        }) : 
        <div>
          {isEmpty(state.formattedData) ? 
            <div className={classes.placeholder} data-testid="placeholder">
              <Placeholder 
                message={t("default:error.noRecords")} 
                classNameMessage={classes.placeholderText} 
                icon={<FolderOff/>} 
                classNameIcon={classes.placeholderIcon}
              />
            </div> : 
            Object.entries(state.formattedData).map((v, i) => {
              return (
                <div className={classes.timePeriod} key={i}>
                  <div className={classes.date}>{v[0]}</div>
                  <Divider className={classes.divider} />
                  {v[1].map((v, j) => {
                    let logInfo = getLogType(v.log_type);
                    return (
                      <div className={classes.logItem} key={j}>
                        {logInfo.icon}
                        <div className={classes.logTextContainer}>
                          <div className={classes.header}>
                            <div className={classes.action} onClick={() => handleLogGroupClick(v.category_id, logInfo.logGroup)}>
                              <b>{logInfo.logGroup}</b>{v.data?.interface ? ` (${t("interface." + v.data.interface)})` : ""}
                            </div>
                            <div className={classes.time}>{moment.utc(v.created_at).local().format("h:mm:ss a")}</div>
                          </div>
                          { renderMessage(v)}
                        </div>
                      </div> 
                    );
                  })
                  }
                </div>
              );
            })
          }
        </div>
      }
    </div>;
  };

  const renderListFooter = () => {
    return <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Button
          onClick={handleDirectionChange}
          className={classes.button}
          size="small"
          startIcon={<SwapIcon className={classes.swapIcon} pathClassName={classes.red} sortDirection={state.sortDirection} width="20px"/>}
        >
          {renderDirectionText()}
        </Button>
      </Grid>
      <Grid item>
        <TablePagination
          className={classes.pagination}
          data-testid="pagination"
          component="div"
          count={state.totalData || 0}
          rowsPerPage={state.rowsPerPage}
          page={state.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 15, 25, 50]}
          onRowsPerPageChange={handleRowChange}
        />
      </Grid>
    </Grid>;
  };

  return (
    <Grid container direction="column">
      <Grid item data-testid="listHeaderSection">
        {renderListHeader()}
      </Grid>
      <Grid item data-testid="listContentSection">
        {renderList()}
      </Grid>
      <Grid item data-testid="listFooterSection">
        {renderListFooter()}
      </Grid>
      <CustomModal
        open={state.userSummaryOpen}
        setOpen={setUserSummaryOpen}
        handleSubmit={handleUserClick}
        anchorEl={userAnchorEl}
        type="customPopover"
        title={`${state.userData.first_name} ${state.userData.last_name}`}
        calloutIcon={state.calloutIcon}
        confirm={t("default:label.viewProfile")}
        manualClose
        description={renderUserSummary()}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        confirmButton
        setValue={setValue}
        modalStyle={classes.modal}
        submit
      />
    </Grid>
  );

}

SystemLogsWidget.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  setEventType: PropTypes.func,
  getFormattedFilterOptions: PropTypes.func,
  baseUrl: PropTypes.string,
  org: PropTypes.string,
  history: PropTypes.object
};

export default SystemLogsWidget;