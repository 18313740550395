import { enterpriseTenantLicenseFeatures, keylessTenantLicenseFeatures } from "./license.constants";
import { isNullOrUndefined } from "_helpers/utility";


export const MIN_NAME_LENGTH = 3;
export const MAX_NAME_LENGTH = 30;

export const tenantLicenseOptions = Object.freeze({
  archiveDistributor: { name: "Archive - Distributor", value: 70 },
  archiveEndUser: { name: "Archive - End User", value: 71 },
  archiveIntegrator: { name: "Archive - Integrator", value: 72 },
  archiveInternal: { name: "Archive - Internal", value: 73 },
  archiveOEM: { name: "Archive - OEM", value: 74 },
  archivePOC: { name: "Archive - POC", value: 75 },
  archiveSaleAgent: { name: "Archive - Sale Agent", value: 76 },
  distributorDemo: { name: "Distributor - Demo", value: 1 },
  distributorInventoryStaging: { name: "Distributor - Inventory/Staging", value: 0 },
  endUserBasic: { name: "End User - Basic", value: 13, features: keylessTenantLicenseFeatures },
  endUserEnterprise: { name: "End User - Enterprise", value: 11, features: enterpriseTenantLicenseFeatures },
  endUserFlex: { name: "End User - Flex", value: 12 },
  endUserKeyless: { name: "End User - Keyless", value: 14, features: keylessTenantLicenseFeatures },
  endUserStandard: { name: "End User - Standard", value: 10, features: keylessTenantLicenseFeatures },
  integratorDemo: { name: "Integrator - Demo", value: 21 },
  integratorInventoryStaging: { name: "Integrator - Inventory/Staging", value: 20 },
  integratorKeyless: { name: "Integrator - Keyless", value: 22, features: keylessTenantLicenseFeatures },
  oem: { name: "OEM", value: 30 },
  oemCabinetEnclosure: { name: "OEM - Cabinet/Enclosure", value: 32 },
  oemKeyless: { name: "OEM - Keyless", value: 34, features: keylessTenantLicenseFeatures },
  oemLockMFG: { name: "OEM - Lock MFG", value: 31 },
  oemPlatformSoftware: { name: "OEM - Platform/Software", value: 33 },
  other: { name: "Other", value: 60 },
  pocEnterprise: { name: "POC - Enterprise", value: 40, features: enterpriseTenantLicenseFeatures },
  pocFlex: { name: "POC - Flex", value: 41 },
  pocKeyless: { name: "POC - Keyless", value: 43, features: keylessTenantLicenseFeatures},
  pocStandard: { name: "POC - Standard", value: 42, features: keylessTenantLicenseFeatures },
  salesAgent: { name: "Sales Agent", value: 50 },
  sera4Internal: { name: "Sera4 Internal", value: 51 }
});

export const getTenantLicenseFeatures = (value) => {
  const licenseOption = Object.values(tenantLicenseOptions).find(option => option.value === value);

  return licenseOption ? licenseOption.features : {};
};

export const getTenantLicenseName = (value) => {
  if (isNullOrUndefined(value)) {
    return "";
  }

  const licenseOption = Object.values(tenantLicenseOptions).find(option => option.value === value);

  return licenseOption ? licenseOption.name : "unknown";
};