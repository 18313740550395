import { useContext } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { siteService, lockgroupService } from "_services/lockstasy";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter } from "_helpers";
import { hasNewFirmwareAvailable } from "_helpers/lock";
import { getLicenseName } from "_services/lockstasy/helper";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

//icons
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import LockIcons from "assets/teleporte/LockIcons";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lockCardStyle.js";

const useStyles = makeStyles()(styles);

const TYPES_WITH_ONLY_BASIC_INFO = ["site", "lock_group"];

function LockCard(props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const sera4Mode = useSelector((state) => state.auth.sera4Mode);
  const { data, type } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const isDisabled = data.deactivated;

  const removeLockFromSite = async (siteId, lockId, fetchWidgetData) => {
    try {
      await siteService.removeLock({ siteId: siteId, lockId: lockId });
      dispatch(alertActions.send(t("success.removedLock")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to remove lock", e);
      dispatch(alertActions.send(t("error.removeLock"), "error"));
    }
  };

  const removeLockFromLockgroup = async (lockgroupId, lockId, fetchWidgetData) => {
    try {
      await lockgroupService.removeLock({ id: lockgroupId, lockId: lockId });
      dispatch(alertActions.send(t("success.lockGroup.removedLock")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to remove lock", e);
      dispatch(alertActions.send(t("error.lockGroup.removeLock"), "error"));
    }
  };

  const getTypePermission = () => {
    if (type === "site") {
      return "lock_collections";
    } else if (type === "lock_group") {
      return "lock_groups";
    } else {
      return null;
    }
  };

  const handleRemoveLock = () => {
    const confirmAction = () => {
      if (type === "site") {
        removeLockFromSite(data.site.id, data.id, props.fetchWidgetData);
      } else if (type === "lock_group") {
        removeLockFromLockgroup(data.lock_group_id, data.id, props.fetchWidgetData);
      }
    };

    props.createModal({
      type: "confirm",
      confirmAction: confirmAction,
      title: t("confirmation.removeTitle"),
      description: t("confirmation.removeLock"),
      submit: true,
      confirm: t("button.confirm")
    });
  };

  const navigateToLock = () => props.history.push(lsyRouter("lock", data.id));
  const navigateToSite = () => props.history.push(lsyRouter("site", data.site.id));
  const navigateToGroup = () => props.history.push(lsyRouter("lock_group", data.lock_group_id));

  const renderLockName = () => {
    return <p className={cx(classes.name, {[classes.disabled]: isDisabled})} onClick={navigateToLock} data-testid="lockCardField">
      {data.name}
    </p>;
  };

  const renderDescription = () => {
    return !isDisabled && data.description &&
      <p className={cx(classes.description, {[classes.disabled]: isDisabled})} data-testid="lockCardField">{data.description}</p>;
  };

  const renderCardField = (field, value, action) => {
    return <Typography className={classes.pos} data-testid="lockCardField">
      <span className={cx(classes.field, {[classes.disabled]: isDisabled})}>{`${field}:`}</span>
      <span
        className={cx(classes.value, {[classes.actionLink]: action, [classes.disabled]: isDisabled})}
        onClick={action}
      >
        {value}
      </span>
    </Typography>;
  };

  const renderHardwareId = () => {
    return renderCardField(t("label.hardwareId"), data.hardware_id);
  };

  const renderHardwareType = () => {
    return renderCardField(t("label.hardwareType"), data.hardware_type);
  };

  const renderNextFirmware = () => {
    if (!hasNewFirmwareAvailable(data) || isDisabled) {
      return null;
    }

    return renderCardField(t("label.nextFirmwareVersion"), data.firmware_version_next);
  };

  const renderSite = () => {
    if (TYPES_WITH_ONLY_BASIC_INFO.includes(type) || isDisabled || !data.site?.name) {
      return null;
    }

    return <Can I="read" on="lock_collections" ability={ability}>
      {renderCardField(t("widgetField.site"), data.site.name, navigateToSite)}
    </Can>;
  };

  const renderGroup = () => {
    if (TYPES_WITH_ONLY_BASIC_INFO.includes(type) || isDisabled || !data.lock_group_name) {
      return null;
    }

    return <Can I="read" on="lock_groups" ability={ability}>
      {renderCardField(t("label.lockGroup"), data.lock_group_name, navigateToGroup)}
    </Can>;
  };

  const renderLicense = () => {
    if (TYPES_WITH_ONLY_BASIC_INFO.includes(type) || isDisabled || !sera4Mode) {
      return null;
    }

    return renderCardField(t("widgetField.license"), t(getLicenseName(data.license).name));
  };

  const renderTags = () => {
    if (TYPES_WITH_ONLY_BASIC_INFO.includes(type) || isDisabled) {
      return null;
    }

    return <Can I="read" on="tags" ability={ability}>
      <div className={classes.chips}>
        {data.tags?.length > 0 ? (
          data.tags.map((tag, index) => {
            return (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                key={`${tag.name}-${index}`}
                title={`${t("label.showLocksTaggedWith")} ${tag.name}`}
              >
                <Chip
                  className={classes.chip}
                  label={<span className={classes.chipText}>{tag.name}</span>}
                  onClick={() => {
                    const newState = {
                      ...props.state.filterVariables,
                      tag_ids: [{ id: tag.id, name: tag.name }]
                    };
                    props.setState({
                      filterVariables: newState,
                      currentPage: 1,
                      filterActive: true
                    });
                    props.reset(newState);
                    props.updateUrlQuery(newState);
                  }
                  }
                />
              </Tooltip>
            );
          })
        ) : null}
      </div>
    </Can>;
  };

  const renderRemoveButton = () => {
    if (type !== "site" && type !== "lock_group") {
      return null;
    }

    return <Can I="update" on={getTypePermission()} ability={ability}>
      <Tooltip
        title={data.semaphore_locked ? t("error.lock.locked") : t("button.removeLock")}
        classes={{ tooltip: classes.tooltip }}
      >
        <span>
          <IconButton
            disabled={data.semaphore_locked}
            onClick={handleRemoveLock}
            className={classes.remove}
            aria-label={t("button.removeLock")}
            size="large">
            <RemoveIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Can>;
  };

  return (
    <Card className={classes.card}>
      <div className={classes.lockCardContent} data-testid="lockCard">
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <div className={classes.cardIcon}>
            <LockIcons
              type={data.hardware_type}
              defaultStyle={classes.defaultIcon}
              disabled={isDisabled}
              hasFirmwareUpgrade={hasNewFirmwareAvailable(data)}
              firmwareIconSize='sm'
              height="60px"
              width="60px"
            />
          </div>
        </Box>
        <CardContent className={classes.cardContent}>
          {renderLockName()}
          {renderDescription()}
          {renderHardwareId()}
          {renderHardwareType()}
          {renderNextFirmware()}
          {renderSite()}
          {renderGroup()}
          {renderLicense()}
          {renderTags()}
        </CardContent>
        {renderRemoveButton()}
      </div>
    </Card>
  );
}

LockCard.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  ability: PropTypes.object,
  updateUrlQuery: PropTypes.func
};

export default LockCard;
