export const NOTIFICATION_TYPE = Object.freeze({
  latchSensor: 1,
  doorSensor: 2,
  lowBattery: 3,
  accessRequest: 4,
  wsCloseConfirmation: 5,
  wsTimeExceeded: 6,
  lockNote: 7,
  openLock: 8,
  lockReplacementStart: 11,
  lockReplacementFinished: 12,
  lockReplacementFail: 13,
  lockDeactivation: 22,
  lockReactivation: 23
});

export const notificationGroups = Object.freeze([
  {
    name: "userNotifications.name.accessRequest",
    title: "userNotifications.title.accessRequest",
    type: "access_request",
    api_type: NOTIFICATION_TYPE.accessRequest,
    api_label: "lock",
    description: "userNotifications.description.accessRequest",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.locks",
    type: "locks",
    description: "None",
    data: [
      {
        name: "userNotifications.name.openLock",
        title: "userNotifications.title.openLock",
        type: "open_lock",
        api_type: NOTIFICATION_TYPE.openLock,
        api_label: "lock",
        description: "userNotifications.description.openLock",
        hasSelectAll: true,
        typeAhead: true
      },
      {
        name: "userNotifications.name.lockGroups", 
        title: "userNotifications.title.lockGroups", 
        type: "lock_group",
        api_type: NOTIFICATION_TYPE.openLock,
        api_label: "lock_group",
        description: "userNotifications.description.lockGroups",
        hasSelectAll: false,
        typeAhead: true
      }
    ]
  }, 
  {
    name: "userNotifications.name.latchSensor",
    title: "userNotifications.title.latchSensor",
    type: "latch_sensor",
    api_type: NOTIFICATION_TYPE.latchSensor,
    api_label: "lock",
    description: "userNotifications.description.latchSensor",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.doorSensor",
    title: "userNotifications.title.doorSensor",
    type: "door_sensor",
    api_type: NOTIFICATION_TYPE.doorSensor,
    api_label: "lock",
    description: "userNotifications.description.doorSensor",
    hasSelectAll: true,
    typeAhead: true
  },
  {
    name: "userNotifications.name.lowBattery",
    title: "userNotifications.title.lowBattery",
    type: "low_battery",
    api_type: NOTIFICATION_TYPE.lowBattery,
    api_label: "lock",
    description: "userNotifications.description.lowBattery",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.workSessions",
    type: "work_sessions",
    description: "None",
    data: [
      {
        name: "userNotifications.name.closeConfirmation",
        title: "userNotifications.title.closeConfirmation",
        type: "work_session_without_close_confirmation",
        api_type: NOTIFICATION_TYPE.wsCloseConfirmation,
        api_label: "user",
        description: "userNotifications.description.closeConfirmation",
        hasSelectAll: true,
        typeAhead: true
      }, 
      {
        name: "userNotifications.name.timeExceeded",
        title: "userNotifications.title.timeExceeded",
        type: "work_session_time_exceeded",
        api_type: NOTIFICATION_TYPE.wsTimeExceeded,
        api_label: "user",
        description: "userNotifications.description.timeExceeded",
        hasSelectAll: true,
        typeAhead: true
      }
    ]
  }, 
  {
    name: "userNotifications.name.lockNote",
    title: "userNotifications.title.lockNote",
    type: "lock_note",
    api_type: NOTIFICATION_TYPE.lockNote,
    api_label: "lock",
    description: "userNotifications.description.lockNote",
    hasSelectAll: true,
    typeAhead: true
  },
  {
    name: "userNotifications.name.lockReplacement",
    title: "userNotifications.title.lockReplacement",
    type: "lock_replacement",
    api_type: [
      NOTIFICATION_TYPE.lockReplacementStart,
      NOTIFICATION_TYPE.lockReplacementFinished,
      NOTIFICATION_TYPE.lockReplacementFail
    ],
    api_label: "lock",
    description: "userNotifications.description.lockReplacement",
    hasSelectAll: true,
    typeAhead: true
  },
  {
    name: "userNotifications.name.lockDeactivation",
    title: "userNotifications.title.lockDeactivation",
    type: "lock_deactivation",
    api_type: [NOTIFICATION_TYPE.lockDeactivation, NOTIFICATION_TYPE.lockReactivation],
    api_label: "lock",
    description: "userNotifications.description.lockDeactivation",
    hasSelectAll: true,
    typeAhead: true
  }
]);