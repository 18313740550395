import { isEmpty } from "lodash";

import { MAX_LOCKS_QUANTITY_FILTER, OPENED_VIA, LOCK_STATUS } from "_constants/lock.constants";
import { convertToHex } from "_helpers";
import { getLicenseName } from "_services/lockstasy/helper";

export const formatLockHexIDs = (lockIds, t) => {
  if (!lockIds) {
    return {};
  }

  const ids = lockIds.split(",");
  const locksQuantity = ids.length;

  if (locksQuantity > MAX_LOCKS_QUANTITY_FILTER) {
    return { ids: ids, error: t("error.maxLockIdsFilter", { locks: locksQuantity }) };
  }

  const formattedLockIds = ids.map(id => id.includes("0x") ? id.trim() : `0x${id.trim()}`);

  return { ids: formattedLockIds };
};

export const getLockEventName = (event, openedVia) => {
  switch (openedVia) {
    case OPENED_VIA.pirSensor:
      return "accessHistory.lock.event.38";
    case OPENED_VIA.manualUnlock:
      return "accessHistory.lock.event.40";
    case OPENED_VIA.mfa:
      return "accessHistory.lock.log.47";
    case OPENED_VIA.accessCode:
      return "accessHistory.lock.log.74";
    case OPENED_VIA.latchSensor:
      return "accessHistory.lock.log.19";
    case OPENED_VIA.doorSensor:
      return "accessHistory.lock.log.20";
    case OPENED_VIA.vap:
      return "accessHistory.lock.unlockedViaVAP";
    default:
      return "accessHistory.lock.event." + event;
  }
};

export const getFormattedLicense = (lock, t) => {
  if (!lock?.license) {
    return "";
  }
  
  if (!lock.license.id) {
    lock.license.id = lock.license.license_definition_id;
  }

  const name = getLicenseName(lock?.license);
  const till = name?.expiration?.params?.till || "";
  return `${t(name.name)}${!isEmpty(till) ? " | " + till : ""}`;
};

export const getFormattedLockName = (lock) => {
  if (!lock) {
    return "";
  }

  return lock.full_identifier || `${lock.name} (${convertToHex(lock.id, 4)})`;
};

export const getLockStatuses = (t) => {
  return Object.keys(LOCK_STATUS).map(status => {
    return { name: t(`label.${status}`), value: LOCK_STATUS[status] };
  });
};

export const getLockStatusNameSera4tal = (lock) => {
  if (!lock) {
    return "";
  }

  return lock.deactivated ? "Deactivated" : "Active";
};

export const hasNewFirmwareAvailable = (lock) => {
  if (isEmpty(lock?.firmware_version_next) || lock?.deactivated) {
    return false;
  }

  const currentVersion = lock.firmware_version_current.split(".");
  const nextVersion = lock.firmware_version_next.split(".");

  return !(
    currentVersion[0] === nextVersion[0] &&
    currentVersion[1] === nextVersion[1]
  );
};