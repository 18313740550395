import { useContext, useState, useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCustomCompareEffect, useCustomCompareCallback } from "use-custom-compare";
import { isEqual } from "lodash";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import queryString from "query-string";

import { alertActions } from "_actions";
import { LOCK_STATUS } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { getLockStatuses } from "_helpers/lock";
import { accessHistoryService, reportService } from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";
import { getLocalTZ, lsyRouter, formatDateToAPI } from "_helpers";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//components
import CustomModal from "_components/Modal/CustomModal";

//icons
import { GetApp } from "@mui/icons-material";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import DvrIcon from "@mui/icons-material/Dvr";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/accessWidgetStyle.js";

const useStyles = makeStyles()(styles);

const exportOptions = Object.freeze([{
  name: "XLSX",
  value: true
},
{
  name: "CSV",
  value: false
}]);

function AccessWidget(props) {
  const { user, lock, history, isRegularUser, type } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      exportModalOpen: false,
      loading: true
    });
  const setLoading = (loading) => setState({ loading });
  const setExportModalOpen = (exportModalOpen) => setState({ exportModalOpen });
  const openExportModal = () => setExportModalOpen(true);
  const [range, setRange] = useState("30d");
  const [exportType, setExportType] = useState("date");
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const handleRange = (_, newAlignment) => {
    if (newAlignment) {
      setRange(newAlignment);
    }
  };

  const fetchAccessHistoryStats = async () => {
    try {
      if (type === "user"){
        if (!user.membership_id) 
          return;
      } else if (type === "lock"){
        if (!lock.id) 
          return;
      }

      let startDate;
      let groupBy;
      switch (range) {
        case "30d":
          startDate = moment().subtract(29, "days").startOf("day");
          groupBy = "day";
          break;
        case "12w":
          startDate = moment().subtract(12, "weeks").startOf("day");
          groupBy = "week";
          break;
        case "6m":
          startDate = moment().subtract(6, "months").startOf("day");
          groupBy = "month";
          break;
        default:
          break;
      }
      startDate = startDate.format("YYYY-MM-DD");

      let options = {
        start_date: startDate,
        end_date: moment().endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
        group_by: groupBy,
        client_timezone: getLocalTZ()
      };

      let result;

      if (type === "user"){
        options["membershipId"] = user.membership_id;
        options["lock_status"] = ability.can("read", "locks.deactivation") ? LOCK_STATUS.all : LOCK_STATUS.active;
        result = await accessHistoryService.fetchAccessHistoryStats(options);
      } else if (type === "lock"){
        options["lockId"] = lock.id;
        options["lock_status"] = LOCK_STATUS.all;
        result = await accessHistoryService.fetchLockAccessHistoryStats(options);
      }

      let data = result.data;
      Object.keys(data)?.forEach((date) => {
        let newDate = date.split(" ")[0];
        data[newDate] = data[date];
        delete data[date];
      });
      setState({ data: data, loading: false });
    } catch (e) {
      console.warn("Warning, failed to fetch user's access history data", e);
      dispatch(alertActions.send(t("error.fetchAccessHistory"), "error"));
    }
    setLoading(false);
  };

  const getXAxis = useCallback(() => {
    const count = range.substring(0, range.length - 1);
    let type;
    let axis = [];
    switch (range) {
      case "6m":
        type = "months";
        break;
      case "12w":
        type = "weeks";
        break;
      case "30d":
        type = "days";
        break;
      default:
        break;
    }

    for (let i = 0; i < count; i++) {
      axis.push(moment().subtract(i, type).endOf(type.substring(0, type.length - 1)).format("YYYY-MM-DD"));
    }
    axis = axis.reverse();

    return axis;
  }, [range]);

  const getOptions = useCustomCompareCallback(() => {
    const xAxis = getXAxis();
    const dataPoints = xAxis.map((date) => {
      return state.data[date] || 0;
    });

    const options = {
      xAxis: {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: "value",
        minInterval: 1
      },
      series: [
        {
          data: dataPoints,
          type: "line"
        }
      ],
      tooltip: {
        trigger: "axis"
      },
      grid: {
        left: "30", right: "0", top: "15"
      }
    };

    return options;
  }, [state.data, range], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    if (type === "user"){
      if (user?.membership_id) {
        setState({ user });
      }
    } else if (type === "lock"){
      if (lock?.id){
        setState({ lock });
      }
    }
    fetchAccessHistoryStats();
  }, [user, lock, range], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  
  const exportAccessHistoryData = async (data) => {
    const options = {
      export: data.xlsx ? "xlsx" : "csv"
    };
    
    try {
      if (exportType === "date") {
        options.start_date = formatDateToAPI(moment(data.start_date).startOf("day"));
        options.end_date = formatDateToAPI(moment(data.end_date).endOf("day"));
      } else {
        delete data.start_date;
        delete data.end_date;
      }

      if (type === "user") {
        await reportService.fetchLockAccess({
          membership_ids: [user.membership_id],
          ...options,
          lock_status: data.lock_status
        });
      } else if (type === "lock") {
        await reportService.fetchLockAccess({ lock_ids: [lock.id], lock_status: LOCK_STATUS.all, ...options });
      }
      dispatch(alertActions.send(t("success.exportAccessHistory")));
    } catch (e) {
      console.warn("Warning, failed to export access history", e);
      dispatch(alertActions.send(t("error.exportAccessHistory"), "error"));
    }
  };

  const handleKeyDownExportType = (event) => {
    if (event.key === "Tab") {
      setExportType(event.currentTarget.dataset.value);
    }
  };

  const handleExportTypeChange = (event) => setExportType(event.target.value);

  const exportTypeInput = <GridItem xs={12} className={classes.marginTop}>
    <FormControl variant="outlined" className={classes.itemGrid}>
      <InputLabel id="exportType">
        {t("label.exportType")}
      </InputLabel>
      <Select
        className={classes.itemBox}
        value={exportType}
        label={t("label.exportType")}
        data-testid="exportTypeId"
        onChange={handleExportTypeChange}
      >
        <MenuItem
          value="date"
          onKeyDown={handleKeyDownExportType}
        >
          {t("label.exportLogsBetweenDates")}
        </MenuItem>
        <MenuItem
          value="all"
          onKeyDown={handleKeyDownExportType}
        >
          {t("label.exportAllLogs")}
        </MenuItem>
      </Select>
    </FormControl>
  </GridItem>;

  const getExportForm = () => {
    let options = [{
      field: "xlsx",
      type: "select",
      label: t("label.exportFormat"),
      defaultValue: true,
      options: exportOptions
    }];

    if (type === "user" && ability.can("read", "locks.deactivation")) {
      options.push({
        field: "lock_status",
        type: "select",
        label: t("label.lockStatus"),
        defaultValue: LOCK_STATUS.active,
        options: getLockStatuses(t)
      });
    }

    if (exportType === "date") {
      options = options.concat([
        {
          field: "start_date",
          type: "datePicker",
          label: t("label.startDate"),
          defaultValue: moment().subtract(1, "months"),
          disableFuture: true
        },
        {
          field: "end_date",
          type: "datePicker",
          label: t("label.endDate"),
          defaultValue: moment().add(1, "days")
        }]);
    }

    return options;
  };

  const onExportSubmit = (data) => {
    if (exportType === "date" && moment(data.end_date).isSameOrBefore(data.start_date)) {
      dispatch(alertActions.send(t("error.invalidDates"), "error"));
    } else {
      setExportModalOpen(false);
      data.client_timezone = getLocalTZ();
      exportAccessHistoryData(data);
    }
  };

  const navigateToSystemLogs = () => {
    history.push(`/system_logs?${queryString.stringify({ membership_ids: [user.membership_id] })}`);
  };

  const navigateToAccessHistory = () => {
    if (type === "user") {
      history.push(lsyRouter("user_access_histories", user.membership_id));
    } else if (type === "lock") {
      history.push(lsyRouter("lock_access_histories", lock.id));
    }
  };

  const renderActionButtons = () => {
    const isAccessHistoryDisabled = type === "lock" && !lock.deactivated && !hasLockLicense("access_history", lock);

    return <>
      {type === "user" &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={t("label.showSystemActivity")}
        >
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={navigateToSystemLogs}
          >
            <DvrIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      }
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={t("label.viewAccessHistory")}
      >
        <span>
          <IconButton
            className={classes.iconButton}
            aria-label={t("label.viewAccessHistory")}
            size="small"
            disabled={isAccessHistoryDisabled}
            onClick={navigateToAccessHistory}
          >
            <FindInPageOutlinedIcon
              className={isAccessHistoryDisabled ? null : classes.icon}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={t("label.exportAccessHistory")}
      >
        <span>
          <IconButton 
            aria-label={t("label.exportAccessHistory")}
            className={classes.iconButton} 
            size="small" 
            disabled={isAccessHistoryDisabled}
            onClick={openExportModal}>
            <GetApp className={isAccessHistoryDisabled ? null : classes.icon} />
          </IconButton>
        </span>
      </Tooltip>
    </>;
  };

  const renderTitleSection = () => {
    return <div className={classes.topBar}>
      {<span className={classes.name}>{t("label.accessHistory")}</span>}
      {!isRegularUser && renderActionButtons()}
    </div>;
  };

  const renderPeriodButtons = () => {
    return <Grid container direction="column" alignItems="flex-end">
      <Grid item className={classes.toggleButtonSection}>
        <ToggleButton
          className={classes.toggleButton}
          value="30d"
          selected={range === "30d"}
          onClick={handleRange}
          aria-label="30 days">{t("button.range.30D")}</ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value="12w"
          selected={range === "12w"}
          onClick={handleRange}
          aria-label="12 weeks">{t("button.range.12W")}</ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value="6m"
          selected={range === "6m"}
          onClick={handleRange}
          aria-label="6 months">{t("button.range.6M")}</ToggleButton>
      </Grid>
    </Grid>;
  };

  return <>
    <CustomModal
      open={state.exportModalOpen}
      setOpen={setExportModalOpen}
      handleSubmit={handleSubmit(onExportSubmit)}
      title={t("label.exportAccessHistory")}
      type="formCreator"
      modalStyle={classes.modal}
      confirm={t("actions.export")}
      cancel={t("button.cancel")}
      submit
      manualClose
      errors={errors}
      control={control}
      setValue={setValue}
      description={exportTypeInput}
      formOptions={getExportForm()}
    />
    { renderTitleSection() }
    <Divider className={classes.divider} />
    { renderPeriodButtons() }
    <ReactECharts
      option={getOptions()}
      notMerge={true}
      lazyUpdate={true}
    />
  </>;
}

AccessWidget.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  lock: PropTypes.object,
  isRegularUser: PropTypes.bool,
  type: PropTypes.string
};

export default AccessWidget;
