import { useCallback, useEffect, useReducer } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { formatDateAsMMMMDDYYYYhhmmLocal } from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { isNullOrUndefined } from "_helpers/utility";
import { genericReducer } from "_reducers/general.reducer";
import { getLicenseInfoFromNote, hasLockLicense } from "_services/lockstasy/helper";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Typography
} from "@mui/material";

import {
  DescriptionOutlined
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/cards/lockNoteCardStyle";

const useStyles = makeStyles()(styles);

function LockNoteCard(props) {
  const { isLoading, isSelected, isShortCard, images, note, onClick, showLockName } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");

  const [state, setState] = useReducer(genericReducer,
    {
      image: "",
      isImgLoading: true
    }
  );

  const hasLicenseOrIsDeactivated = hasLockLicense("notes", note.related_resource) || note.related_resource?.deactivated;

  const renderSkeleton = () => {
    return <Skeleton variant="rounded" height={75} data-testid="skeleton"/>;
  };

  const fetchNoteImage = useCallback(() => {
    const img = images?.find(img => img.id === note.photos[0].id);

    if (img) {
      setState({
        image: img.data,
        isImgLoading: false
      });

    }
  }, [note?.photos, images]);

  useEffect(() => {
    if (!isEmpty(note?.photos) && hasLicenseOrIsDeactivated) {
      fetchNoteImage();
    } else {
      setState({
        image: "",
        isImgLoading: false
      });
    }
  }, [note?.photos, note?.related_resource, fetchNoteImage, hasLicenseOrIsDeactivated]);

  const getLockReplacementMessage = note => {
    const replacementDetails = note.replacement_details || {};
    const lock = replacementDetails.lock || {};
    const lockName = getFormattedLockName({...lock.before, id: lock.id});
    const replacementLock = replacementDetails.lock_replacement || {};
    const replacementLockName = getFormattedLockName({...replacementLock.before, id: replacementLock.id});

    return t(`resourceNotes.${note.message}`, { lock: lockName, replacementLock: replacementLockName });
  };

  const getLicense = note => {
    const { name, expiration } = getLicenseInfoFromNote(note);

    return {
      license: t(name),
      expiration: expiration ? t("resourceNotes.license.validTill", {expiration: expiration.params.till}) : ""
    };
  };

  const getSystemGeneratedMessage = () => {
    const deepNote = note?.note || {};

    if (deepNote.message === "lock.replacement" || deepNote.message === "lock.replaced") {
      return getLockReplacementMessage(deepNote);
    } else if (deepNote.message === "license.created") {
      return t(`resourceNotes.${deepNote.message}`, getLicense(deepNote));
    } else {
      return t(`resourceNotes.${deepNote.message}`);
    }
  };

  const renderCard = () => {
    const resource = note.related_resource || {};
    const deepNote = note.note || {};
    const lockName = getFormattedLockName(resource);
    const createdAt = formatDateAsMMMMDDYYYYhhmmLocal(note.created_at);

    const message = note.system_generated ?
      getSystemGeneratedMessage() :
      hasLicenseOrIsDeactivated ? deepNote.message : t("error.lock.license.required");

    return isShortCard ?
      <Card className={classes.shortCard} data-testid={`lockNoteCard-${note.id}`}>
        <CardActionArea onClick={onClick} data-testid={`noteCardAction-${note.id}`}>
          {state.isImgLoading ?
            <Skeleton variant="rounded" height={200} /> :
            !state.image ? null :
              <CardMedia
                data-testid={`noteImage-${note.id}`}
                component="img"
                height="200"
                src={`data:image/jpeg;base64,${state.image}`}
              />
          }
          <CardContent data-testid={`noteContent-${note.id}`}>
            { showLockName &&
              <Typography gutterBottom variant="body1" component="div" data-testid={`noteLockName-${note.id}`}>
                {lockName}
              </Typography>
            }
            <Typography variant="caption" className={classes.tableItemTime} data-testid={`noteCreatedAt-${note.id}`}>
              {createdAt}
            </Typography>
            <br/>
            { !showLockName && !isNullOrUndefined(note.user) ?
              <Typography variant="caption" className={classes.tableItemTime} data-testid={`noteCreatedBy-${note.id}`}>
                {`${t("label.by")} ${note.user?.name}`}
              </Typography> : null
            }
          </CardContent>
        </CardActionArea>
      </Card> :
      <div
        className={cx(classes.tableItem, {[classes.highlightedTableItem]: isSelected})}
        onClick={onClick}
        data-testid={`lockNoteCard-${note.id}`}
      >
        <div className={classes.noteImageContainer}>
          {state.isImgLoading ?
            <Skeleton variant="rounded" height={50} width={50} data-testid="imgSkeleton"/> :
            !state.image ?
              <DescriptionOutlined className={classes.icon}/> :
              <img className={classes.noteImage} data-testid={`noteImage-${note.id}`}
                src={`data:image/jpeg;base64,${state.image}`} 
              />
          }
        </div>
        <div className={classes.tableItemText} data-testid={`noteContent-${note.id}`}>
          {showLockName && 
            <Typography variant="body2" data-testid={`noteLockName-${note.id}`}>
              <b>{lockName}</b>
            </Typography>
          }
          { hasLicenseOrIsDeactivated || note.system_generated ?
            <div className={classes.tableItemTime} data-testid={`noteCreatedAtBy-${note.id}`}>
              {isNullOrUndefined(note.user) ?
                createdAt :
                `${createdAt} ${t("label.by")} ${note.user?.name || ""}`
              }
            </div> : null
          }
          <Typography className={classes.tableItemDesc} variant="caption" data-testid={`noteMessage-${note.id}`}>
            { message }
          </Typography>
        </div>
      </div>;
  };

  return isLoading ? renderSkeleton() : renderCard();
}

LockNoteCard.defaultProps = {
  isSelected: false,
  isLoading: false,
  showLockName: true,
  isShortCard: false
};

LockNoteCard.propTypes = {
  note: PropTypes.object,
  images: PropTypes.array,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
  showLockName: PropTypes.bool,
  isShortCard: PropTypes.bool
};

export default LockNoteCard;