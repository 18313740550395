import { useMemo, useReducer, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useCustomCompareEffect } from "use-custom-compare";
import { useTranslation } from "react-i18next";
import { isEqual, isEmpty } from "lodash";
import queryString from "query-string";

import { alertActions } from "_actions";
import { LOCK_STATUS } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { formatDateAsYYYYMMDD, formatDateToAPI, getDiff, getFilterVars, compactObj, getMomentFor } from "_helpers";
import { getLockStatuses } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";
import { userService, lockService, accessHistoryService, reportService } from "_services/lockstasy";

import {
  Chip,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@mui/material";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import ChipDateTimeRange from "_components/Date/ChipDateTimeRange";
import LsyRouter from "_components/Navigation/LsyRouter";
import AccessEventsTable from "_containers/Widgets/AccessEventsTable";
import CustomAsyncSearchForm from "_components/Form/CustomAsyncSearchForm";
import CustomModal from "_components/Modal/CustomModal";

import SwapIcon from "assets/teleporte/SwapIcon";
import { GetApp, KeyboardBackspaceRounded, GroupRounded, History } from "@mui/icons-material";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/accessHistoryStyle";
import AccessHistoryWidget from "_containers/Widgets/AccessHistoryWidget";

const useStyles = makeStyles()(styles);

const defaultInitialState = Object.freeze({
  client_timezone: "UTC",
  start_date: moment().subtract(6, "month"),
  end_date: moment(),
  exportType: false,
  search: [],
  audit_table: false,
  lock_status: LOCK_STATUS.all
});

function AccessHistory(props) {
  const { location, history, baseUrl, org, type } = props;
  const { search } = location;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const { handleSubmit, reset, setValue, errors, control, clearErrors } = useForm();
  const id = useParams().id;
  const language = useSelector((state) => state.locale.language);
  moment.locale(language);

  function decodeQueryParam(p) {
    if (Array.isArray(p)) {
      return p.map(item => {
        const [ name, id ] = item.split(",");
        return { id, name };
      });
    } else {
      const [ name, id ] = p.split(",");
      return [{ id, name }];
    }
  }

  const getInitialState = () => {
    let filterVars = getFilterVars(defaultInitialState, search);
    if (filterVars.start_date) {
      filterVars.start_date = getMomentFor(filterVars.start_date);
    }
    if (filterVars.end_date) {
      filterVars.end_date = getMomentFor(filterVars.end_date);
    }
    if (filterVars.search) {
      filterVars.search = decodeQueryParam(filterVars.search);
    }
    if (filterVars.audit_table) {
      filterVars.audit_table = filterVars.audit_table === "true";
    }
    if (Object.keys(filterVars).length > 0) {
      const newState = { ...defaultInitialState, ...filterVars };
      return newState;
    } else {
      return defaultInitialState;
    }
  };

  const initialState = getInitialState();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const canReadDeactivationLock = ability.can("read", "locks.deactivation");
  const [state, setState] = useReducer(genericReducer,
    {
      currentPage: 1,
      rowsPerPage: 25,
      totalSize: 0,
      data: [],
      filterVariables: initialState,
      searchOpen: false,
      openSelectExport: false,
      sortDirection: "desc",
      loading: true,
      selectorValues: initialState.search,
      isShowAuditTable: initialState.audit_table,
      targetName: ""
    }
  );
  const setIsShowAuditTable = isShowAuditTable => setState({ isShowAuditTable: isShowAuditTable, currentPage: 1 });

  const setFilterVariables = newFilters => setState({ filterVariables: {...state.filterVariables, ...newFilters}, currentPage: 1 });
  const setDateRange = dateRange => setFilterVariables({ start_date: dateRange[0], end_date: dateRange[1] });
  const setLockStatus = lockStatus => setFilterVariables({ lock_status: lockStatus });
  const setSearchOpen = (searchOpen) => setState({ searchOpen });
  const setSelectorValues = useCallback((selectorValues) => setState({ selectorValues }), []);
  const setOpenSelectExport = open => setState({ openSelectExport: open });
  const setSortDirection = (sortDirection) => setState({ sortDirection });
  const setCurrentPage = page => setState({ currentPage: page });
  const setRowsPerPage = rowsPerPage => setState({ rowsPerPage: rowsPerPage, currentPage: 1 });
  const handleOpenSelectExport = () => setOpenSelectExport(true);
  const handleCloseSelectExport = () => setOpenSelectExport(false);
  const handleLockStatus = (event) => setLockStatus(event.target.value);
  const lockStatusOptions = getLockStatuses(t);

  const updateUrlQuery = useCallback((data) => {
    let newState = {...state.filterVariables, start_date: state.filterVariables.start_date, end_date: state.filterVariables.end_date, ...data, audit_table: state.isShowAuditTable};

    if (!isEqual(newState, defaultInitialState) && !isEqual(newState.startDate, defaultInitialState.start_date)) {
      newState = getDiff(defaultInitialState, newState);

      if (newState?.start_date) {
        newState.start_date = formatDateAsYYYYMMDD(newState.start_date);
      }
      if (newState?.end_date) {
        newState.end_date = formatDateAsYYYYMMDD(newState.end_date);
      }
      if (newState.search) {
        newState.search = newState.search.map(v => `${v.name},${v.id}`);
      }
  
      const filterVar = compactObj(newState);
      history.replace(`/${type}/${id}/access_histories?${queryString.stringify(filterVar, { arrayFormat: "separator", arrayFormatSeparator: "|" })}`);
    } else {
      history.replace(`/${type}/${id}/access_histories`);
    }
  }, [state.filterVariables, state.isShowAuditTable, id, type, history]);

  const resetFilter = (filterVar) => {
    reset(filterVar);
    let newState = {
      ...state.filterVariables,
      [filterVar]: defaultInitialState[filterVar]
    };
    setState({
      filterVariables: newState,
      currentPage: 1,
      selectorValues: filterVar === "search" ? defaultInitialState[filterVar] : state.selectorValues
    });
    updateUrlQuery({...newState, start_date: state.filterVariables.start_date, end_date: state.filterVariables.end_date});
  };

  const onSubmit = (data) => {
    if (data.search && !isEqual(state.filterVariables.search, state.selectorValues)) {
      setState({ 
        filterVariables: { ...state.filterVariables, ...data } ,
        currentPage: 1,
        searchOpen: false,
        eventTypeOpen: false
      });
    }
    if (!isEqual(data, state.filterVariables)) {
      reset(data);
      setState({
        filterVariables: {
          ...state.filterVariables,
          ...data
        },
        currentPage: 1,
        searchOpen: false
      });
      updateUrlQuery(data);
    }
  };

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, []);

  const fetchLocksWithInput = useCallback(async (searchInput) => {
    try {
      const result = await lockService.fetchLocks({ search_name: searchInput, status: state.filterVariables.lock_status });
      return result.data.map(lock => lock ? { id: lock.id, name: lock.full_identifier } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
      return [];
    }
  }, [state.filterVariables.lock_status]);

  const fetchAccessHistory = useCallback(async() => {
    const initiator_ids = state.filterVariables.search.map(v => v.id);
    try {
      let options = {
        start_date: formatDateToAPI(state.filterVariables.start_date.startOf("day")),
        end_date: formatDateToAPI(state.filterVariables.end_date.endOf("day")),
        ...type !== "locks" && {lock_status: state.filterVariables.lock_status},
        sort: state.sortDirection,
        page: state.currentPage,
        page_size: state.rowsPerPage
      };
      let resp;
      if (type === "users") {
        options.lock_ids = initiator_ids.toString();
        resp = state.isShowAuditTable ? await accessHistoryService.fetchUserEvents(id, options) : await accessHistoryService.fetchAccessHistory(id, options);
      } else {
        options.membership_ids = initiator_ids.toString();
        resp = state.isShowAuditTable ? await accessHistoryService.fetchLockEvents(id, options) : await accessHistoryService.fetchLockAccessHistory(id, options);
      }

      setState({
        data: resp.data,
        totalSize: resp.meta.pagination.total,
        loading: false
      });
    } catch(e) {
      setState({ data: [], totalSize: 0, loading: false});
      console.warn(e);
    }
  }, [state.currentPage, state.filterVariables.end_date, state.rowsPerPage, state.sortDirection,
    state.filterVariables.start_date, id, type, state.filterVariables.search, state.isShowAuditTable,
    state.filterVariables.lock_status]
  );

  const fetchTarget = useCallback(async() => {
    try {
      let name;
      if (type === "users") {
        const resp = await userService.fetchUser({ id: id });
        const user = resp.data || {};
        name = `${user.first_name} ${user.last_name} (${user.email})`;
      } else {
        const resp = await lockService.fetchLock({ lockId: id });
        name = resp.data.full_identifier;
      }

      setState({ targetName: name });
    } catch(e) {
      console.warn(e);
    }
  }, [id, type]);

  useEffect(() => {
    fetchTarget();
  }, [type, fetchTarget]);

  useCustomCompareEffect(() => {
    setState({loading: true});
    fetchAccessHistory();
  }, [state.isShowAuditTable, state.currentPage, state.filterVariables.start_date, state.filterVariables.end_date, state.filterVariables, state.sortDirection, state.rowsPerPage], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const handleDirectionChange = () => {
    if (state.sortDirection === "desc") {
      setSortDirection("asc");
    } else {
      setSortDirection("desc");
    }
  };

  const renderDirectionText = () => {
    if (state.sortDirection === "desc") {
      return t("label.viewingLatest");
    } else {
      return t("label.viewingOldest");
    }
  };

  const handleTableChange = () => {
    setIsShowAuditTable(!state.isShowAuditTable);
  };

  const exportAccessHistory = async (event) => {
    const initiator_ids = state.filterVariables.search.map(v => v.id);
    let options = {
      export: event.target.value,
      start_date: formatDateToAPI(state.filterVariables.start_date.startOf("day")),
      end_date: formatDateToAPI(state.filterVariables.end_date.endOf("day"))
    };
    
    try {
      if (type === "users"){
        options.lock_ids = initiator_ids;
        options.membership_ids = [id];
        options.lock_status = state.filterVariables.lock_status;
      } else if (type === "locks"){
        options.membership_ids = initiator_ids;
        options.lock_ids = [id];
        options.lock_status = LOCK_STATUS.all;
      }
      await reportService.fetchLockAccess(compactObj(options));
      dispatch(alertActions.send(t("success.exportReportsData")));
    } catch (e) {
      console.warn("Warning, failed to export", e);
      let errorMsg = t("label.error");
      const response = e.response || {};
      const errors = response.data?.error?.errors || [{}];

      if (response.status === 429) {
        errorMsg = t("reports.error.manyRequests");
      } else if (response.status === 422) {
        if (errors[0].detail === "error.rows.exceeded") {
          const maxRows = errors[0].boundaries?.max || "";
          errorMsg = t("reports.error.tooManyRows", { maxRows: maxRows });
        }
      }
      dispatch(alertActions.send(errorMsg, "error"));
    }
  };
  
  const getTitles = () => {
    return type === "users" ?
      { auditTableTitle: t("features.auditUserEvents"), tableTitle: t("features.accessEvents")} :
      { auditTableTitle: t("features.auditLockEvents"), tableTitle: t("features.lockEvents")};
  };

  const displayTooltipTableTitle = () => {
    const titles = getTitles();
    return state.isShowAuditTable ? titles.tableTitle : titles.auditTableTitle;
  };

  const displayTableTitle = () => {
    const titles = getTitles();
    return state.isShowAuditTable ? titles.auditTableTitle : titles.tableTitle;
  };

  const searchTitle = useMemo(() => type === "users" ? t("label.searchLocks") : t("label.searchUsers"), [type, t]);

  const renderExportButton = () => {
    if (state.isShowAuditTable) {
      return null;
    }

    const exportLabel = () => t("actions.export");
    
    return <Chip
      className={classes.chipSelect}
      label={
        <div className={classes.header}>
          <GetApp
            className={cx(classes.chipIcon, classes.clickable)}
            onClick={handleOpenSelectExport}/>
          <Select
            data-testid="exportSelect"
            value={exportLabel()}
            className={classes.select}
            onChange={exportAccessHistory}
            open={state.openSelectExport}
            onClose={handleCloseSelectExport}
            onOpen={handleOpenSelectExport}
            renderValue={exportLabel}
            IconComponent={() => null}
            SelectDisplayProps={{ style: { paddingRight: 0 } }}
          >
            <MenuItem value={exportLabel()} className={classes.selectHiddenOption}/>
            <MenuItem value={"csv"} className={classes.selectOptions}>CSV</MenuItem>
            <MenuItem value={"xlsx"} className={classes.selectOptions}>XLSX</MenuItem>
          </Select>
        </div>}
    />;
  };

  const renderLockStatus = () => {
    const renderSelectedLockStatus = value => {
      return `${t("label.lockStatus")}: ${lockStatusOptions.find(op => op.value == value)?.name}`;
    };

    return <Select
      data-testid="lockStatusSelect"
      value={state.filterVariables.lock_status}
      className={classes.selectStatus}
      onChange={handleLockStatus}
      IconComponent={() => null}
      renderValue={renderSelectedLockStatus}
      size="small"
      SelectDisplayProps={{ style: { paddingRight: 12 }}}
    >
      {lockStatusOptions.map(option => (
        <MenuItem key={`keyStatus${option.value}`} value={option.value} className={classes.selectOptions}>
          {option.name}
        </MenuItem>
      ))}
    </Select>;
  };

  const renderFilterSection = () => {
    return <Grid container alignItems="center" justifyContent="flex-start" spacing={1} data-testid="chips">
      <Grid item>
        <ChipDateTimeRange
          startDate={state.filterVariables.start_date}
          endDate={state.filterVariables.end_date}
          setDateRange={setDateRange}
        />
      </Grid>
      {type !== "locks" && canReadDeactivationLock &&
        <Grid item>
          {renderLockStatus()}
        </Grid>
      }
      <Grid item>
        <Chip
          data-testid="chipSearch"
          label={
            <div className={classes.header}>
              {type === "locks" ? 
                <GroupRounded className={classes.chipIcon}/> : 
                <LockGroupIcon className={classes.chipIcon}/>
              }
              <div className={classes.chipText}>
                <div className={classes.searchContainer}>
                  {!isEmpty(state.filterVariables.search) ?
                    <span className={classes.searchText}>
                      {`${type === "locks" ? t("features.users") : t("features.locks")}: ${state.filterVariables.search.length}`}
                    </span> :
                    searchTitle
                  }
                </div>
              </div>
            </div>}
          onClick={() => {setSearchOpen(true);}}
          className={classes.chip}
        />
      </Grid>
      <Grid item>
        <Chip
          data-testid="chipSortDirection"
          label={
            <div className={classes.header}>
              <SwapIcon className={classes.chipIcon} pathClassName={classes.red} sortDirection={state.sortDirection.toUpperCase()} width="20" height="20"/>
              <p className={classes.chipText}>
                <span>{state.sortDirection ? <span>{renderDirectionText()}</span> : t("label.sortDirection")}</span>
              </p>
            </div>}
          onClick={handleDirectionChange}
          className={classes.chip}
        />
      </Grid>
      <Grid item>
        {renderExportButton()}
      </Grid>
    </Grid>;
  };

  return (
    <div className={classes.body}>
      <GridContainer className={classes.goBackContainer}>
        <GridItem xs={false} md={1}></GridItem>
        <GridItem xs={12} md={10}>
          <LsyRouter className={classes.backContainer} page={type === "users" ? "user" : "lock"} id={id} testId={"goBackTo"}>
            <KeyboardBackspaceRounded className={classes.backIcon}/>
            <span>{type === "users" ? t("button.toUserProfile") : t("button.toLockProfile")}</span>
          </LsyRouter>
        </GridItem>
        <GridItem xs={false} md={1}></GridItem>
      </GridContainer>
      <GridContainer className={classes.container}>
        <GridItem xs={false} md={1}></GridItem>
        <GridItem xs={12} md={5} className={cx(classes.systemLogContainer, classes.chartContainer)}>
          <Typography data-testid="tableTitleId" className={classes.header} variant="body1">
            <b>{displayTableTitle()}</b>
            <Tooltip title={displayTooltipTableTitle()}>
              <History onClick={handleTableChange} className={classes.histIcon} data-testid="table-change-button"/>
            </Tooltip>
          </Typography>
          <Typography className={classes.titleDescription} variant="caption">
            {state.targetName}
          </Typography>
          { renderFilterSection() }
          <AccessEventsTable 
            history={history}
            baseUrl={baseUrl}
            org={org}
            data={state.data}
            type={type}
            pagination={{
              setCurrentPage: setCurrentPage,
              setRowsPerPage: setRowsPerPage,
              rowsPerPage: state.rowsPerPage,
              totalSize: state.totalSize,
              currentPage: state.currentPage
            }}
            handleDirectionChange={handleDirectionChange}
            isShowAuditTable={state.isShowAuditTable}
            loading={state.loading}
            sortDirection={state.sortDirection}
          />
        </GridItem>
        <AccessHistoryWidget
          history={history}
          baseUrl={baseUrl}
          org={org}
          type={type}
          data={state.data}
          startDate={state.filterVariables.start_date}
          endDate={state.filterVariables.end_date}
          id={id}
          updateUrlQuery={updateUrlQuery}
          filterVariables={state.filterVariables}
          isShowAuditTable={state.isShowAuditTable}
        />
        <GridItem xs={false} md={1}></GridItem>
      </GridContainer>

      <CustomModal
        open={state.searchOpen}
        setOpen={setSearchOpen}
        handleSubmit={() => handleSubmit(onSubmit({search: state.selectorValues}))}
        handleCancel={() => resetFilter("search")}
        type="custom"
        title={searchTitle}
        confirm={t("button.apply")}
        cancel={t("button.reset")}
        manualClose
        description={
          <CustomAsyncSearchForm 
            setSelectorValues={setSelectorValues} 
            placeholder={searchTitle}
            fetchWithInput={type === "users" ? fetchLocksWithInput : fetchUsersWithInput}
            selectorValues={state.selectorValues}
          />
        }
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        cancelButton
        confirmButton
        setValue={setValue}
        modalStyle={classes.modal}
        submit
        disableBackdropClick={true}
      />
    </div>
  );
}

AccessHistory.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string,
  type: PropTypes.string
};

export default AccessHistory;