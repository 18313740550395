export const SYSTEM_LOG_TYPE = Object.freeze({
  signIn: 1,
  signOut: 2,
  userCreated: 3,
  userDeleted: 4,
  passwordChanged: 5,
  userTypeChanged: 6,
  multipleDeviceUsageChanged: 7,
  attemptedSignInFromDifferentDevice: 8,
  deviceReset: 9,
  lockReset: 10,
  lockNameChanged: 11,
  lockDescriptionChanged: 12,
  lockSecretKeyChanged: 13,
  lockKeyAdded: 14,
  lockKeyDeleted: 15,
  organizationCreated: 16,
  accountRecovered: 17,
  lockTypeChanged: 18,
  lockGroupChanged: 19,
  lockOrganizationChanged: 20,
  lockAdded: 21,
  batchOfLocksAdded: 22,
  lockDeleted: 23,
  lockGroupCreated: 24,
  lockGroupDeleted: 25,
  lockGroupRenamed: 26,
  userAddedToLockGroup: 27,
  lockGroupKeyRemoved: 28,
  lockPublicKeyRotated: 29,
  lockAddedToGroup: 30,
  lockRemovedFromGroup: 31,
  accountSuspended: 32,
  accountEnabled: 33,
  siteCreated: 34,
  siteDeleted: 35,
  siteRenamed: 36,
  siteKeyAdded: 37,
  siteKeyRemoved: 38,
  lockAddedToSite: 39,
  lockRemovedFromSite: 40,
  siteKeyDeleted: 41,
  siteKeyReferenceNumberChanged: 42,
  siteKeyCommentsChanged: 43,
  lockKeyReferenceNumberChanged: 44,
  lockKeyCommentsChanged: 45,
  organizationTokenCreated: 46,
  apiTokenChanged: 47,
  organizationTokenDeleted: 48,
  accessRequestsWebhookEnabled: 49,
  individualKeyEventsWebhookEnabled: 50,
  lockGroupKeyEventsWebhookEnabled: 51,
  siteKeysEventsWebhookEnabled: 52,
  locksWebhookEnabled: 53,
  lockEventsWebhookEnabled: 54,
  lockStatusUpdatesWebhookEnabled: 55,
  organizationUpdatesWebhookEnabled: 56,
  sitesWebhookEnabled: 57,
  userUpdatesWebhookEnabled: 58,
  webhookURLChanged: 59,
  webhookSignatureChanged: 60,
  fsuCreated: 61,
  systemSettingsUpdated: 62,
  mfaEnabled: 63,
  mfaSetupComplete: 64,
  mfaDisabled: 65,
  mfaSetupStarted: 66,
  backupCodesRegenerated: 67,
  mfaSetupCompleteAgain: 68,
  failedToAuthenticate: 69,
  accountLocked: 70,
  accountUnlocked: 71,
  lockNotesWebhookEnabled: 72,
  deviceRestrictionEnabled: 73,
  deviceRestrictionDisabled: 74,
  userAttributeChanged: 75,
  passwordRemoved: 76,
  systemLogsWebhookEnabled: 77,
  signInAttemptWithExpiredLink: 78,
  lockReplaced: 80,
  emergencyAccessCodesDownloaded: 81,
  lockDeactivation: 82,
  lockReactivation: 83,
  userReportGenerated: 90,
  lockReportGenerated: 91,
  tagReportGenerated: 92,
  systemEventReportGenerated: 93,
  keyReportGenerated: 94,
  lockAccessHistoryReportGenerated: 95,
  userAccessHistoryReportGenerated: 96
});

export const SYSTEM_LOG_CATEGORIES = Object.freeze({
  auth: [
    SYSTEM_LOG_TYPE.signIn,
    SYSTEM_LOG_TYPE.signOut,
    SYSTEM_LOG_TYPE.failedToAuthenticate
  ],
  key: [
    SYSTEM_LOG_TYPE.lockKeyAdded,
    SYSTEM_LOG_TYPE.lockKeyDeleted,
    SYSTEM_LOG_TYPE.lockKeyReferenceNumberChanged,
    SYSTEM_LOG_TYPE.lockKeyCommentsChanged,
    SYSTEM_LOG_TYPE.fsuCreated,
    SYSTEM_LOG_TYPE.emergencyAccessCodesDownloaded
  ],
  lock: [
    SYSTEM_LOG_TYPE.lockReset,
    SYSTEM_LOG_TYPE.lockNameChanged,
    SYSTEM_LOG_TYPE.lockDescriptionChanged,
    SYSTEM_LOG_TYPE.lockSecretKeyChanged,
    SYSTEM_LOG_TYPE.lockTypeChanged,
    SYSTEM_LOG_TYPE.lockOrganizationChanged,
    SYSTEM_LOG_TYPE.lockAdded,
    SYSTEM_LOG_TYPE.batchOfLocksAdded,
    SYSTEM_LOG_TYPE.lockDeleted,
    SYSTEM_LOG_TYPE.lockPublicKeyRotated,
    SYSTEM_LOG_TYPE.lockReplaced,
    SYSTEM_LOG_TYPE.lockDeactivation,
    SYSTEM_LOG_TYPE.lockReactivation
  ],
  lock_collection: [
    SYSTEM_LOG_TYPE.siteCreated,
    SYSTEM_LOG_TYPE.siteDeleted,
    SYSTEM_LOG_TYPE.siteRenamed,
    SYSTEM_LOG_TYPE.siteKeyReferenceNumberChanged,
    SYSTEM_LOG_TYPE.siteKeyCommentsChanged
  ],
  lock_collection_access: [
    SYSTEM_LOG_TYPE.siteKeyAdded,
    SYSTEM_LOG_TYPE.siteKeyRemoved,
    SYSTEM_LOG_TYPE.lockAddedToSite,
    SYSTEM_LOG_TYPE.lockRemovedFromSite,
    SYSTEM_LOG_TYPE.siteKeyDeleted
  ],
  lock_group: [
    SYSTEM_LOG_TYPE.lockGroupCreated,
    SYSTEM_LOG_TYPE.lockGroupDeleted,
    SYSTEM_LOG_TYPE.lockGroupRenamed
  ],
  lock_group_access: [
    SYSTEM_LOG_TYPE.lockGroupChanged,
    SYSTEM_LOG_TYPE.userAddedToLockGroup,
    SYSTEM_LOG_TYPE.lockGroupKeyRemoved,
    SYSTEM_LOG_TYPE.lockAddedToGroup,
    SYSTEM_LOG_TYPE.lockRemovedFromGroup
  ],
  mfa: [
    SYSTEM_LOG_TYPE.mfaEnabled,
    SYSTEM_LOG_TYPE.mfaSetupComplete,
    SYSTEM_LOG_TYPE.mfaDisabled,
    SYSTEM_LOG_TYPE.mfaSetupStarted,
    SYSTEM_LOG_TYPE.backupCodesRegenerated,
    SYSTEM_LOG_TYPE.mfaSetupCompleteAgain
  ],
  org: [
    SYSTEM_LOG_TYPE.organizationCreated
  ],
  system_settings: [
    SYSTEM_LOG_TYPE.organizationTokenCreated,
    SYSTEM_LOG_TYPE.apiTokenChanged,
    SYSTEM_LOG_TYPE.organizationTokenDeleted,
    SYSTEM_LOG_TYPE.accessRequestsWebhookEnabled,
    SYSTEM_LOG_TYPE.individualKeyEventsWebhookEnabled,
    SYSTEM_LOG_TYPE.lockGroupKeyEventsWebhookEnabled,
    SYSTEM_LOG_TYPE.siteKeysEventsWebhookEnabled,
    SYSTEM_LOG_TYPE.locksWebhookEnabled,
    SYSTEM_LOG_TYPE.lockEventsWebhookEnabled,
    SYSTEM_LOG_TYPE.lockStatusUpdatesWebhookEnabled,
    SYSTEM_LOG_TYPE.organizationUpdatesWebhookEnabled,
    SYSTEM_LOG_TYPE.sitesWebhookEnabled,
    SYSTEM_LOG_TYPE.userUpdatesWebhookEnabled,
    SYSTEM_LOG_TYPE.webhookURLChanged,
    SYSTEM_LOG_TYPE.webhookSignatureChanged,
    SYSTEM_LOG_TYPE.systemSettingsUpdated,
    SYSTEM_LOG_TYPE.lockNotesWebhookEnabled,
    SYSTEM_LOG_TYPE.systemLogsWebhookEnabled
  ],
  user: [
    SYSTEM_LOG_TYPE.userCreated,
    SYSTEM_LOG_TYPE.userDeleted,
    SYSTEM_LOG_TYPE.userTypeChanged,
    SYSTEM_LOG_TYPE.accountRecovered,
    SYSTEM_LOG_TYPE.userAttributeChanged
  ],
  user_access: [
    SYSTEM_LOG_TYPE.passwordChanged,
    SYSTEM_LOG_TYPE.multipleDeviceUsageChanged,
    SYSTEM_LOG_TYPE.attemptedSignInFromDifferentDevice,
    SYSTEM_LOG_TYPE.deviceReset,
    SYSTEM_LOG_TYPE.accountSuspended,
    SYSTEM_LOG_TYPE.accountEnabled,
    SYSTEM_LOG_TYPE.accountLocked,
    SYSTEM_LOG_TYPE.accountUnlocked,
    SYSTEM_LOG_TYPE.deviceRestrictionEnabled,
    SYSTEM_LOG_TYPE.deviceRestrictionDisabled,
    SYSTEM_LOG_TYPE.passwordRemoved,
    SYSTEM_LOG_TYPE.signInAttemptWithExpiredLink
  ],
  report: [
    SYSTEM_LOG_TYPE.userReportGenerated,
    SYSTEM_LOG_TYPE.lockReportGenerated,
    SYSTEM_LOG_TYPE.tagReportGenerated,
    SYSTEM_LOG_TYPE.systemEventReportGenerated,
    SYSTEM_LOG_TYPE.keyReportGenerated,
    SYSTEM_LOG_TYPE.lockAccessHistoryReportGenerated,
    SYSTEM_LOG_TYPE.userAccessHistoryReportGenerated
  ]
});