import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DatePicker from "react-multi-date-picker";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { isEmpty } from "lodash";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";

import ErrorBoundary from "_components/ErrorBoundary";
import QuickTimeOptions from "./QuickTimeOptions";
import { isBrowserTimeMilitaryFormat } from "_helpers";

import { genericReducer } from "_reducers/general.reducer";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Date/dateTimeRangeStyle.js";
import "assets/css/dateTime.css";

const useStyles = makeStyles()(styles);

const inputPropsDefault = Object.freeze({
  variant: "outlined"
});

function DateTimeRange(props) {
  const { t } = useTranslation("default");
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const dateRef = useRef();
  const { minDate, maxDate, format, showTime, setStartDate, setEndDate, isRange, numberOfMonths, setDate,
    calendarProps, setDateRange, showQuickFilter, dataTestId } = props;
  const inputProps = props.inputProps ? {...inputPropsDefault, ...props.inputProps} : inputPropsDefault;
  const defaultLabel = isRange ? `${t("label.startDate")} ~ ${t("label.endDate")}` : t("label.date");
  const label = props.label || defaultLabel;
  const [dateTimeRange, setDateTimeRange] = useReducer(genericReducer,
    {
      dateRange: [
        props.startDate?.toDate(),
        props.endDate?.toDate()
      ],
      startTime: props.startDate,
      endTime: props.endDate,
      date: typeof props.date === "string" ? moment(props.date).toDate() : props.date?.toDate(),
      time: moment().local().startOf("hour"),
      openStartTime: false,
      openEndTime: false
    }
  );

  useEffect(() => {
    if (isRange) {
      setDateTimeRange({
        dateRange: [
          props.startDate?.toDate(),
          props.endDate?.toDate()
        ],
        startTime: props.startDate,
        endTime: props.endDate
      });
    } else {
      setDateTimeRange({
        date: typeof props.date === "string" ? moment(props.date).toDate() : props.date?.toDate()
      });
    }
  }, [props.startDate, props.endDate, props.date, isRange]);

  const updateStartTime = (value) => {
    if (isEmpty(value)) {
      return;
    }
    let startDate = dateTimeRange.dateRange[0];
    startDate.setHours(value.hour());
    startDate.setMinutes(value.minute());

    setDateTimeRange({
      dateRange: [
        startDate,
        ...dateTimeRange.dateRange.length > 1 ? [dateTimeRange.dateRange[1]] : []
      ],
      startTime: value
    });

    if (dateTimeRange.dateRange.length > 1) {
      setStartDate(moment(startDate));
    }
  };

  const isDisabledEndTime = () => dateTimeRange.dateRange.length < 2;

  const updateEndTime = (value) => {
    if (isEmpty(value) || isDisabledEndTime()) {
      return;
    }
    let endDate = dateTimeRange.dateRange[1];
    endDate.setHours(value.hour());
    endDate.setMinutes(value.hour() === 0 && value.minute() === 0 ? 15 : value.minute());
    endDate.setSeconds(value.seconds());

    setDateTimeRange({
      dateRange: [
        dateTimeRange.dateRange[0],
        endDate
      ],
      endTime: value
    });

    setEndDate(moment(endDate));
  };

  const copyTime = (fromDate, toDate) => {
    if (fromDate) {
      toDate.setHours(fromDate.hour());
      toDate.setMinutes(fromDate.minute());
      toDate.setSeconds(fromDate.seconds());
    }
  };

  const updateDateRange = (value) => {
    let dateRange = [];
    let startDate = value[0].toDate();

    copyTime(dateTimeRange.startTime, startDate);

    dateRange.push(startDate);

    if (value.length > 1) {
      let endDate = value[1].toDate();

      copyTime(dateTimeRange.endTime, endDate);

      dateRange.push(endDate);
      setStartDate(moment(startDate));
      setEndDate(moment(endDate));
      setDateRange([moment(startDate), moment(endDate)]);
      if (!showTime) {
        dateRef.current.closeCalendar();
      }
    }

    setDateTimeRange({ dateRange });
  };

  const updateDate = (value) => {
    let date = value.toDate();

    copyTime(dateTimeRange.time, date);

    setDateTimeRange({ date });
    setDate(moment(date));
    
    if (showTime) {
      dateRef.current.openCalendar();
    }
  };

  const updateTime = (value) => {
    if (isEmpty(value)) {
      return;
    }
    let date = dateTimeRange.date;
    date.setHours(value.hour());
    date.setMinutes(value.minute());

    setDateTimeRange({
      date: date,
      time: value
    });
    setDate(moment(date));
  };

  const generateOptions = (length, excludedOptions) => {
    const arr = [];
    for (let value = 0; value < length; value++) {
      if (excludedOptions.indexOf(value) < 0) {
        arr.push(value);
      }
    }
    return arr;
  };

  const handleDisableMinutes = (hour) => {
    const exceptions = hour === 23 ? 
      [0, 15, 30, 45, 59] :
      hour === 0 ?
        [15, 30, 45] :
        [0, 15, 30, 45];
    return generateOptions(60, exceptions);
  };

  const handleFormat = () => {
    if (!showTime || isBrowserTimeMilitaryFormat()) {
      return format;
    }

    return format.toLowerCase().includes("a") ? format : format.concat(" a").replace("HH", "hh");
  };

  const getFormattedValue = () => {
    if (isRange) {
      const dates = [];

      for (const date of dateTimeRange.dateRange) {
        if (date) {
          dates.push(moment(date).format(handleFormat()));
        }
      }

      return isEmpty(dates) ? label : dates.join(" ~ ");
    }

    return dateTimeRange.date ?
      moment(dateTimeRange.date).format(handleFormat()) :
      label;
  };

  const renderCustomInputDate = (_, openCalendar) => {
    const icon = <InputAdornment position="end">
      <InsertInvitationRoundedIcon fontSize="inherit"/>
    </InputAdornment>;

    return <TextField 
      className={classes.inputDate}
      variant={inputProps.variant}
      label={label}
      onClick={openCalendar}
      value={getFormattedValue()}
      InputProps={{ endAdornment: icon, readOnly: true, className: classes.pointer, ...inputProps }}
      inputProps={{ "data-testid": dataTestId, "className": classes.pointer }}
    />;
  };

  const setOpenStartTimePicker = () => {
    setDateTimeRange({ openStartTime: !dateTimeRange.openStartTime });
  };

  const setOpenEndTimePicker = () => {
    if (isDisabledEndTime()) {
      return;
    }

    setDateTimeRange({ openEndTime: !dateTimeRange.openEndTime });
  };

  const handleQuickTimeOptionsSetDate = (dates) => {
    setDateRange(dates);
    
    if (!showTime) {
      dateRef.current.closeCalendar();
    }
  };

  return <ErrorBoundary>
    <DatePicker
      ref={dateRef}
      className={cx(theme.palette.mode, classes.background)}
      portal="true"
      value={isRange ? dateTimeRange.dateRange : dateTimeRange.date}
      onChange={isRange ? updateDateRange : updateDate}
      range={isRange}
      numberOfMonths={numberOfMonths}
      minDate={minDate}
      maxDate={maxDate}
      highlightToday={false}
      format={handleFormat()}
      render={renderCustomInputDate}
      arrow={false}
      zIndex={1301}
      {...calendarProps}
    >
      {showQuickFilter ? 
        <div className={classes.quickTimeOptions}>
          <div>{t("description.quickFilterOptions")}:</div>
          <QuickTimeOptions
            setDateRange={handleQuickTimeOptionsSetDate}
            startDate={props.startDate}
          />
        </div> : null}
      {showTime &&
        <Grid container justifyContent="space-around" className={classes.spacing}>
          <Grid item>
            <Grid container>
              <Grid item>
                <AccessTimeOutlinedIcon className={classes.pointer} onClick={setOpenStartTimePicker} />
              </Grid>
              <Grid item>
                <TimePicker 
                  className={classes.time}
                  showSecond={false}
                  hideDisabledOptions
                  minuteStep={15}
                  defaultValue={isRange ? dateTimeRange.startTime : dateTimeRange.time}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={isRange ? updateStartTime : updateTime}
                  use12Hours={!isBrowserTimeMilitaryFormat()}
                  open={dateTimeRange.openStartTime}
                  onOpen={setOpenStartTimePicker}
                  onClose={setOpenStartTimePicker}
                />
              </Grid>
            </Grid>
          </Grid>
          {isRange &&
            <Grid item>
              <Grid container>
                <Grid item>
                  <AccessTimeOutlinedIcon 
                    className={isDisabledEndTime() ? null : classes.pointer} 
                    onClick={setOpenEndTimePicker} />
                </Grid>
                <Grid item>
                  <TimePicker
                    className={classes.time}
                    showSecond={false}
                    disabled={isDisabledEndTime()}
                    hideDisabledOptions
                    disabledMinutes={handleDisableMinutes}
                    defaultValue={props.endDate}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={updateEndTime}
                    use12Hours={!isBrowserTimeMilitaryFormat()}
                    open={dateTimeRange.openEndTime}
                    onOpen={setOpenEndTimePicker}
                    onClose={setOpenEndTimePicker}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>}
    </DatePicker>
  </ErrorBoundary>;
}

DateTimeRange.defaultProps = {
  dataTestId: "dateTimeRangeInputId",
  minDate: new Date(2014, 1, 1),
  numberOfMonths: 2,
  isRange: true,
  showTime: true,
  format: "YYYY/MM/DD HH:mm",
  setStartDate: () => {},
  setEndDate: () => {},
  setDateRange: () => {}
};

DateTimeRange.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  setDate: PropTypes.func,
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func,
  setDateRange: PropTypes.func,
  numberOfMonths: PropTypes.number,
  label: PropTypes.string,
  isRange: PropTypes.bool,
  showTime: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  format: PropTypes.string,
  inputProps: PropTypes.object,
  calendarProps: PropTypes.object,
  showQuickFilter: PropTypes.bool,
  dataTestId: PropTypes.string
};

export default DateTimeRange;