const lockNotesStyle = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  cardHeader: {
    margin: `${theme.spacing(1.5)} 0`
  },
  chip: {
    "margin": `${theme.spacing(0.25)} 0`,
    "marginRight": theme.spacing(0.5),
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& .MuiFormLabel-root": {
      display: "none"
    },
    "& input": {
      width: "140px"
    }
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.typography.chipFontSize,
    "display": "inline",
    "fontWeight": theme.lsyPalette.lightWeight,
    "& span": {
      fontWeight: theme.typography.chipFontSize
    }
  },	
  chipIcon: {
    margin: "0",
    width: "20px",
    height: "auto",
    paddingRight: theme.spacing(0.5),
    color: theme.lsyPalette.text
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "marginLeft": "auto",
    "width": "fit-content",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  maxSize: {
    maxWidth: "575px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "75vw"
    }
  },
  datePeriod: {
    color: theme.palette.text.light,
    fontSize: theme.lsyPalette.subtitle3
  },

  placeholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  placeholderIcon: {
    fontSize: "48px",
    margin: "1rem",
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: "16px",
    color: theme.lsyPalette.primary.mainDark
  },
  cardContainer: {
    position: "relative",
    height: "100%",
    borderColor: theme.palette.text.light,
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      marginBottom: theme.spacing(3),
      borderBottom: "solid",
      borderColor: theme.palette.text.light
    }
  },
  cardBody: {
    position: "sticky",
    top: "3vh"
  },
  cardImage: {
    maxWidth: "100%",
    height: "50%",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto"
    }
  },
  noteImageContainer: {
    height: "100%",
    width: "auto",
    maxWidth: "50px"
  },
  noteImage: {
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
    width: "auto"
  },
  placeholderImage: {
    width: "100%",
    height: "175px",
    backgroundColor: theme.palette.text.light
  },
  skeleton: {
    height: "175px",
    width: "100%",
    margin: 0
  },
  textContainer: {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
  },
  noteModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    minWidth: "200px"
  },
  modalImage: {
    maxWidth: "400px",
    maxHeight: "400px"
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.secondary.dark,
    overflowWrap: "anywhere"
  },
  imageContainer: {
    position: "relative",
    width: "100%"
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    }
  },
  tableItem: {
    "display": "flex",
    "margin": `${theme.spacing(.25)} 0`,
    "borderRadius": theme.shape.borderRadius,
    "padding": theme.spacing(1),
    "height": "75px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.text.light
    }
  },
  highlightedTableItem: {
    backgroundColor: theme.palette.text.light
  },
  tableItemText: {
    display: "flex",
    flexDirection: "column",
    margin: `0 ${theme.spacing(2)}`,
    maxWidth: "80%"
  },
  tableItemTime: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.chipFontSize,
    marginBottom: theme.spacing(0.5)
  },
  tableItemDesc: {
    color: theme.palette.secondary.main,
    fontSize: theme.lsyPalette.subtitle3,
    marginBottom: theme.spacing(0.5),
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  newNoteChip: {
    marginLeft: "auto"
  },
  icon: {
    width: "1.75rem",
    height: "1.75rem"
  },
  cardHeaderIcon: {
    width: theme.lsyPalette.title2,
    height: theme.lsyPalette.title2,
    marginRight: theme.spacing(0.75)
  },
  cardHeaderItem: {
    "color": theme.palette.secondary.dark,
    "fontSize": theme.lsyPalette.subtitle3,
    "display": "flex",
    "alignItems": "center",
    "marginRight": theme.spacing(2.25),
    "&:hover": {
      cursor: "pointer"
    }
  },
  redirectLink: {
    color: theme.lsyPalette.link,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px"
  },
  redirectIcon: {
    width: "20px",
    height: "20px"
  },
  tableSkeleton: {
    margin: 0
  },
  spaceAround: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  select: {
    fontSize: theme.lsyPalette.body1
  },
  selectOptions: {
    fontSize: theme.typography.xsFontSize
  },
  chipSelect: {
    "fontSize": theme.typography.xsFontSize,
    "fontWeight": theme.lsyPalette.lightWeight,
    "width": "fit-content",
    "& .MuiSvgIcon-root": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      padding: "0"
    }
  },
  modal: {
    width: "500px"
  },
  displayGrid: {
    display: "grid"
  },
  divider: {
    justifySelf: "center"
  }
});

export default lockNotesStyle;