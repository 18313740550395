import { useEffect, useCallback, useMemo, useContext, Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect, useCustomCompareMemo } from "use-custom-compare";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual, pick, get } from "lodash";
import { useForm } from "react-hook-form";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { alertActions, authActions, localeActions } from "_actions";
import { MAX_USERNAME_LENGTH } from "_constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { hasAbility, invalidTagRegex, userNameCannotContainsRegex, usernameRegex } from "_helpers";
import { isPasswordlessRequired } from "_helpers/systemSettings";
import { userService, tagService } from "_services/lockstasy";
import { mfaService } from "_services";
import { adminConstants } from "_constants/admin.constants";
import { getMfaStatusName, isAccountStatusSuspended } from "_constants/account.constants";
import { genericReducer } from "_reducers/general.reducer";

import Panel from "_components/Panel/Panel";
import CustomModal from "_components/Modal/CustomModal";
import CustomForm from "_components/Form/CustomForm";
import MfaModal from "_components/Modal/MfaModal";
import MfaCodeModal from "_components/Modal/MfaCodeModal";
import LsyRouter from "_components/Navigation/LsyRouter";
import ItemPanel from "_components/Panel/ItemPanel";
import SelectLanguage from "_components/Select/SelectLanguage";
import SelectRole from "_components/Select/SelectRole";
import Password from "_components/TextField/Password";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/userEditStyle.js";

const useStyles = makeStyles()(styles);

function UserEdit() {
  const { t } = useTranslation(["default", "auth"]);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const membershipId = useParams().id;
  const [ state, setState ] = useReducer(genericReducer,
    {
      user: {},
      openCreateTag: false,
      showChangePwModal: false,
      showRemovePwModal: false
    });
  const setShowCreateTag = open => setState({ openCreateTag: open });
  const setShowChangePwModal = show => setState({ showChangePwModal: show });
  const setShowRemovePwModal = show => setState({ showRemovePwModal: show });
  const setUser = user => setState({ user });
  const [mfaState, setMfaState] = useReducer(genericReducer,
    {
      showMfaModal: false,
      showMfaBackupCodes: false,
      isMfaEnabled: false,
      isMfaPending: false,
      isDisabling: false,
      mfaBackupCodes: []
    }
  );

  const setShowChangePwModalOpen = () => setShowChangePwModal(true);
  const setShowRemovePwModalOpen = () => setShowRemovePwModal(true);
  const setShowMfaModalOpen = (open) => setMfaState({ showMfaModal: open });
  const setShowMfaBackupCodes = (open) => setMfaState({ showMfaBackupCodes: open });

  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const isSelf = currentMembership.id === membershipId;
  const isAdmin = currentMembership.role_id === adminConstants.LSY_ADMIN;
  const { ability, fetchUserSettings, orgPreferences } = useContext(LsyAdminDataContext);
  const canUpdateUsers = ability.can("update", "users");
  const canReadTags = ability.can("read", "tags");
  const canCreateTags = ability.can("create", "tags");
  const canUpdateRoles = ability.can("update", "roles");
  const canChangeRoles = canUpdateRoles && !isSelf;
  const canChangeDeviceRestriction = hasAbility(currentMembership, "edit", "device_restriction") && canUpdateUsers;
  const canUpdateMfa = isAdmin || !mfaState.isMfaEnabled && isSelf;
  const canChangeUsername = isSelf;

  const userForm = useForm();
  const tagForm = useForm();
  const tagsSelected = {...tagForm.watch("tags")};
  const createTagForm = useForm({ mode: "onChange" });
  const passwordForm = useForm({ mode: "onChange" });
  const sessionTimeoutForm = useForm();

  const fetchUser = useCallback(async () => {
    try {
      const result = await userService.fetchUser({ id: membershipId, include: "tags" });
      setUser({ ...result.data, teleporte_username: result.data.username });
      setMfaState({ isMfaEnabled: result.data.mfa });
    } catch (e) {
      console.warn("Warning, failed to fetch user", e);
      return [];
    }
  }, [membershipId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);
  
  useCustomCompareEffect(() => {
    userForm.reset(state.user);
    tagForm.reset(state.user);
    sessionTimeoutForm.reset({ session_timeout: `${state.user.session_timeout}`});
    if (isSelf) {
      dispatch(localeActions.setLocale({ language: state.user.language }));
    }
  }, [state.user], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const fetchTags = async (inputValue) => {
    try {
      const result = await tagService.fetchTags({ page_size: 5, search: inputValue });
      return result.data.map(tag => tag ? { id: tag.id, name: tag.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  const getErrorMessage = (errors) => {
    for (const key in errors) {
      if (errors[key].message) {
        return errors[key].message;
      }
    }

    return "";
  };

  const usernameOptions = useCustomCompareMemo(() => {
    const fieldName = "teleporte_username";

    return [{
      field: fieldName,
      type: "textField",
      autoComplete: "off",
      defaultValue: state.user.username,
      disabled: !canChangeUsername,
      inputProps: { maxLength: MAX_USERNAME_LENGTH, autoComplete: "off", name: fieldName, form: {autocomplete: "off"} },
      pattern: {
        value: usernameRegex,
        message: t("error.invalidUsername")
      }
    }];
  }, [state.user, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderUsernameForm = useMemo(() => {
    return <CustomForm items={usernameOptions} form={userForm}/>;
  }, [usernameOptions, userForm]);
  
  const updateUser = async (updatedUser, fields) => {
    const userData = pick(state.user, fields);
    if (isEqual(userData, updatedUser)) {
      return;
    }

    try {
      if (updatedUser.tags) {
        updatedUser.tag_ids = updatedUser.tags.map((tag) => tag.id);
      }
    
      const result = await userService.updateUser({ membershipId: state.user.membership_id, payload: updatedUser });
      
      if(result?.data?.user_group) 
        updatedUser.user_group = result.data.user_group;

      const userUpdated = { ...updatedUser, teleporte_username: result?.data?.username };
      userForm.reset(userUpdated);
      setUser({ ...state.user, ...userUpdated });
      setShowCreateTag(false);
      dispatch(alertActions.send(t("success.updateUser")));
      if (fields.includes("session_timeout")) {
        fetchUserSettings(true);
      }
    } catch (e) {
      if (get(e, "response.status") === 422) {
        const maxTagUsersDetail = get(e, "response.data.error.errors[0].detail");
        const found = maxTagUsersDetail?.match(/error.user.tag_ids.count.gt.(\d)+/i);
        if (found) {
          dispatch(alertActions.send(t("error.maxTagUsers", {maxTagUsers: found[1]}), "error"));
        } else {
          dispatch(alertActions.send(t("error.updateUser"), "error"));
        }
      } else if (get(e, "response.status") === 409) {
        userForm.setError("teleporte_username", { type: "manual", message: t("error.notUniqueUsername") });
      } else {
        console.warn("Warning, failed to update user", e);
        dispatch(alertActions.send(t("error.updateUser"), "error"));
      }
    }
  };

  const usernameSubmit = (data) => { 
    updateUser({ username: data.teleporte_username }, ["username"]);
  };

  const handleError = (errors)  => {
    dispatch(alertActions.send(getErrorMessage(errors), "error"));
  };

  const usernameButtons = () => {
    return userForm.formState.dirtyFields.teleporte_username ? [
      { label: t("button.cancel"), onClick: () => userForm.reset(state.user) },
      { label: t("button.save"), onClick: userForm.handleSubmit(usernameSubmit) }
    ] : [];
  };

  const personalInfoOptions = useCustomCompareMemo(() => {
    return [{
      field: "first_name",
      type: "textField",
      label: t("form.firstName"),
      required: t("error.requiredField"),
      autoComplete: "off",
      defaultValue: state.user.first_name,
      pattern: {
        value: userNameCannotContainsRegex,
        message: t("error.invalidEntry")
      }
    },
    {
      field: "last_name",
      type: "textField",
      label: t("form.lastName"),
      required: t("error.requiredField"),
      autoComplete: "off",
      defaultValue: state.user.last_name,
      pattern: {
        value: userNameCannotContainsRegex,
        message: t("error.invalidEntry")
      }
    }];
  }, [state.user, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderPersonalInfoForm = useMemo(() => {
    return <CustomForm items={personalInfoOptions} form={userForm}/>;
  }, [personalInfoOptions, userForm]);

  const personalInfoSubmit = (data) => {
    updateUser({ first_name: data.first_name, last_name: data.last_name }, ["first_name", "last_name"]);
  };

  const personalInfoButtons = () => {
    const { first_name, last_name } = userForm.formState.dirtyFields;

    return first_name || last_name ? [
      { label: t("button.cancel"), onClick: () => userForm.reset(state.user) },
      { label: t("button.save"), onClick: userForm.handleSubmit(personalInfoSubmit, handleError) }
    ] : [];
  };

  const tagOptions = useCustomCompareMemo(() => {
    if (!canReadTags) {
      return [];
    }

    return [{
      field: "tags",
      type: "typeAhead",
      placeholder: t("features.tags"),
      isMulti: true,
      autoFocus: false,
      defaultValue: state.user.tags,
      className: classes.sizeBreak,
      showIcon: state.openCreateTag,
      icon: canCreateTags ?
        <Tooltip title={t("label.createTag")}>
          <IconButton
            data-testid="createTag"
            className={classes.iconButton}
            onClick={() => setShowCreateTag(true)}
            size="large">
            <AddIcon/>
          </IconButton>
        </Tooltip> : null,
      iconStyle: classes.internalIcon,
      promiseOptions: fetchTags
    }];
  }, [state.user, t, state.openCreateTag], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderTagForm = useCustomCompareMemo(() => {
    return <CustomForm items={tagOptions} form={tagForm}/>;
  }, [tagOptions, tagForm, tagsSelected], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const cancelChangeTag = () => {
    setShowCreateTag(false);
    tagForm.reset(state.user.tags);
  };

  const tagSubmit = (data) => {
    updateUser({ tags: data.tags }, ["tag_ids"]);
  };

  const tagsButtons = () => {
    return Object.keys(tagForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelChangeTag },
      { label: t("button.save"), onClick: tagForm.handleSubmit(tagSubmit) }
    ] : [];
  };

  const createTagOptions = useCustomCompareMemo(() => {
    if (!canCreateTags){
      return [];
    }

    return [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      validateEntry: value => !invalidTagRegex.test(value),
      validateEntryMessage: t("error.invalidCreateChars"),
      autoComplete: "off",
      defaultValue: ""
    }];
  }, [t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderCreateTagForm = useMemo(() => {
    return <CustomForm items={createTagOptions} form={createTagForm}/>;
  }, [createTagOptions, createTagForm]);

  const createTag = async (data) => {
    try {
      await tagService.createTag({ data: data });
      setShowCreateTag(false);
      dispatch(alertActions.send(t("success.createTag")));
    } catch (e) {
      console.warn("Warning, failed to create tag", e);
      dispatch(alertActions.send(t("error.createTag"), "error"));
    }
  };

  const cancelCreateTag = () => {
    setShowCreateTag(false);
    createTagForm.reset();
  };

  const createTagSubmit = (data) => {
    createTag(data);
  };

  const createTagButtons = () => {
    return Object.keys(createTagForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelCreateTag },
      { label: t("button.save"), onClick: createTagForm.handleSubmit(createTagSubmit, handleError), disabled: !isEmpty(Object.keys(createTagForm.formState.errors)) }
    ] : [];
  };

  const languageOnChange = (data) => {
    const newLanguage = { language: data };

    updateUser(newLanguage, ["language"]);
  };

  const toggleMembershipState = async () => {
    try {
      // the ONLY time to show this as disabled is when it's suspended
      const response = await userService.toggleMembershipState({
        membershipId: state.user.membership_id,
        data: { enabled: isAccountStatusSuspended(state.user.status) }
      });

      setUser({
        ...state.user,
        status: response.status
      });
      dispatch(alertActions.send(t("success.updateUser")));
    } catch (e) {
      console.warn("Warning, failed to update user", e);
      dispatch(alertActions.send(t("error.updateUser"), "error"));
    }
  };

  const getActionOptions = () => {
    return isAccountStatusSuspended(state.user.status) ?
      {
        title: t("actions.unsuspend"),
        description: t("description.unsuspend"),
        buttonLabel: t("button.unsuspend"),
        handleOnClick: toggleMembershipState
      } :
      {
        title: t("actions.suspend"),
        description: t("description.suspend"),
        buttonLabel: t("button.suspend"),
        handleOnClick: toggleMembershipState
      };
  };

  const renderAccountAction = () => {
    if (isSelf || state.user.pending_consent || !isAdmin) {
      return null;
    }

    const options = getActionOptions();

    return (
      <ItemPanel buttons={[{ label: options.buttonLabel || options.title, onClick: options.handleOnClick }]}>
        <Typography variant="subtitle1">{options.title}</Typography>
        <Typography variant="caption" className={classes.description}>{options.description}</Typography>
      </ItemPanel>
    );
  };

  const updatePassword = async (newPassword, currentPassword) => {
    try {
      await userService.updatePassword(newPassword, currentPassword);
      dispatch(authActions.logout());
    } catch (e) {
      console.warn("Warning, failed to update user", e);
      dispatch(alertActions.send(t("error.updateUser"), "error"));
    }
  };

  const onPasswordSubmit = (data) => {
    const { current_password, password, password_confirmation } = data;

    if (isEqual(password, password_confirmation)) {
      updatePassword(password, current_password);
    } else {
      dispatch(alertActions.send(t("error.passwordMatch"), "error"));
    }
  };

  const onCancelPW = () => {
    passwordForm.reset();
  };

  const handlePWError = (errors) => {
    dispatch(alertActions.send(getErrorMessage(errors) || t("error.passwordFormat"), "error"));
  };

  const changePasswordButtons = handleSubmit => {
    return Object.keys(passwordForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: onCancelPW },
      { label: t("button.save"), onClick: handleSubmit, disabled: !isEmpty(Object.keys(passwordForm.formState.errors)) }
    ] : [];
  };

  const getChangePwDescription = () => {
    return <p>
      {t("confirmation.changePassword")}<br/>
    </p>;
  };

  const renderPwModal = (open, setOpen, title, submit) => {
    return <CustomModal
      open={open}
      setOpen={setOpen}
      handleSubmit={submit}
      type="confirm"
      confirm={t("button.save")}
      cancel={t("button.cancel")}
      cancelButtonStyle={classes.button}
      confirmButtonStyle={classes.button}
      handleCancel={onCancelPW}
      title={title}
      description={getChangePwDescription()}
      disableBackdropClick={true}
      modalStyle={classes.modal}
    />;
  };

  const pwObserver = passwordForm.watch("password");

  useEffect(() => {
    passwordForm.trigger("password_confirmation");

    if (state.user.password && !isEmpty(passwordForm.getValues("password"))) {
      passwordForm.trigger("current_password");
    }
  }, [state.user.password, pwObserver, passwordForm, t]);

  const renderChangePassword = () => {
    if (isPasswordlessRequired(orgPreferences)) {
      return null;
    }

    let title = t("label.setPassword");
    let description = t("description.setPassword");
    const handleSubmitPw = setShowChangePwModalOpen;
    let handleSubmitPwEnter = setShowChangePwModalOpen;

    if (state.user.password) {
      title = t("label.changePassword");
      description = t("description.changePassword");
    }

    const errors = passwordForm.formState.errors || {};
    const errorMsg = errors.password?.message ||
      errors.password_confirmation?.message ||
      errors.current_password?.message;

    if (errorMsg) {
      handleSubmitPwEnter = () => {};
    }

    const validatePWConfirmation = {
      validateEntry: passwordConfirmation => isEqual(passwordForm.getValues("password"), passwordConfirmation),
      validateEntryMessage: t("error.passwordMatch")
    };

    return <Panel isCollapse={true} isSubPanel={true} title={title} description={description}>
      {renderPwModal(state.showChangePwModal, setShowChangePwModal, title, passwordForm.handleSubmit(onPasswordSubmit, handlePWError))}
      <form name="passwordForm">
        { state.user.password &&
          <ItemPanel>
            <Password form={passwordForm} label={t("form.currentPassword")} fieldName="current_password" />
          </ItemPanel>
        }
        <ItemPanel>
          <Password form={passwordForm} label={t("form.password")} fieldName="password"/>
        </ItemPanel>
        <ItemPanel buttons={changePasswordButtons(handleSubmitPw)} errorMsg={errorMsg}>
          <Password form={passwordForm} label={t("form.passwordConfirmation")} fieldName="password_confirmation"
            submitOnEnter={true} handleSubmit={handleSubmitPwEnter} validate={validatePWConfirmation}/>
        </ItemPanel>
      </form>
    </Panel>;
  };

  const onDeletePassword = async () => {
    try {
      await userService.deletePassword();
      dispatch(authActions.logout());
    } catch (e) {
      dispatch(alertActions.send(t("error.updateUser"), "error"));
    }
  };

  const renderRemovePassword = () => {
    if (!isSelf || (!isPasswordlessRequired(orgPreferences) && !state.user.password)) {
      return null;
    }

    const pwInfo = {
      title: t("label.deletePassword"),
      description: t("description.deletePassword")
    };

    if (isPasswordlessRequired(orgPreferences)) {
      pwInfo.title = t("form.password");
      pwInfo.description = t("description.managePw");
    }

    return <Grid item>
      {renderPwModal(state.showRemovePwModal, setShowRemovePwModal, t("label.deletePassword"), onDeletePassword)}
      <Grid container direction="column" className={classes.panelSpacing}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">
                {pwInfo.title}
              </Typography>
              <Typography variant="caption" className={classes.description}>
                {pwInfo.description}
              </Typography>
            </Grid>
            {state.user.password && <Grid item>
              <Button
                data-testid="removePasswordButton"
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={setShowRemovePwModalOpen}
              >
                {t("label.deletePassword")}
              </Button>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const resetPassword = () => {
    if (state.user.email) {
      try {
        dispatch(authActions.resetPassword(state.user.email));
        dispatch(alertActions.send(t("success.emailInstruction")));
      } catch (e) {
        console.warn(e);
        dispatch(alertActions.send(t("error.resetPassword"), "error"));
      }
    }
  };

  const renderResetPassword = () => {
    if (isSelf || !canUpdateUsers || isPasswordlessRequired(orgPreferences)) {
      return null;
    }

    return <Grid item>
      <Grid container direction="column" className={classes.panelSpacing}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">
                {t("label.resetPassword")}
              </Typography>
              <Typography variant="caption" className={classes.description}>
                {t("description.resetPassword")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" size="small" className={classes.button} onClick={resetPassword}>
                {t("label.resetPassword")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const sessionTimeoutOptions = useCustomCompareMemo(() => {
    if (!canUpdateUsers) {
      return [];
    }

    return [{
      field: "session_timeout",
      type: "textField",
      label: t("form.timeInMinutes"),
      required: t("error.requiredField"),
      autoComplete: "off",
      defaultValue: `${state.user.session_timeout}`,
      validateEntry: (value) => value >= 0 && value.length <= 4
    }];
  }, [state.user, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderSessionTimeoutForm = useMemo(() => {
    return <CustomForm items={sessionTimeoutOptions} form={sessionTimeoutForm}/>;
  }, [sessionTimeoutOptions, sessionTimeoutForm]);

  const onSTSubmit = (data) => {
    updateUser(data, ["session_timeout"]);
  };

  const handleSTError = (errors) => {
    dispatch(alertActions.send(getErrorMessage(errors) || t("error.invalidEntry"), "error"));
  };

  const sessionTimeoutButtons = () => {
    return Object.keys(sessionTimeoutForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: () => sessionTimeoutForm.reset(state.user.session_timeout) },
      { label: t("button.save"), onClick: sessionTimeoutForm.handleSubmit(onSTSubmit, handleSTError) }
    ] : [];
  };

  const handleRoleSubmit = value => updateUser({ role_id: value }, ["role_id"]);

  const renderRoles = () => {
    return canChangeRoles && 
      <Panel isCollapse={true} isSubPanel={true} title={t("label.membershipType")} description={t("description.membershipType")}>
        <ItemPanel>
          <SelectRole showLabel={false} roleId={state.user.role_id} handleSubmit={handleRoleSubmit}/>
        </ItemPanel>
      </Panel>;
  };

  const onDeviceRestrictionChange = data => {
    updateUser({ device_restricted: data }, ["device_restricted"]);
  };

  const showBackupCodes = async () => {
    try {
      const result = await mfaService.rotateBackupCodes(membershipId);

      setMfaState({
        showMfaBackupCodes: true,
        mfaBackupCodes: result.backup_codes
      });
    } catch (e) {
      dispatch(alertActions.send(t("error.regenerateMfaBackupCodes"), "error"));
    }
  };

  const renderMfaItem = () => {
    if (!isAdmin && !isSelf) {
      return null;
    }

    let mfaItems = [];
    
    const mfaStatus = state.user?.auth_options?.mfa.TOTP.state;

    if (isSelf) {
      switch (mfaStatus) {
        case 1:
          mfaItems.push({
            description: t("mfa.setupAlert", { ns: "auth" }),
            buttons: [{
              label: "Setup",
              onClick: () => {
                setMfaState({
                  showMfaModal: true,
                  isMfaPending: true
                });
              }
            }]
          });
          break;
        case 2:
          mfaItems.push({
            description: t("description.reconfigureMfa"),
            buttons: [{
              label: t("label.reconfigureMfa"),
              onClick: () => {
                setMfaState({
                  showMfaModal: true,
                  isDisabling: false,
                  isMfaPending: false
                });
              }
            }]
          });
          mfaItems.push({
            description: t("description.regenerateBkCodes"),
            buttons: [{
              label: t("label.regenerateBkCodes"),
              onClick: showBackupCodes
            }]
          });
          break;
      }
    }

    if (mfaItems.length <= 0) {
      return null;
    }

    return <Fragment>
      <Divider className={classes.divider} />
      {mfaItems.map(item => {
        return <ItemPanel key={item.buttons[0].label} buttons={item.buttons}>
          <Typography variant="body2" className={classes.sizeBreak}>{item.description}</Typography>
        </ItemPanel>;
      })}
    </Fragment>;
  };

  const onMfaChange = () => {
    setMfaState({
      isDisabling: mfaState.isMfaEnabled,
      isMfaPending: state.user?.auth_options?.mfa.TOTP.state === 1,
      showMfaModal: true
    });
  };

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.lsyBackground}>
        <Grid container className={classes.container} alignItems="stretch" justifyContent="center">
          <Grid item xs={11} lg={8}>
            <Grid container direction="column" className={classes.margin}>
              <Grid item>
                <LsyRouter page="user" id={membershipId}>
                  <KeyboardBackspaceRoundedIcon className={classes.icon}/>
                </LsyRouter>
              </Grid>
              <Grid item>
                <Typography variant="h6">{t("features.userSettings")}</Typography>
                <Typography variant="caption" className={classes.description}>{t("description.userSettings")}</Typography>
              </Grid>
              <Grid item className={classes.content}>
                <Grid container direction="column" spacing={2}>
                  <Grid item data-testid="accountIdentitySection">
                    <Panel isCollapse={true} isSubPanel={false} title={t("label.accountIdentity")} description={t("description.accountIdentity")}>
                      <Panel isCollapse={false} isSubPanel={true} title={t("form.email")} description={t("description.email")}>
                        <ItemPanel>
                          <Typography variant="body2">{state.user.email}</Typography>
                        </ItemPanel>
                      </Panel>
                      {canChangeUsername && 
                        <Panel isCollapse={false} isSubPanel={true} title={t("login.username", { ns: "auth" })} description={`${t("description.username")} ${t("error.invalidUsername")}`}>
                          <ItemPanel buttons={usernameButtons()} errorMsg={userForm.formState.errors?.teleporte_username?.message}>
                            { renderUsernameForm } 
                          </ItemPanel>
                        </Panel>}
                    </Panel>
                  </Grid>
                  <Grid item data-testid="personalInfoSection">
                    <Panel isCollapse={true} isSubPanel={false} title={t("label.personalInfo")} description={t("description.personalInfo")}>
                      <ItemPanel buttons={personalInfoButtons()}>
                        { renderPersonalInfoForm }
                      </ItemPanel>
                      <ItemPanel>
                        <Typography variant="subtitle1">{t("label.language")}</Typography>
                        <Typography variant="caption" className={classes.description}>{t("description.language")}</Typography>
                        <SelectLanguage language={state.user.language} showLabel={false} handleSubmit={languageOnChange}/>
                      </ItemPanel>
                    </Panel>
                  </Grid>
                  { canReadTags &&
                    <Grid item data-testid="tagsSection">
                      <Panel isCollapse={true} isSubPanel={false} title={t("features.tags")} description={t("description.userSettingsTags")}>
                        <ItemPanel buttons={ !state.openCreateTag ? tagsButtons() : [] }>
                          { renderTagForm }
                        </ItemPanel>
                        <Collapse in={state.openCreateTag}>
                          <ItemPanel buttons={createTagButtons()} errorMsg={createTagForm.formState.errors?.name?.message}>
                            { renderCreateTagForm }
                          </ItemPanel>
                        </Collapse>
                      </Panel>
                    </Grid>
                  }
                  <Grid item data-testid="securitySection">
                    <Panel isCollapse={true} isSubPanel={false} title={t("label.security")} description={t("description.security")}>
                      {renderAccountAction()}
                      {isSelf && renderChangePassword()}
                      {renderResetPassword()}
                      {renderRemovePassword()}
                      { canUpdateUsers && 
                        <Panel isCollapse={true} isSubPanel={true}
                          title={t("sessionTimeout.title", { ns: "auth" })}
                          description={t("sessionTimeout.description", { ns: "auth" })}
                        >
                          <ItemPanel buttons={sessionTimeoutButtons()}>
                            { renderSessionTimeoutForm }
                          </ItemPanel>
                        </Panel>
                      }
                      {renderRoles()}
                      <Grid item>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.panelSpacing}>
                          <Grid item>
                            <Typography variant="subtitle1">{t("label.restrictUser")}</Typography>
                            <Typography variant="caption" className={classes.description}>{t("widget.explanations.restrictUserFull")}</Typography>
                          </Grid>
                          <Grid item>
                            <ToggleSwitch
                              onColor="#5C5C5C"
                              checked={state.user.device_restricted || false}
                              disabled={!canChangeDeviceRestriction}
                              handleChange={onDeviceRestrictionChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column"  className={classes.panelSpacing}>
                          <Grid item>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <Typography variant="subtitle1">
                                  {t("mfa.mfa", { ns: "auth" })}
                                  { state.user?.auth_options?.mfa?.TOTP.state === 1 &&
                                    <Chip
                                      size="small"
                                      className={classes.chip}
                                      label={t(getMfaStatusName(1))}
                                    />}
                                </Typography>
                                <Typography variant="caption" className={classes.description}>
                                  {t("mfa.action", { ns: "auth" })}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <MfaModal
                                  showMfaModal={mfaState.showMfaModal}
                                  handleClose={setShowMfaModalOpen}
                                  getMembershipData={fetchUser}
                                  personalAccount={isSelf}
                                  isMfaPending={mfaState.isMfaPending}
                                  isDisabling={mfaState.isDisabling}
                                  mfa={mfaState.isMfaEnabled}
                                  email={state.user.email}
                                  id={membershipId}
                                  hasPassword={state.user.password}
                                />
                                <MfaCodeModal
                                  showModal={mfaState.showMfaBackupCodes}
                                  handleClose={setShowMfaBackupCodes}
                                  codes={mfaState.mfaBackupCodes}
                                />
                                <ToggleSwitch 
                                  onColor="#5C5C5C" 
                                  checked={mfaState.isMfaEnabled}
                                  disabled={!canUpdateMfa} 
                                  handleChange={onMfaChange}/>
                              </Grid>
                            </Grid>
                          </Grid>
                          { renderMfaItem() }
                        </Grid>
                      </Grid>
                    </Panel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}

export default UserEdit;