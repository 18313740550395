import { useEffect, useState, useMemo, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useParams, useLocation, Switch, Route } from "react-router-dom";
import { pick, has, isEmpty, isEqual } from "lodash";
import { useCustomCompareEffect } from "use-custom-compare";
import moment from "moment";
import Iframe from "react-iframe";

import { config } from "_configs/server-config";
import { memberActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { WebSocketDataProvider } from "_contexts/WebSocketData/WebSocketData";
import { useLsyHistory } from "_hooks";

//Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/lockstasyStyle.js";

//Components
import LsyHeader from "_containers/Lockstasy/LsyHeader.js";
import LsyFooter from "_containers/Lockstasy/LsyFooter";
import About from "_views/Lockstasy/About";
import LsyDashboard from "./LsyDashboard";
import Tags from "./Tags";
import Users from "./Users";
import User from "./User";
import UserEdit from "./UserEdit";
import Sites from "./Sites";
import Locks from "./Locks";
import Lock from "./Lock";
import LockEdit from "./LockEdit";
import LockGroups from "./LockGroups";
import LockGroup from "./LockGroup";
import LockReplacement from "./LockReplacement";
import Site from "./Site";
import Maps from "./Maps";
import Charts from "./Charts";
import Reports from "./Reports";
import SystemLogs from "./SystemLogs";
import AccessHistory from "./AccessHistory";
import LockNotes from "./LockNotes";
import LsyDevelopers from "./Developers";
import SystemSettings from "./SystemSettings";
import UserDevices from "./UserDevices";
import UserDeviceHistory from "./UserDeviceHistory";
import UserNotifications from "./UserNotifications";
import WorkSessions from "./WorkSessions";

import PropTypes from "prop-types";
import appRoutes from "_routes";

import { adminConstants } from "_constants/admin.constants";
import AccessRequests from "./AccessRequests";
import ScrollToTop from "_helpers/ScrollToTop";

const baseUrl = appRoutes.private.lockstasy;
const useStyles = makeStyles()(styles);
const removeTabSelection = -1;

const nativePages = ["/dashboard$", /\/users(\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?$/, "/locks$", "/developers$", /\/sites(\/\\d+)?/, "/locations$", "/access_requests$"];

function Lockstasy(props) {
  const [currentMembership, setCurrentMembership] = useState(null);
  const [org, setOrg] = useState("");
  const lastOrg = useRef(null);
  const [previousPath, setPreviousPath] = useState(null);
  const [currentNavTab, setCurrentNavTab] = useState(removeTabSelection);

  const curMembership = useSelector((state) => state.memberships.currentMembership);
  const membershipsList = useSelector((state) => state.memberships.list);
  const memberships = useMemo(() => {
    let membershipsUnfiltered = [];

    try {
      membershipsUnfiltered = isEmpty(membershipsList) ?
        JSON.parse(localStorage.getItem("memberships")) :
        membershipsList;
    } catch (e) {
      console.warn("Error parsing localStorage memberships", localStorage.getItem("memberships"),  e);
    }

    return membershipsUnfiltered?.filter((membership) => membership.s4_role !== adminConstants.SERA4TAL_ADMIN).sort() || [];
  }, [membershipsList]);

  const language = useSelector((state) => state.locale.language);
  const currentMembershipRef = useRef(null);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const { ability, schemaLoaded, abilityRef, setIsScrollToTop } = lsyAdminDataContext;
  const { setLayoutProps } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { classes } = useStyles();
  const params = useParams();
  const routeMatch = useRouteMatch();
  const history = useLsyHistory();

  useEffect(() => {
    setLayoutProps({ xs: 12 });
  }, [setLayoutProps]);

  useEffect(() => {
    setIsScrollToTop(true);
  }, [location.pathname, setIsScrollToTop]);

  const invalidRouteFallback = () => {
    if (lastOrg.current) {
      history.replace1(`${baseUrl}/${lastOrg.current}/dashboard`);
    } else if (!isEmpty(memberships)) {
      history.replace1(`${baseUrl}/${memberships[0].server_alias}/dashboard`);
      lastOrg.current = memberships[0].server_alias;
    } else {
      history.replace1(`${baseUrl}/${curMembership.server_alias}/dashboard`);
      lastOrg.current = curMembership.server_alias;
    }
  };

  const hasPermission = (path) => {
    const routePermissionOutliers = { //TODO: Doesn't work if taken out of this function, need to fix eventually
      about: true,
      dashboard: true,
      user_notifications: true,
      access_histories: true,
      reports: abilityRef.current.can("read", "reports"),
      system_logs: abilityRef.current.can("read", "system_logs"),
      developers: abilityRef.current.can("read", "webhooks"),
      systemsettings: abilityRef.current.can("read", "webhooks"),
      sites: abilityRef.current.can("read", "lock_collections")
    };
    return routePermissionOutliers[path] || abilityRef.current.can("read", path);
  };

  const isAllowedAllUsers = (url) => {
    const org = currentMembershipRef.current.server_alias;

    const allowedUrls = [
      `${baseUrl}/${org}/users/${currentMembershipRef.current.id}`,
      `${baseUrl}/${org}/users/${currentMembershipRef.current.id}/edit`
    ];

    const foundUrl = allowedUrls.filter(item => url === item);

    return !isEmpty(foundUrl);
  };

  const canUserAccessUrl = (url) => {
    return currentMembershipRef.current.role_id !== adminConstants.LSY_USER ||
      isAllowedAllUsers(url);
  };

  const rerouteToDashboard = () => {
    history.replace("/dashboard");
    setPreviousPath(`${baseUrl}/${params.org}/dashboard`);
    setCurrentNavTab(removeTabSelection);
  };

  const routeValidation = () => {
    const url = routeMatch.url;
    const path = params.path;
    const id = params.id;
    const subPath = params.subPath;

    if (!canUserAccessUrl(url)) {
      history.replace1(`${baseUrl}/${currentMembershipRef.current.server_alias}/users/${currentMembershipRef.current.id}`);
    } else if (schemaLoaded.current && currentMembershipRef.current && !ability.can("read", "temp")) {
      //Check if path is provided
      let validPath = false;
      if (path) {
        let unlicensedPaths = [
          "about",
          "dashboard",
          "locks",
          "users",
          "tags",
          "work_sessions",
          "system_logs",
          "reports",
          "developers", // TODO: developers will need to be added to the membership features eventually backend side
          "systemsettings",
          "user_notifications"
        ];

        if (isAllowedAllUsers(url)) { //all users are allowed to visit their account info page
          validPath = true;
          setPreviousPath(url);
          setCurrentNavTab(1);
        } else if (unlicensedPaths.includes(path) && hasPermission(path)) { //If path does not require license, dont check for one, however still check for permission
          validPath = true;
          switch (path) {
            case "users":
              setCurrentNavTab(1);
              break;
            case "locks":
              setCurrentNavTab(2);
              break;
            case "dashboard":
              setCurrentNavTab(removeTabSelection);
              break;
            default:
          }

          //Handle routing if id is provided
          const pathsWithIds = ["users", "locks", "work_sessions", "user_notifications"];
          if (id && pathsWithIds.includes(path)) {
            if (subPath) { //subpath handler
              setPreviousPath(url);
            } else { //handle regular id
              //specific exception for user_notifications
              if (path === "user_notifications" && parseInt(id) !== currentMembershipRef.current.user_id) {
                invalidRouteFallback();
              } else {
                setPreviousPath(url);
              }
            }
          } else {
            // id is not given or the given route does not accept an id
            setPreviousPath(url);
          }
        } else {
          //Path is restricted, validate license and permission
          const outlierFeatures = [
            "key_requests",
            "lock_collections",
            "maps"
          ];

          const map = {
            key_requests: "access_requests",
            lock_collections: "sites",
            maps: "locations"
          };

          let membershipFeatures = currentMembershipRef.current.features;

          //Loop through features and check if path matches and if we have license and permission
          for (let feature in membershipFeatures) {
            if (feature === path && membershipFeatures[feature] === true && hasPermission(path)) {
              validPath = true;

              switch (path) {
                case "sites":
                  setCurrentNavTab(0);
                  break;
                case "locations":
                  setCurrentNavTab(3);
                  break;
                default:
              }

              //ID check
              if (id && (path === "sites" || path === "lock_groups")) {
                setPreviousPath(url);
              } else {  // id is not given or invalid on dashboard
                setPreviousPath(url);
              }
              break;
            }

            //Some paths do not match the feature name, check outliers
            if (outlierFeatures.includes(feature) && membershipFeatures[feature]
              && map[feature] === path && hasPermission(path)) {
              validPath = true;

              //ID check
              if (id && path === "access_requests") {
                setPreviousPath(url);
              } else {
                // id is not given or invalid on dashboard
                setPreviousPath(url);
              }
              break;
            }
          }
        }
        //If path is not found
        if (!validPath) {
          rerouteToDashboard();
        }
      } else {
        rerouteToDashboard();
      }
    } else {
      setTimeout(routeValidation, 1000); //validate the route after the schema has been loaded/formatted
    }
  };


  useCustomCompareEffect(() => {
    let validOrg = false;
    let url = routeMatch.url;

    if (url !== previousPath) {
      if (params.org) {
        for (let i in memberships) { //set membership according to the org provided in url
          if (params.org === memberships[i].server_alias) {
            validOrg = true;

            if (!isEqual(currentMembershipRef.current, memberships[i])) {
              setOrg(memberships[i].server_alias);
              setCurrentMembership(memberships[i]);
              currentMembershipRef.current = memberships[i];
              dispatch(memberActions.storeCurrentMembership(memberships[i]));
              sessionStorage.setItem("membershipId", memberships[i].id);
            }

            if (params.org !== lastOrg.current) {
              lastOrg.current = params.org;
            }

            break;
          }
        }

        if (validOrg) {
          routeValidation();
        } else {
          invalidRouteFallback(); //invalid org provided
        }
      } else {
        invalidRouteFallback(); //org not provided, invalid url
      }
    }
  }, [memberships, params, routeMatch], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  // //Translates membership data from Object to base64 encoded string
  const codifyData = (data) => {
    try {
      return btoa(JSON.stringify(data));
    } catch (e) {
      console.debug(e);
      // return basic empty condition
      return btoa(JSON.stringify({ features: {} }));
    }
  };

  const renderIFrame = useMemo(() => {
    // TODO: send membership features data
    return currentMembership && (
      <Iframe
        url={
          config.tenantProtocol +
          currentMembership.server_alias +
          `.sera4.com${config.tenantPort}/#/about` +
          `?is_data=${codifyData(pick(currentMembership, ["features"]))}
          &language=${language || "en"}`
        }
        width="0"
        id="lockstasyFrame"
        display="none"
        //position="relative"
        frameBorder="0"
        className={classes.lockstasyFrame}
      />
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentMembership]);

  const renderFooter = useMemo(() => {
    let render;
    nativePages.forEach((url) => {
      const urlRegex = new RegExp(url);
      const currentUrl = routeMatch.url.replace(`/tenants/${org}`, "");
      if (!currentUrl.startsWith("/reports") && urlRegex.test(currentUrl)) {
        render = true;
      }
    });
    return render;
  }, [routeMatch.url, org]);

  const onChangeOrganization = (_event, mem) => {
    if (has(mem, "server_alias")) {
      history.push1(`${baseUrl}/${mem.server_alias}/dashboard`);
    }
  };

  return (
    <WebSocketDataProvider>
      <div className={classes.lsyPage}>
        <LsyHeader
          history={history}
          memberships={memberships}
          org={org}
          currentMembership={currentMembership}
          currentNavTab={currentNavTab}
          setCurrentNavTab={setCurrentNavTab}
          onChange={onChangeOrganization}
          baseUrl={baseUrl}
        />
        {org && schemaLoaded.current ? (
          <ScrollToTop>
            <Switch>
              <Route
                path={`${baseUrl}/${org}/about`}
                exact
                render={() => (<About/>)}
              />
              <Route
                path={`${baseUrl}/${org}/dashboard`}
                exact
                render={() => (
                  <LsyDashboard
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/developers`}
                exact
                render={() => (
                  <LsyDevelopers
                    tenantId={currentMembership.tenant_id}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users`}
                exact
                render={() => (
                  <Users
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id`}
                exact
                render={() => (
                  <User org={org} history={history}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/notifications`}
                exact
                render={() => (
                  <UserNotifications history={history} location={location}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/edit`}
                exact
                render={() => (<UserEdit/>)}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/access_histories`}
                exact
                render={() => (
                  <AccessHistory
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                    type={"users"}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/locks/:id/access_histories`}
                exact
                render={() => (
                  <AccessHistory
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                    type={"locks"}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/devices`}
                exact
                render={() => (
                  <UserDevices history={history}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/devices/:deviceId/device_history`}
                exact
                render={() => (
                  <UserDeviceHistory history={history} location={location}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/users/:id/work_sessions`}
                exact
                render={() => (<WorkSessions/>)}
              />
              <Route
                path={`${baseUrl}/${org}/lock_groups`}
                exact
                render={() => (
                  <LockGroups org={org} location={location}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/lock_groups/:id`}
                exact
                render={() => (
                  <LockGroup
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/tags`}
                exact
                render={() => (
                  <Tags
                    org={org}
                    history={history}
                    location={location}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/sites`}
                exact
                render={() => (
                  <Sites
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/sites/:id`}
                exact
                render={() => (
                  <Site
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/locks`}
                exact
                render={() => (
                  <Locks
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/locks/:id`}
                exact
                render={() => (
                  <Lock org={org} history={history}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/locks/:id/edit`}
                exact
                render={() => (<LockEdit/>)}
              />
              <Route
                path={`${baseUrl}/${org}/lock_notes`}
                exact
                render={() => (
                  <LockNotes
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/locks/:id/replacement/:replacementId`}
                exact
                render={() => (<LockReplacement/>)}
              />
              <Route
                path={`${baseUrl}/${org}/locations`}
                exact
                render={() => (
                  <Maps
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/reports/:report?`}
                exact
                render={() => (
                  <Reports history={history} location={location}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/access_requests/:id?`}
                exact
                render={() => (
                  <AccessRequests history={history}/>
                )}
              />
              <Route
                path={`${baseUrl}/${org}/system_logs`}
                exact
                render={() => (
                  <SystemLogs
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/systemsettings`}
                exact
                render={() => (
                  <SystemSettings
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
              <Route
                path={`${baseUrl}/${org}/charts`}
                exact
                render={() => (
                  <Charts
                    org={org}
                    history={history}
                    location={location}
                    baseUrl={baseUrl}
                  />
                )}
              />
            </Switch>
          </ScrollToTop>
        ) : <div className={classes.blankPage}></div>}
        {renderFooter ? <LsyFooter org={org} /> : null}
        {renderIFrame}
      </div>
    </WebSocketDataProvider>
  );
}

Lockstasy.propTypes = {
  setLayoutProps: PropTypes.func
};

export default Lockstasy;
