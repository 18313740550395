import { isEmpty } from "lodash";
import { get, save, update } from "__indexedDB/db";
import { getFormattedFilterName } from "__indexedDB/helpers/filter";
import { formatDateAsYYYYMMDD } from "_helpers";

const TABLE_NAME = "filters";

const getNewFilterList = (storedFilters, filter) => {
  const storedFilterIdx = storedFilters.findIndex(storedFilter => storedFilter.name === filter.name);

  if (storedFilterIdx === -1) {
    return [filter, ...storedFilters.slice(0, 4)];
  } else {
    let newFilters = [filter, ...storedFilters];
    newFilters.splice(storedFilterIdx + 1, 1);
    return newFilters;
  }
};

const updateFilters = async (membershipId, prop, filter) => {
  const filters = await get(TABLE_NAME, membershipId);
  
  if (isEmpty(filters)) {
    return await save(TABLE_NAME, {
      membership_id: membershipId,
      [prop]: [filter]
    });
  } else if (isEmpty(filters[prop])) {
    return await update(TABLE_NAME, {
      ...filters,
      [prop]: [filter]
    });
  } else {
    return await update(TABLE_NAME, {
      ...filters,
      [prop]: getNewFilterList(filters[prop], filter)
    });
  }
};

export const saveLastFilter = async (membershipId, pathname, filterOptions, filter) => {
  const name = getFormattedFilterName(filterOptions, filter);

  if (isEmpty(name)) {
    return;
  }

  const lastFilter = {
    name,
    variables: {
      ...filter,
      ...filter.start_date && {start_date: formatDateAsYYYYMMDD(filter.start_date)},
      ...filter.end_date && {end_date: formatDateAsYYYYMMDD(filter.end_date)}
    }
  };

  try {
    await updateFilters(membershipId, pathname, lastFilter);
  } catch (e) {
    console.warn("Error saving last filter", e);
  }
};

export const getFilterFor = async (membershipId, filterLocation) => {
  const favourites = await get(TABLE_NAME, membershipId) || {};
  return favourites[filterLocation] || [];
};