/* This component redirects to the path passed in the props.
  It receives a page, an id (it may be null), and the message to be used in the link. */
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import appRoutes from "_routes";
import { lsyRouter } from "_helpers";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lsyRouterStyle.js";

const useStyles = makeStyles()(styles);

function LsyRouter(props) {
  const { classes,cx } = useStyles();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const { page, id } = props;

  const getBaseUrl = () => {
    const pathname = window.location.pathname;
    const privateRoutes = appRoutes.private;

    return privateRoutes[Object.keys(privateRoutes).filter(prop => pathname.includes(privateRoutes[prop]))];
  };

  const path = `${getBaseUrl()}/${currentMembership?.server_alias}`;
  
  return <Link to={`${path}${lsyRouter(page, id)}`} className={cx(classes.text, props.className)} data-testid={props.testId}>
    {props.children}
  </Link>;
}

LsyRouter.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.any,
  testId: PropTypes.any
};

export default LsyRouter;