import { useState, useEffect, useCallback, useMemo, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect, useCustomCompareMemo } from "use-custom-compare";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual, pick } from "lodash";
import { useForm } from "react-hook-form";

import AddIcon from "@mui/icons-material/Add";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { hasAbility, invalidTagRegex } from "_helpers";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { fetchErrors } from "_utils";
import { lockService, tagService, siteService } from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";

import {
  Collapse,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

import CustomForm from "_components/Form/CustomForm";
import CustomModal from "_components/Modal/CustomModal";
import Panel from "_components/Panel/Panel";
import ItemPanel from "_components/Panel/ItemPanel";
import SelectLockGroup from "_components/Select/SelectLockGroup";
import SelectLock from "_components/Select/SelectLock";
import LsyRouter from "_components/Navigation/LsyRouter";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockEditStyle.js";

const useStyles = makeStyles()(styles);

const MAX_NOTE_MESSAGE_LENGTH = 1024;

function LockEdit() {
  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useLsyHistory();

  const lockId = useParams().id;
  const [ state, setState ] = useReducer(genericReducer,
    {
      lock: {},
      lockGroup: {},
      replacementLock: {},
      replacementNote: "",
      isLoading: true,
      deactivationNote: "",
      showConfirmDeactivation: false
    }
  );

  const { lock } = state;
  const setLockGroup = lockGroup => setState({ lockGroup });
  const setReplacementLock = replacementLock => setState({ replacementLock });
  const setReplacementNote = replacementNote => setState({ replacementNote });
  const setDeactivationNote = deactivationNote => setState({ deactivationNote });
  const setShowConfirmDeactivation = useCallback(showConfirmDeactivation => setState({ showConfirmDeactivation }), []);

  const [showCreateSite, setShowCreateSite] = useState(false);
  const [showCreateTag, setShowCreateTag] = useState(false);

  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const canReadSites =
    hasAbility(currentMembership, "view", "lock_collections") &&
    ability.can("read", "lock_collections");
  const canReadLockGroups = hasAbility(currentMembership, "view", "lock_groups") &&
    ability.can("read", "lock_groups");
  const canCreateTags = ability.can("create", "tags") && hasLockLicense("tags", lock);
  const canReadTags = ability.can("read", "tags");
  const canReplaceLock = ability.can("create", "locks.replacements");
  const canDeactivateLock = ability.can("create", "locks.deactivation");

  const lockForm = useForm();
  const tagForm = useForm();
  const tagsSelected = {...tagForm.watch("tags")};
  const createTagForm = useForm({ mode: "onChange" });
  const siteForm = useForm();
  const siteSelected = {...siteForm.watch("site")};
  const createSiteForm = useForm();

  const fetchLock = useCallback(async () => {
    try {
      const { data } = await lockService.fetchLock({ lockId: lockId });

      setState({
        lock: data,
        lockGroup: data.lock_group_id ? { id: data.lock_group_id, name: data.lock_group_name } : {},
        isLoading: false
      });
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
      return [];
    }
  }, [lockId]);

  const fetchSites = async (inputValue) => {
    const options = {
      page_size: 5,
      ...inputValue && { search: inputValue }
    };

    try {
      const result = await siteService.fetchSites(options);
      return result.data.map((site) => (site ? { id: site.id, name: site.name } : null));
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  const fetchTags = async (inputValue) => {
    const options = {
      page_size: 5,
      ...inputValue && { search: inputValue }
    };

    try {
      const result = await tagService.fetchTags(options);
      return result.data.map((tag) => (tag ? { id: tag.id, name: tag.name } : null));
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  useEffect(() => {
    fetchLock();
  }, [fetchLock]);

  useCustomCompareEffect(() => {
    lockForm.reset(lock);
    tagForm.reset(lock);
    siteForm.reset(lock);
  }, [lock], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));
  
  const getErrorMessage = (errors) => {
    for (const key in errors) {
      if (errors[key].message) {
        return errors[key].message;
      }
    }

    return "";
  };

  const lockInfoOptions = useCustomCompareMemo(() => {
    return [
      {
        field: "name",
        type: "textField",
        label: t("label.lockName"),
        required: t("error.requiredField"),
        autoComplete: "off",
        defaultValue: lock.name
      },
      {
        field: "description",
        type: "textField",
        label: t("label.lockDescription"),
        required: t("error.requiredField"),
        autoComplete: "off",
        defaultValue: lock.description
      }
    ];
  }, [lock, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderLocklInfoForm = useMemo(() => {
    if (state.isLoading) {
      return <Skeleton variant="rounded" height={100} data-testid="skeletonLockInfo"/>;
    }

    return <CustomForm items={lockInfoOptions} form={lockForm}/>;
  }, [lockInfoOptions, lockForm, state.isLoading]);

  const updateLock = async (updatedLock) => {
    try {
      await lockService.updateLock({ lockId: lockId, payload: updatedLock });

      fetchLock();
      dispatch(alertActions.send(t("success.updateLock")));
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors.length > 0) {
        const maxTagLocksDetail = errors.find(o => o.source.field === "tag_ids");
        if (maxTagLocksDetail) {
          const found = maxTagLocksDetail.detail.match(/error.lock.tag_ids.count.gt.(\d)+/i);
          dispatch(alertActions.send(t("error.maxTagLocks", {maxTagLocks: found[1]}), "error"));
        } else if(errors.find(o => o.source.field === "name")) {
          dispatch(alertActions.send(t("error.existingLock"), "error"));
        } else {
          console.warn("Warning, failed to update lock", e);
          dispatch(alertActions.send(t("error.updateLock"), "error"));
        }
      } else {
        console.warn("Warning, failed to update lock", e);
        dispatch(alertActions.send(t("error.updateLock"), "error"));
      }
    }
  };
  
  const lockInfoSubmit = (data) => {
    const newNameDescription = {
      name: data.name,
      description: data.description
    };
    const lockCopy = pick(lock, ["name", "description"]);
    if (!isEqual(lockCopy, newNameDescription)) {
      updateLock(newNameDescription);
    }
  };

  const handleError = (errors)  => {
    dispatch(alertActions.send(getErrorMessage(errors), "error"));
  };

  const lockInfoButtons = () => {
    return Object.keys(lockForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: () => lockForm.reset() },
      { label: t("button.save"), onClick: lockForm.handleSubmit(lockInfoSubmit, handleError) }
    ] : [];
  };

  const renderLockGroup = () => {
    if (state.isLoading) {
      return <Skeleton variant="rounded" height={100} />;
    }

    return <SelectLockGroup
      lock={state.lock}
      lockGroup={state.lockGroup}
      setLockGroup={setLockGroup}
      className={classes.sizeBreak}
    />;
  };

  const lockGroupSubmit = () => {
    if (!isEqual(lock.lock_group_id, state.lockGroup.id)) {
      updateLock({ lock_group_id: !isEmpty(state.lockGroup) ? state.lockGroup.id : -1 });
    }
  };

  const groupButtons = () => {
    const resetLockGroup = () => setLockGroup(lock.lock_group_id ? { id: lock.lock_group_id, name: lock.lock_group_name } : {});
    
    return lock.lock_group_id !== state.lockGroup.id ? [
      { label: t("button.cancel"), onClick: resetLockGroup },
      { label: t("button.save"), onClick: lockGroupSubmit }
    ] : [];
  };

  const siteOptions = useCustomCompareMemo(() => {
    if (!canReadSites) {
      return [];
    }

    return [{
      field: "site",
      type: "typeAhead",
      className: classes.sizeBreak,
      defaultValue: lock.site || [],
      placeholder: t("widgetField.site"),
      async: true,
      isMulti: false,
      autoFocus: false,
      isClearable: true,
      backspaceRemovesValue: false,
      disabled: !hasLockLicense("lock_collections", lock),
      showIcon: showCreateSite,
      icon: hasLockLicense("lock_collections", lock) ? (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={t("label.createSite")}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() => setShowCreateSite(true)}
            size="large">
            <AddIcon/>
          </IconButton>
        </Tooltip>
      ) : null,
      promiseOptions: fetchSites
    }];
  }, [lock, showCreateSite, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderSiteForm = useCustomCompareMemo(() => {
    if (state.isLoading) {
      return <Skeleton variant="rounded" height={100} />;
    }

    return <CustomForm items={siteOptions} form={siteForm}/>;
  }, [siteOptions, siteForm, siteSelected, state.isLoading], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const cancelChangeSite = () => {
    setShowCreateSite(false);
    siteForm.reset(lock);
  };

  const siteSubmit = (data) => {
    const site = pick(lock, ["site"]);
    if (!isEqual(site, data)) {
      updateLock({ site_id: data.site ? data.site.id : -1 });
    }
  };

  const siteButtons = () => {
    return Object.keys(siteForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelChangeSite },
      { label: t("button.save"), onClick: siteForm.handleSubmit(siteSubmit) }
    ] : [];
  };

  const createSiteOptions = useCustomCompareMemo(() => {
    return [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      autoComplete: "off",
      defaultValue: ""
    }];
  }, [t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderCreateSiteForm = useMemo(() => {
    return <CustomForm items={createSiteOptions} form={createSiteForm}/>;
  }, [createSiteOptions, createSiteForm]);

  const createSite = async (data) => {
    try {
      // request to server to create new site
      const value = await siteService.addSite({ data: data });

      //newSite data is stored
      const newSiteValue = value.data;

      //rests/closes form and issues dispatch
      setShowCreateSite(false);
      createSiteForm.reset();
      dispatch(alertActions.send(t("success.addSite")));

      //Form reflects the current selection immediately after the site is created.
      siteForm.setValue("site", {id: newSiteValue.id, name: newSiteValue.name});

      //updating values in the backend
      await updateLock({site_id: newSiteValue.id});

    } catch (e) {
      const errors = fetchErrors(e);

      if(errors.length > 0) {
        const nameNotUnique = errors.find(o => o.detail === "error.name.not_unique");
        if (nameNotUnique) {
          console.warn(t("error.existingSite", e));
          dispatch(alertActions.send(t("error.existingSite"), "error"));
        } else {
          console.warn("Warning, failed to add site", e);
          dispatch(alertActions.send(t("error.addSite"), "error"));
        }
      } else {
        console.warn("Warning, failed to add site", e);
        dispatch(alertActions.send(t("error.addSite"), "error"));
      }
    }
  };

  const cancelCreateSite = () => {
    setShowCreateSite(false);
    createSiteForm.reset();
  };

  const createSiteButtons = () => {
    return Object.keys(createSiteForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelCreateSite },
      { label: t("button.save"), onClick: createSiteForm.handleSubmit(createSite, handleError) }
    ] : [];
  };

  const tagOptions = useCustomCompareMemo(() => {
    if (!canReadTags) {
      return [];
    }

    return [{
      field: "tags",
      type: "typeAhead",
      placeholder: t("features.tags"),
      asyncProps: { isDisabled: !hasLockLicense("tags", lock) },
      isMulti: true,
      autoFocus: false,
      className: classes.sizeBreak,
      showIcon: showCreateTag,
      icon: canCreateTags ?
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={t("label.createTag")}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() => setShowCreateTag(true)}
            size="large">
            <AddIcon/>
          </IconButton>
        </Tooltip> : null,
      promiseOptions: fetchTags
    }];
  }, [lock, t, showCreateTag], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderTagForm = useCustomCompareMemo(() => {
    if (state.isLoading) {
      return <Skeleton variant="rounded" height={100} />;
    }

    return <CustomForm items={tagOptions} form={tagForm}/>;
  }, [tagOptions, tagForm, tagsSelected, state.isLoading], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const cancelChangeTag = () => {
    setShowCreateTag(false);
    tagForm.reset(lock);
  };

  const tagSubmit = (data) => {
    const tags = pick(lock, ["tags"]);
    if (!isEqual(tags, data)) {
      updateLock({ tag_ids: data.tags.map((tag) => tag.id) });
    }
  };

  const tagsButtons = () => {
    return Object.keys(tagForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelChangeTag },
      { label: t("button.save"), onClick: tagForm.handleSubmit(tagSubmit) }
    ] : [];
  };

  const createTagOptions = useCustomCompareMemo(() => {
    if (!canCreateTags){
      return [];
    }

    return [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      validateEntry: value => !invalidTagRegex.test(value),
      validateEntryMessage: t("error.invalidCreateChars"),
      autoComplete: "off",
      defaultValue: ""
    }];
  }, [lock, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderCreateTagForm = useMemo(() => {
    return <CustomForm items={createTagOptions} form={createTagForm}/>;
  }, [createTagOptions, createTagForm]);

  const createTag = async (data) => {
    try {
      await tagService.createTag({ data: data });
      setShowCreateTag(false);
      createTagForm.reset();
      dispatch(alertActions.send(t("success.createTag")));
    } catch (e) {
      console.warn("Warning, failed to create tag", e);
      dispatch(alertActions.send(t("error.createTag"), "error"));
    }
  };

  const cancelCreateTag = () => {
    setShowCreateTag(false);
    createTagForm.reset();
  };

  const createTagButtons = () => {
    return Object.keys(createTagForm.formState.dirtyFields).length > 0 ? [
      { label: t("button.cancel"), onClick: cancelCreateTag },
      { label: t("button.save"), onClick: createTagForm.handleSubmit(createTag, handleError), disabled: !isEmpty(Object.keys(createTagForm.formState.errors)) }
    ] : [];
  };

  const handleReplacementLock = useCallback(() => {
    history.pushWithState(`/locks/${lockId}/replacement/${state.replacementLock.id}`, { replacementNote: state.replacementNote });
  }, [history, lockId, state.replacementLock.id, state.replacementNote]);

  const replaceButtons = useCallback(() => {
    return isEmpty(state.replacementLock) ? [] :
      [
        { label: t("button.cancel"), onClick: () => setState({ replacementLock: {}, replacementNote: "" }) },
        { label: t("button.replace"), onClick: handleReplacementLock }
      ];
  }, [state.replacementLock, handleReplacementLock, t]);

  const lockOptionDisabled = lock => {
    return lock.id == lockId;
  };

  const renderDirectReplacement = useCustomCompareMemo(() => {
    return canReplaceLock && <Panel isCollapse={true} isSubPanel={false} expanded={false}
      title={t("label.lockReplacement.title")}
      description={t("label.lockReplacement.editDescription", { hardwareType: lock.hardware_type, lockName: lock.full_identifier })}
    >
      { state.isLoading ?
        <Skeleton variant="rounded" height={100} /> :
        <>
          <ItemPanel>
            <SelectLock
              lock={state.replacementLock}
              setLock={setReplacementLock}
              optionDisabled={lockOptionDisabled}
              optionsLimit={5}
              className={classes.sizeBreak}
              lockFilter={{ hardware_type: lock.hardware_type }}
              hideDisabledOptions={true}
              noOptionsMessage={() => t("fallbacks.noLocksSameHwFound")}
              placeholder={t("label.searchLocks")}
              menuPlacement="top"
            />
          </ItemPanel>
          <ItemPanel buttons={replaceButtons()}>
            <TextField
              data-testid={"textFieldReplacementNote"}
              onKeyDown={(ev) => {
                if (!isEmpty(state.replacementLock) && ev.key === "Enter") {
                  ev.preventDefault();
                  handleReplacementLock();
                }
              }}
              onChange={ev => setReplacementNote(ev.target.value)}
              value={state.replacementNote}
              className={classes.sizeBreak}
              disabled={isEmpty(state.replacementLock)}
              size="small"
              variant="outlined"
              label={`${t("widgetField.note")} (${t("label.optional")})`}
              inputProps={{ maxLength: MAX_NOTE_MESSAGE_LENGTH }}
            />
          </ItemPanel>
        </>
      }
    </Panel>;
  }, [canReplaceLock, classes.sizeBreak, lock.full_identifier, state.isLoading, state.replacementLock, state.replacementNote, replaceButtons, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const deactivationLock = async () => {
    try {
      await lockService.deactivateLock(lockId, { comments: state.deactivationNote });
      dispatch(alertActions.send(t("success.lock.deactivation")));
      history.replace(`/locks/${lockId}`);
    } catch (e) {
      console.warn("Warning, failed to deactivation lock", e);
      dispatch(alertActions.send(t("error.lock.deactivation"), "error"));
    }
  };

  const handleChangeDeactivationNote = ev => setDeactivationNote(ev.target.value);
  
  const renderLockDeactivation = useCustomCompareMemo(() => {
    if (!canDeactivateLock) {
      return null;
    }

    const handleEnter = (ev) => {
      if (ev.key === "Enter") {
        ev.preventDefault();
        setShowConfirmDeactivation(true);
      }
    };

    const deactivationButtons = [
      { label: t("button.deactivate"), onClick: () => setShowConfirmDeactivation(true) }
    ];

    return <Panel isCollapse={true} isSubPanel={false} expanded={false}
      title={t("label.lockDeactivation.title")}
      description={t("label.lockDeactivation.editDescription")}
    >
      { state.isLoading ?
        <Skeleton variant="rounded" height={100} /> :
        <ItemPanel buttons={deactivationButtons}>
          <TextField
            data-testid={"textFieldDeactivationNote"}
            onKeyDown={handleEnter}
            onChange={handleChangeDeactivationNote}
            className={classes.sizeBreak}
            value={state.deactivationNote}
            size="small"
            variant="outlined"
            label={`${t("widgetField.note")} (${t("label.optional")})`}
            inputProps={{ maxLength: MAX_NOTE_MESSAGE_LENGTH }}
          />
        </ItemPanel>
      }
    </Panel>;
  }, [canReplaceLock, state.isLoading, state.deactivationNote, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getConfirmDescription = () => {
    return <>
      <Typography variant="body2">{t("label.lockDeactivation.confirmDescription")}</Typography>
      <List dense disablePadding sx={{ listStyleType: "disc", pl: 4 }}>
        <ListItem disableGutters sx={{ display: "list-item" }}>
          <Typography variant="body2">{t("label.lockDeactivation.confirmDescriptionP1")}</Typography>
        </ListItem>
        <ListItem disableGutters sx={{ display: "list-item" }}>
          <Typography variant="body2">{t("label.lockDeactivation.confirmDescriptionP2")}</Typography>
        </ListItem>
        <ListItem disableGutters sx={{ display: "list-item" }}>
          <Typography variant="body2">{t("label.lockDeactivation.confirmDescriptionP3")}</Typography>
        </ListItem>
        <ListItem disableGutters sx={{ display: "list-item" }}>
          <Typography variant="body2">{t("label.lockDeactivation.confirmDescriptionP4")}</Typography>
        </ListItem>
      </List>
      <br/>
      <Typography variant="body2">{t("label.lockDeactivation.confirmDescriptionEnd", {lock: lock.full_identifier})}</Typography>
    </>;
  };

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.lsyBackground}>
        <Grid container className={classes.container} justifyContent="center" alignItems="center">
          <Grid item xs={11} lg={8}>
            <Grid container direction="column" alignItems="stretch" className={classes.margin}>
              <Grid item>
                <LsyRouter page="lock" id={lockId}>
                  <KeyboardBackspaceRoundedIcon className={classes.icon}/>
                </LsyRouter>
              </Grid>
              <Grid item>
                <Typography variant="h6">{t("features.lockSettings")}</Typography>
                <Typography variant="caption" className={classes.description}>{t("description.lockSettings")}</Typography>
              </Grid>
              <Grid item className={classes.content}>
                <Grid container direction="column" spacing={2}>
                  <Grid item data-testid="lockInfoSection">
                    <Panel isCollapse={true} isSubPanel={false} title={t("label.lockInfo")} description={t("description.lockInfo")}>
                      <ItemPanel>
                        <Typography variant="subtitle1">{t("label.hardwareId")}</Typography>
                        <Typography variant="caption" className={classes.description}>{lock.hardware_id}</Typography>
                      </ItemPanel>
                      <ItemPanel buttons={lockInfoButtons()}>
                        { renderLocklInfoForm }
                      </ItemPanel>
                    </Panel>
                  </Grid>
                  { canReadLockGroups &&
                    <Grid item data-testid="lockGroupsSection">
                      <Panel isCollapse={true} isSubPanel={false}
                        title={t("features.groups")}
                        description={t("description.lockSettingsGroup")}
                        extraInfo={hasLockLicense("lock_groups", lock) ? "" :
                          `${t("features.needStandardEnterpriseLicense")} ${t("features.basicLicenseAllowsDeselecting")}`}
                      >
                        <ItemPanel buttons={groupButtons()}>
                          { renderLockGroup() }
                        </ItemPanel>
                      </Panel>
                    </Grid>
                  }
                  { canReadSites &&
                    <Grid item data-testid="siteSection">
                      <Panel isCollapse={true} isSubPanel={false}
                        title={t("widgetField.site")} 
                        description={t("description.lockSettingsSite")}
                        extraInfo={hasLockLicense("lock_collections", lock) ? "" :
                          `${t("features.needStandardEnterpriseLicense")} ${t("features.basicLicenseAllowsDeselecting")}`}
                      >
                        <ItemPanel buttons={ !showCreateSite ? siteButtons() : [] }>
                          { renderSiteForm }
                        </ItemPanel>
                        <Collapse in={showCreateSite}>
                          <ItemPanel buttons={createSiteButtons()}>
                            { renderCreateSiteForm }
                          </ItemPanel>
                        </Collapse>
                      </Panel>
                    </Grid>
                  }
                  { canReadTags &&
                    <Grid item data-testid="tagsSection">
                      <Panel isCollapse={true} isSubPanel={false}
                        title={t("features.tags")}
                        description={t("description.lockSettingsTags")}
                        extraInfo={hasLockLicense("tags", lock) ? "" : t("features.needStandardEnterpriseLicense")}
                      >
                        <ItemPanel buttons={ !showCreateTag ? tagsButtons() : [] }>
                          { renderTagForm }
                        </ItemPanel>
                        <Collapse in={showCreateTag}>
                          <ItemPanel buttons={createTagButtons()} errorMsg={createTagForm.formState.errors?.name?.message}>
                            { renderCreateTagForm }
                          </ItemPanel>
                        </Collapse>
                      </Panel>
                    </Grid>
                  }
                  <Grid item data-testid="lockReplacementSection">
                    { renderDirectReplacement }
                  </Grid>
                  <Grid item data-testid="lockDeactivationSection">
                    <CustomModal
                      open={state.showConfirmDeactivation}
                      setOpen={setShowConfirmDeactivation}
                      handleSubmit={deactivationLock}
                      confirm={t("button.confirm")}
                      title={t("label.lockDeactivation.confirmTitle")}
                      description={getConfirmDescription()}
                      type="confirm"
                      modalStyle={classes.modal}
                    />
                    { renderLockDeactivation }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}

export default LockEdit;