const lsyFooterStyle = theme => ({
  lsyFooter:{
    backgroundColor:theme.lsyPalette.secondary.background,
    paddingBottom: theme.spacing(3)
  },
  footerLinks:{
    color:theme.lsyPalette.link,
    textDecoration:"none"
  },
  footer:{
    fontSize: "14px"
  },
  footerItems:{
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    color:theme.lsyPalette.footerText
  },
  footerText:{
    color:theme.lsyPalette.footerText,
    display: "flex",
    justifyContent: "center"
  }
});
export default lsyFooterStyle;