import { useContext, useReducer, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual } from "lodash";
import queryString from "query-string";
import moment from "moment";

import { alertActions } from "_actions";
import { config } from "_configs/server-config";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import {
  LockBatteryStatus,
  LockRtmStatus,
  LockOpenStatus
} from "_constants/lock.constants.js";
import { genericReducer } from "_reducers/general.reducer";
import { lockService } from "_services/lockstasy";
import { isBlank, hasAbility, lsyRouter, findLockErrorString, getLocalTZ } from "_helpers";
import { hasNewFirmwareAvailable } from "_helpers/lock";
import { hasLockLicense, getLicenseName } from "_services/lockstasy/helper";

// @mui/material components
import {
  Alert,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

//components
import DateTimeRange from "_components/Date/DateTimeRange";
import CustomModal from "_components/Modal/CustomModal";
import SelectUser from "_components/Select/SelectUser";

//icons
import {
  Edit as EditIcon,
  Dialpad as DialpadIcon,
  LocationOn as LocationOnIcon,
  LockReset as LockResetIcon
} from "@mui/icons-material";
import GenericLock from "assets/teleporte/GenericLock";
import { ConnectedIcon, LockBatteryIcon, LockIcons } from "assets/teleporte/Icons";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/lockDetailsWidgetStyle.js";
import Sera4talIcon from "assets/teleporte/Sera4talIcon";

const useStyles = makeStyles()(styles);

const FSU_MODE_24H = 1;
const FSU_MODE_4H = 2;
const INVALID_MEMBERSHIP_STATUS_FOR_FSU = [0, 3, 4];

function LockDetailsWidget(props) {
  const { history, lock, fetchLock, setRefreshKeys } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const sera4Mode = useSelector((state) => state.auth.sera4Mode);
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const [state, setState] = useReducer(genericReducer,
    {
      lock: lock,
      totalSize: 0,
      modalOpen: false,
      accessModalOpen: false,
      tagModalOpen: false,
      siteModalOpen: false,
      loading: true,
      submittedAccess: false,
      submittedAccessData: {},
      user: {},
      showConfirmReactivation: false,
      reactivationComment: ""
    }
  );
  const setAccessModalOpen = (accessModalOpen) => setState({ accessModalOpen });
  const setUser = (user) => setState({ user });
  const defaultValues = {
    date: moment(),
    time: "",
    comments: ""
  };
  const setShowConfirmReactivation = showConfirmReactivation => setState({ showConfirmReactivation });
  const setReactivationComment = reactivationComment => setState({ reactivationComment });
  const canCreateKeys = ability.can("create", "keys");
  const canReadSites =
    hasAbility(currentMembership, "view", "lock_collections") &&
    ability.can("read", "lock_collections");
  const canReadLockGroups = hasAbility(currentMembership, "view", "lock_groups") &&
    ability.can("read", "lock_groups");
  const canReactivateLock = ability.can("create", "locks.deactivation");

  const { handleSubmit, reset, setValue, formState: { errors }, control, watch, clearErrors } = useForm({ defaultValues });
  const watchAllFields = watch();

  const siteRow = (lock) => {
    return <span
      data-testid="siteLink"
      className={classes.actionLink}
      onClick={() => {
        history.push(lsyRouter("site", lock?.site?.id));
      }}
    >
      {lock?.site?.name}
    </span>;
  };

  const lockGroupRow = (lock) => {
    return (
      <span
        data-testid="lockGroupLink"
        className={classes.actionLink}
        onClick={() => {
          history.push(lsyRouter("lock_group", lock.lock_group_id));
        }}
      >
        {lock.lock_group_name}
      </span>
    );
  };

  const lockOrganizationRow = (lock) => lock.organization_name || currentMembership.tenant_name;

  const lockStatusRow = (lock) => {
    const rtmStatusTime = lock?.rtm_board_status?.disconnected_at ? lock.rtm_board_status?.disconnected_at : lock.rtm_board_status?.connected_at;

    return (
      <>
        {lock?.site && canReadSites &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={t("label.viewSiteLocation")}
        >
          <IconButton
            data-testid="siteLocationButton"
            className={classes.siteLocationIcon}
            size="small"
            onClick={() => {
              history.push(`/locations?site_id=${lock?.site?.id}`);
            }}
          >
            <LocationOnIcon/>
          </IconButton>
        </Tooltip>
        }

        {!isBlank(lock?.last_report?.closed) &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          className={classes.noHover}
          placement="bottom"
          title={t(`label.lockOpenStatus.${LockOpenStatus[lock.last_report.closed]}`)}
        >
          <IconButton
            data-testid="lockStatusButton"
            disableRipple
            className={classes.noHover}
            size="small"
          >
            <GenericLock
              className={classes.lockInfoIcons}
              width="23px"
              height="23px"
              closed={lock.last_report.closed}
            />
          </IconButton>
        </Tooltip>
        }
        {!isBlank(lock?.battery_status) &&
        <Tooltip
          className={classes.noHover}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={t(`label.batteryStatus.${LockBatteryStatus[lock.battery_status]}`)}
        >
          <IconButton
            data-testid="batteryStatusButton"
            disableRipple
            className={classes.noHover}
            size="small"
          >
            <LockBatteryIcon
              className={classes.lockInfoIcons}
              width="23px"
              height="23px"
              level={lock.battery_status}
            />
          </IconButton>
        </Tooltip>
        }
        {!isBlank(lock.rtm_board_status) &&
        <Tooltip
          className={classes.noHover}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={t(`label.rtmStatus.${LockRtmStatus[lock.rtm_board_status?.disconnected_at ? false : true]}`, {timestamp: moment(rtmStatusTime).format("lll")})}
        >
          <IconButton
            data-testid="rtmStatusButton"
            disableRipple
            className={classes.noHover}
            size="small"
          >
            <ConnectedIcon
              className={cx(classes.lockInfoIcons,classes.lockInfoIconSolidFill )}
              maskClass={lock.rtm_board_status?.disconnected_at ? classes.rtmDisconnectedColor : classes.lockInfoMask}
              width="23px"
              height="23px"
              connected={lock.rtm_board_status?.disconnected_at ? false : true}
            />
          </IconButton>
        </Tooltip>
        }
      </>
    );
  };

  const getRows = () => {
    let rows = [
      {
        label: t("label.hardwareId"),
        field: "hardware_id"
      },
      {
        label: t("label.hardwareType"),
        field: "hardware_type"
      }
    ];
    if (canReadSites) {
      rows.push({
        label: t("widgetField.site"),
        field: "site.name",
        custom: true,
        func: siteRow
      });
    }
    if (canReadLockGroups) {
      rows.push({
        label: t("label.lockGroup"),
        field: "lock_group_name",
        custom: true,
        func: lockGroupRow
      });
    }
  
    rows = rows.concat([
      {
        label: t("label.organization"),
        field: "organization",
        custom: true,
        func: lockOrganizationRow
      },
      {
        label: t("widgetField.firmware"),
        field: "firmware_version_current"
      },
      {
        label: t("widgetField.license"),
        field: "license",
        custom: true,
        func: (lock) => {
          if (lock) {
            const name = getLicenseName(lock?.license);
            const till = name?.expiration?.params?.till || "";
            return `${t(name.name)}${!isBlank(till) ? " | " + till : ""}`;
          } else {
            return "";
          }
        }
      },
      {
        label: t("widgetField.lastReport"),
        field: "last_report.last_known_timestamp",
        custom: true,
        func: (lock) => {
          if(!lock?.last_report?.last_known_timestamp)
            return "";
  
          return moment(lock?.last_report?.last_known_timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
        }
      }
    ]);
  
    if (lock.deactivated) {
      rows.push({
        label: t("label.status"),
        field: "deactivated",
        custom: true,
        func: (lock) => lock.deactivated && t("label.lockDeactivated")
      });
    }

    return rows;
  };

  const onAccessSubmit = async (data) => {
    if (state.submittedAccess) {
      reset(defaultValues);
      setState({
        accessModalOpen: false,
        submittedAccess: false,
        user: {}
      });
      return;
    }

    const payload = {
      membership_id: state.user.id,
      start_date_time: data.time,
      email_access_code: true,
      comments: data.comments,
      client_timezone: getLocalTZ()
    };

    try {
      const result = await lockService.sendAccessCode({
        lockId: state.lock.id,
        payload
      });
      setState({
        submittedAccessData: {...result.data, ...data, user: state.user},
        submittedAccess: true
      });
      setRefreshKeys(true);
    } catch (e) {
      const errorMsg = findLockErrorString(e) || "error.fullPageTitle";
      
      dispatch(alertActions.send(t(errorMsg), "error"));
    }
  };

  const accessForm = useCallback(() => {

    const findMinimumWindow = (selectedStartDate, windowSize) => {

      let now = moment();
      const startOfDay = moment(selectedStartDate).startOf("day");

      if (startOfDay.toDate() > now.toDate()) {
        now = moment(startOfDay);
      }

      const nowUtc = now.subtract(now.utcOffset(), "minutes").set({minute:0,second:0,millisecond:0});
      const nowUtcHours = nowUtc.hours();
      const utcRemainder = nowUtcHours % windowSize;
      const intervalUtc = nowUtc.subtract(utcRemainder, "hours");

      return intervalUtc.add(now.utcOffset(), "minutes");
    };

    const calculateAccessTimeOptions = (selectedStartDate) => {
      if (![FSU_MODE_24H, FSU_MODE_4H].includes(state.lock?.fsu_mode)) {
        return [];
      }

      const windowSize = state.lock?.fsu_mode == FSU_MODE_24H ? 24 : 4;
      const timeWindow = findMinimumWindow(selectedStartDate, windowSize);
      let timeWindows = [];
      let endOfDay = moment(selectedStartDate).endOf("day");

      while (timeWindow.toDate() <= endOfDay.toDate()) {
        timeWindows.push(timeWindow.clone());
        timeWindow.add(windowSize, "hours");
      }

      return timeWindows;
    };

    const isOptionDisabled = (user) => INVALID_MEMBERSHIP_STATUS_FOR_FSU.includes(user.status);

    return (
      <div>
        <SelectUser
          className={classes.itemBox}
          user={state.user}
          setUser={setUser}
          isOptionDisabled={isOptionDisabled}
        />
        <Controller
          name={"date"}
          control={control}
          render={({ field }) => (
            <DateTimeRange 
              date={field.value}
              setDate={field.onChange}
              isRange={false}
              numberOfMonths={1}
              label={t("instructions.selectDate")}
              format="YYYY/MM/DD"
              showTime={false}
              minDate={moment().startOf("day").toDate()}
            />
          )}
        />
        <Controller
          rules={{
            required: true
          }}
          render={({ field }) =>
            <TextField
              {...field}
              className={classes.itemBox}
              label={t("instructions.selectTime")}
              role="listbox"
              variant="outlined"
              select
            >
              {calculateAccessTimeOptions(watchAllFields.date).map(
                (item, index) => {
                  return item ? (
                    <MenuItem
                      key={`${item.format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}-${index}`}
                      value={item.toISOString()}
                    >
                      {item.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </MenuItem>
                  ) : null;
                }
              )}
            </TextField>
          }
          control={control}
          name={"time"}
        />
        <Controller
          rules={{
            required: currentMembership.id === state.user.id
          }}
          render={({ field, formState }) =>
            <TextField
              {...field}
              data-testid="commentsField"
              label={t("label.comments")}
              variant="outlined"
              fullWidth
              placeholder={t("label.maxChars", { num: 100 })}
              inputProps={{ maxLength: 100 }}
              required={currentMembership.id === state.user.id}
              error={formState.errors.comments ? true : false}
            />
          }
          control={control}
          name={"comments"}
        />
      </div>
    );
  }, [
    classes,
    control,
    watchAllFields,
    state.lock,
    state.user,
    currentMembership,
    t
  ]);

  const submittedAccessForm = useCallback(() => {
    const validFrom = moment(state.submittedAccessData.time).format("lll");
    const validTill = state.lock?.fsu_mode === 1 ? moment(state.submittedAccessData.time).add(24, "hours").format("lll") : moment(state.submittedAccessData.time).add(4, "hours").format("lll");
    return <div data-testid="accessCodeConfirmContent">
      <div>{t("button.accessCode")} <strong>{state.submittedAccessData.access_code}</strong></div>
      <br/>
      <div>{t("label.validFrom")} <strong>{validFrom}</strong></div>
      <br/>
      <div>{t("label.validTill")} <strong>{validTill}</strong></div>
      <br/>
      <div>{t("label.emailSentTo")} <strong>{state.submittedAccessData.user.name}</strong></div>
    </div>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submittedAccessData, t]);

  const formatValue = (value) => {
    if (value && value.length > 40) {
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={value}
          interactive
        >
          <span>{value.slice(0, 40) + "..."}</span>
        </Tooltip>
      );
    } else {
      return value;
    }
  };

  useCustomCompareEffect(() => {
    if (lock?.id) {
      setState({ lock, loading: false });
    }
  }, [lock], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const confirmText = useMemo(() => (state.submittedAccess ? t("button.ok") : t("button.submit")), [
    state.submittedAccess
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cancelText = useMemo(() => (state.submittedAccess ? t("button.cancel") : null), [
    state.submittedAccess
  ]);

  const fsuDisabled = () => {
    if (state.lock) {
      const tenantDoesntHaveFSU = !currentMembership.features.access_codes;
      const lockLicenseDoesntHaveFSU = !hasLockLicense("access_codes", state.lock);
      const lockFirmwareDoesntHaveFSU = ![FSU_MODE_24H, FSU_MODE_4H].includes(state.lock.fsu_mode);
      return tenantDoesntHaveFSU || lockLicenseDoesntHaveFSU || lockFirmwareDoesntHaveFSU;
    }
    return true;
  };

  const goToSera4tal = (event) => {
    history.push1(`${config.adminUrl}/locks?search=0x${state.lock.hardware_id}`, event);
  };

  const goToLockEdit = () => {
    history.push(lsyRouter("lock_edit", lock.id));
  };

  const reactivationLock = async () => {
    try {
      await lockService.reactivateLock(lock.id, { comments: state.reactivationComment });
      dispatch(alertActions.send(t("success.lock.reactivation")));
      fetchLock();
    } catch (e) {
      console.warn("Warning, failed to reactivation lock", e);
      dispatch(alertActions.send(t("error.lock.reactivation"), "error"));
    }
  };

  const openReactivationModal = () => setShowConfirmReactivation(true);
  const handleChangeComment = event => setReactivationComment(event.target.value);
  const handleCancelReactivation = () => {
    setReactivationComment("");
    setShowConfirmReactivation(false);
  };

  const getReactivationModalContent = () => {
    return <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography data-testid="confirmReactivationDescription">
          {t("label.lockReactivation.confirmDescription", { newLine: <br/>, lock: state.lock.full_identifier })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={state.reactivationComment}
          onChange={handleChangeComment}
          label={`${t("widgetField.note")} (${t("label.optional")})`}
          fullWidth
        />
      </Grid>
    </Grid>;
  };

  const renderTopBar = () => {
    if (state.loading) {
      return <div data-testid="topBarSection" className={classes.topBar}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          className={classes.name}
        />
      </div>;
    }

    return <div data-testid="topBarSection" className={classes.topBar}>
      <span className={cx(classes.name, {[classes.disable]: lock.deactivated})}>
        {formatValue(state.lock.full_identifier)}
      </span>
      {sera4Mode &&
        <IconButton
          data-testid="sera4talButton"
          size="small"
          onClick={goToSera4tal}
          className={classes.sera4IconButton}
        >
          <Sera4talIcon/>
        </IconButton>
      }
      {hasAbility(currentMembership, "edit", "access_codes") &&
      canCreateKeys && !fsuDisabled() && !lock.deactivated ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={lock.semaphore_locked ? t("error.lock.locked") : t("button.accessCode")}
          >
            <span>
              <IconButton
                data-testid="fsuButton"
                disabled={lock.semaphore_locked}
                className={classes.iconButton}
                size="small"
                onClick={() => setAccessModalOpen(true)}
              >
                <DialpadIcon className={!lock.semaphore_locked ? classes.icon : null} />
              </IconButton>
            </span>
          </Tooltip>
        ) : null}
      {ability.can("update", "locks") && !lock.deactivated &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={lock.semaphore_locked ? t("error.lock.locked") : t("button.editLock")}
        >
          <span>
            <IconButton
              data-testid="editButton"
              disabled={lock.semaphore_locked}
              className={classes.iconButton}
              size="small"
              onClick={goToLockEdit}
            >
              <EditIcon className={lock.semaphore_locked ? null : classes.icon} />
            </IconButton>
          </span>
        </Tooltip>
      }
      {lock.deactivated && canReactivateLock &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={t("button.reactivate")}
        >
          <span>
            <IconButton
              data-testid="reactivationButton"
              className={classes.iconButton}
              size="small"
              onClick={openReactivationModal}
            >
              <LockResetIcon className={classes.icon} />
            </IconButton>
          </span>
        </Tooltip>
      }
    </div>;
  };

  const renderDescriptionSection = () => {
    return <>
      <div className={classes.marginTop}>
        <span className={cx({ [classes.value]: state.loading, [classes.disable]: lock.deactivated })}>
          {state.lock.description}
        </span>
      </div>
      { lock.semaphore_locked && 
        <div className={classes.marginTop}>
          <Alert data-testid="replacementInProgressAlert" severity="warning" className={classes.alert}>
            <Typography variant="body2">
              {t("label.lockReplacement.replacementInProgress")}
            </Typography>
          </Alert>
        </div>
      }
    </>;
  };

  const renderTableLoading = () => {
    return <>
      <tr className={classes.skeleton}>
        <td><Skeleton data-testid="skeleton" height={60} variant="text" /></td>
      </tr>
      {[...Array(5).keys()].map((val, i) => (
        i < 2 ? <tr key={val}>
          <td><Skeleton data-testid="skeleton" height={30} width={"90%"} variant="text" /></td>
        </tr> : <tr key={val}>
          <td><Skeleton data-testid="skeleton" height={30} width={"75%"} variant="text" /></td>
        </tr>
      ))}
    </>;
  };

  const renderContentSection = () => {
    return <div data-testid="lockContentSection" className={classes.lockDetailsContainer}>
      <div className={classes.lockDetails}>
        {state.loading ? <Skeleton data-testid="skeleton" variant="rectangular" className={classes.lockIconSkeleton}/> :
          <LockIcons
            type={state.lock.hardware_type}
            defaultStyle={classes.defaultIcon}
            disabled={lock.deactivated}
            hasFirmwareUpgrade={hasNewFirmwareAvailable(lock)}
            firmwareIconSize='md'
            height="200px"
            width="200px"
          />}
      </div>
      <table className={classes.table}>
        <tbody>
          {state.loading ? renderTableLoading() :
            getRows().map((val) => {
              return (
                <tr key={val.label} className={classes.textWrapNone}>
                  <td data-testid="lockDetailLabel" className={classes.field}>{val.label}</td>
                  <td data-testid="lockDetailValue" className={cx({ [classes.value]: state.loading, [classes.disable]: lock.deactivated })}>
                    {val.custom ? val.func(lock) : state.lock[val.field] }
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>;
  };

  const renderFooterSection = () => {
    if (state.loading) {
      return <Skeleton
        data-testid="skeleton"
        animation="wave"
        className={classes.name}
      />;
    }

    if (lock.deactivated) {
      return null;
    }

    return <div data-testid="lockFooterSection" className={classes.lockBottomInfoWrap}>
      {state.lock?.tags?.length > 0 && ability.can("read", "tags") &&
        <div className={classes.chips}>
          {state.lock.tags.map((tag) => {
            const navigateToTag = () =>
              history.push(`/locks?sort=name&${queryString.stringify({ tag_ids: [tag.id] })}`);

            return <Tooltip
              classes={{ tooltip: classes.tooltip }}
              key={tag.id}
              title={`${t("label.showLocksTaggedWith")} ${tag.name}`}
            >
              <Chip
                data-testid="tagChip"
                className={classes.chip}
                label={tag.name}
                onClick={navigateToTag}
                size="small"
              />
            </Tooltip>;
          })}
        </div>
      }
      <div className={classes.lockStatusWrap}>{lockStatusRow(state.lock)}</div>
    </div>;
  };

  return <>
    <CustomModal
      open={state.accessModalOpen}
      setOpen={setAccessModalOpen}
      handleSubmit={handleSubmit(onAccessSubmit)}
      handleCancel={() => {
        reset(defaultValues);
        setState({
          setSubmittedAccess: false,
          user: {}
        });
      }}
      title={t("button.accessCode")}
      type={state.submittedAccess ? "alert" : "confirm"}
      modalStyle={classes.modal}
      confirm={confirmText}
      cancel={cancelText}
      submit
      manualClose
      disableBackdropClick={true}
      errors={errors}
      clearErrors={clearErrors}
      control={control}
      setValue={setValue}
      description={
        state.submittedAccess ? submittedAccessForm() : accessForm()
      }
    />
    <CustomModal
      open={state.showConfirmReactivation}
      setOpen={setShowConfirmReactivation}
      handleSubmit={reactivationLock}
      confirm={t("button.confirm")}
      title={t("label.lockReactivation.confirmTitle")}
      description={getReactivationModalContent()}
      type="confirm"
      cancel={t("button.cancel")}
      handleCancel={handleCancelReactivation}
      modalStyle={classes.modal}
    />
    {renderTopBar()}
    <Divider className={classes.divider} />
    {renderDescriptionSection()}
    {renderContentSection()}
    {renderFooterSection()}
  </>;
}

LockDetailsWidget.propTypes = {
  history: PropTypes.object,
  lock: PropTypes.object,
  fetchLock: PropTypes.func,
  setRefreshKeys: PropTypes.func
};

export default LockDetailsWidget;
