import { useEffect, useCallback, useMemo, useRef, Fragment } from "react";

//components
import RegularButton from "_components/Button/RegularButton";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";
import GridItem from "components/Grid/GridItem";
import DateTimeRange from "_components/Date/DateTimeRange";

//material core components
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  Menu
} from "@mui/material";

import {
  Clear as ClearIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import moment from "moment";

import CustomAsyncSelect from "_components/Select/CustomAsyncSelect";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Modal/customModalStyle";

import PropTypes from "prop-types";

const useStyles = makeStyles()(styles);

const CustomModal = (props) => {
  const { t } = useTranslation("default");
  const { type, onOpen, open, submitting, errors, clearErrors, control, formOptions, setValue } = props;
  const { classes, cx } = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    if(open && onOpen) {
      onOpen();
    }

    const timeout = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = (e, reason) => {
    if (props.handleClose) {
      props.handleClose();
    }
    if (reason === "backdropClick") {
      if (!props.disableBackdropClick) {
        props.setOpen(false);
      }
    } else {
      props.setOpen(false);
    }

    if (!isEmpty(errors)) {
      clearErrors();
    }
  };

  const handleSubmit = () => {
    props.handleSubmit();
    if (!props.manualClose) {
      handleClose();
    }
  };

  const formItemHelper = useCallback((item) => {
    const itemSize = item.size || 12;

    const handleOnchange = (event) => {
      if (item.onChange) {
        item.onChange(event.target.value);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Tab") {
        setValue(item.field, event.currentTarget.dataset.value);
      }
    };

    switch (item.type) {
      case "select":
        return (
          <Fragment key={item.field}>
            <GridItem xs={itemSize} className={cx({ [classes.paddedItem]: item.padded })}>
              <FormControl variant="outlined" className={classes.itemGrid}>
                <InputLabel id={item.label}>
                  {item.label}
                </InputLabel>
                <Controller
                  defaultValue={item.defaultValue}
                  rules={{
                    required: item.required
                  }}
                  render={({ field }) => 
                    <Select
                      {...field}
                      className={classes.itemBox}
                      label={item.label}
                      aria-label={item.label}
                      role="listbox"
                      inputProps={{
                        ref: item.autoFocus ? inputRef : undefined
                      }}>
                      {item.options.map((item, index) => {
                        return (
                          <MenuItem
                            key={`${item.field}-${index}`}
                            value={item.value}
                            onKeyDown={handleKeyDown}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  control={control}
                  name={item.field}
                />
              </FormControl>
            </GridItem>
            {item.helperIcon ? <GridItem xs={1} className={classes.helperIcon}>{item.helperIcon}</GridItem> : null}
          </Fragment>
        );
      case "autoComplete":
        return (
          <Fragment key={item.field}>
            <GridItem key={item.field} xs={itemSize} className={cx(classes.itemBox, { [classes.paddedItem]: item.padded })}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={item.options}
                    disableClearable={item.disableClearable || false}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    autoHighlight={true}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={item.label}
                        variant="outlined"
                        required={item.required}
                      />
                    )}
                    onChange={(_, data) => {
                      if (!data && item.fallbackValue) {
                        field.onChange(item.fallbackValue);
                      } else {
                        field.onChange(data);
                      }
                    }}
                  />
                )}
                name={item.field}
                control={control}
                defaultValue={item.defaultValue}
              />
            </GridItem>
            {item.helperIcon ? <GridItem xs={1} className={classes.helperIcon}>{item.helperIcon}</GridItem> : null}
          </Fragment>
        );
      case "textField":
        return (
          <Fragment key={item.field}>
            <GridItem className={classes.itemBox} xs={itemSize}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid className={classes.itemGrid} item>
                  <Controller
                    control={control}
                    name={item.field}
                    rules={{
                      required: item.required,
                      pattern: item.pattern || null,
                      maxLength: item.maxLength,
                      validate: {
                        validEntry: value => {
                          if (item.validateEntry) {
                            return item.validateEntry(value, item.field);
                          } else {
                            return true;
                          }
                        }
                      }
                    }}
                    defaultValue={item.defaultValue || ""}
                    render={({ field, formState }) => {
                      return <TextField
                        {...field}
                        onKeyDown={(ev) => {
                          if (item.submitOnEnter && ev.key === "Enter") {
                            setValue(item.field, ev.target.value);
                            handleSubmit();
                            ev.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          handleOnchange(e);
                        }}
                        className={classes.itemGrid}
                        id={item.field}
                        variant="outlined"
                        type={item.inputType}
                        multiline={item.multiline}
                        maxRows={item.rowsMax}
                        inputProps={item.inputProps}
                        label={item.label}
                        placeholder={item.placeholder || ""}
                        error={formState.errors[item.field] ? true : false}
                        helperText={formState.errors[item.field] ? formState.errors[item.field].message || item.errorMsg || t("error.invalidEntry") : item.helperText}
                        inputRef={item.autoFocus ? inputRef : undefined}
                        data-testid={`${item.field}-textfield`}
                        value={field.value}
                        style={item.style}
                      />;
                    }}
                  />
                </Grid>
              </Grid>
            </GridItem>
            {item.helperIcon ? <GridItem xs={1} className={classes.helperIcon}>{item.helperIcon}</GridItem> : null}
          </Fragment>
        );
      case "switch":
        return (
          <GridItem key={item.field} xs={itemSize} className={classes.itemBox}>
            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-end" classes={{ root: cx({ [classes.paddedItem]: item.padded }) }}>
              <Grid item>
                <Typography style={{ display: "inline-flex" }}>
                  {item.label}
                </Typography>
              </Grid>
              <Grid item classes={{ root: classes.inherit }}>
                <Controller
                  name={item.field}
                  control={control}
                  defaultValue={item.defaultValue}
                  render={({ field }) => (
                    <ToggleSwitch
                      checked={item.disabled ? item.defaultValue : field.value}
                      handleChange={(e) => field.onChange(e)}
                      disabled={item.disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </GridItem>
        );
      case "datePicker":
        return (
          <GridItem key={item.field} xs={itemSize} className={cx(classes.itemBox)} >
            <Grid container spacing={1} justifyContent="space-between" alignItems="center" classes={{ root: cx({ [classes.paddedItem]: item.padded }) }}>
              <Grid item classes={{ root: classes.inherit }} xs={item.clearable ? 11 : 12}>
                <Controller
                  name={item.field}
                  control={control}
                  defaultValue={item.defaultValue}
                  render={({ field }) => (
                    <DateTimeRange 
                      date={field.value && moment(field.value)}
                      setDate={field.onChange}
                      isRange={false}
                      numberOfMonths={1}
                      label={item.label}
                      format="YYYY/MM/DD"
                      showTime={false}
                      minDate={item.disablePast && moment().startOf("day").toDate()}
                      maxDate={item.disableFuture && moment().add(1, "day").startOf("day").toDate()}
                    />
                  )}
                />
              </Grid>
              {item.clearable && 
              <Grid item className={classes.inherit} xs={1} style={{marginTop: "8px"}}>
                <Controller
                  name={item.field}
                  control={control}
                  defaultValue={item.defaultValue}
                  render={({ field }) => (
                    <IconButton size="small" onClick={() => field.onChange(null)}><ClearIcon/></IconButton>)}/>
              </Grid>
              }
            </Grid>
          </GridItem>
        );
      case "timePicker":
        return (
          <GridItem key={item.field} xs={itemSize} className={cx(classes.itemBox)} >
            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-end" classes={{ root: cx({ [classes.paddedItem]: item.padded }) }}>
              <Grid item classes={{ root: classes.inherit }} xs={12}>
                <Controller
                  name={item.field}
                  control={control}
                  defaultValue={item.defaultValue}
                  render={({ field }) => (
                    <DateTimeRange 
                      date={field.value && moment(field.value)}
                      setDate={field.onChange}
                      isRange={false}
                      numberOfMonths={1}
                      label={item.label}
                      format="YYYY/MM/DD HH:mm"
                      showTime={true}
                      minDate={item.disablePast && moment().startOf("day").toDate()}
                      maxDate={item.disableFuture && moment().add(1, "day").startOf("day").toDate()}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </GridItem>
        );
      case "typeAhead": {
        const selectComponent = field => {
          if (item.async) {
            return <CustomAsyncSelect
              {...field}
              ref={item.autoFocus ? inputRef : undefined}
              class={classes.itemBox}
              isMulti={item.isMulti || false}
              backspaceRemovesValue={item.backspaceRemovesValue}
              placeholder={item.placeholder}
              loadOptions={item.promiseOptions}
              isOptionDisabled={(option) => item?.disabled || option.disabled}
              {...item.asyncProps}
            />;
          }
          return null;
        };
        return (
          <Fragment key={item.field}>
            <GridItem xs={itemSize}>
              <Controller
                control={control}
                defaultValue={item.defaultValue}
                name={item.field}
                render={({ field }) => selectComponent(field)}
              />
            </GridItem>
            {item.helperIcon ? <GridItem xs={1} className={classes.helperIcon}>{item.helperIcon}</GridItem> : null}
          </Fragment>
        );
      }
      default:
        break;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [errors]);

  const formCreator = useMemo(() => {
    return (
      <Grid container className={classes.marginTop}>
        { formOptions ? formOptions.map((item) => {
          return formItemHelper(item);
        }) : null}
      </Grid>
    );
  }, [formOptions, formItemHelper, classes]);

  const form = () => {
    return (
      <div
        className={cx(classes.modal, { [props.modalStyle]: props.modalStyle })} >
        <DialogTitle
          className={cx(classes.title, { [props.titleStyle]: props.titleStyle })}
          id="form-dialog-title">
          <span style={{ flexGrow: "1" }}>{props.title}</span>
          {props.clearIcon ? <IconButton size="small" onClick={() => handleClose()}><ClearIcon className={classes.clearIcon} /></IconButton> : null}
        </DialogTitle>
        <DialogContent
          className={cx(classes.content, { [props.contentStyle]: props.contentStyle })}
        >
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              {props.description ? props.description : null}
              {type === "formCreator" ? formCreator : null}
            </Grid>
            { !isEmpty(props.lastFilters) && 
              <Grid item xs={12}>
                <Typography className={classes.lastFiltersTitle}><b>{t("label.lastFilters")}</b></Typography>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                  {props.lastFilters.map(filter => {
                    const renderChipContent = () => {
                      const formattedTitle = filter.name.replaceAll("|", "\n");
                      return <Tooltip placement="bottom-start" title={<div className={classes.tooltipTitle}>{formattedTitle}</div>}>
                        <Typography variant="caption">{filter.name}</Typography>
                      </Tooltip>;
                    };

                    const handleApplyFilter = () => {
                      props.applyLastFilter(filter);
                    };

                    return <Grid item key={filter.name} xs={4}>
                      <Chip
                        label={renderChipContent()}
                        size="small"
                        className={classes.chip}
                        onClick={handleApplyFilter}
                      />
                    </Grid>;
                  })}
                </Grid>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions
          className={cx(classes.action, { [props.actionStyle]: props.actionStyle })} >
          <RegularButton
            className={cx(classes.cancelButton, { [props.cancelButtonStyle]: props.cancelButtonStyle })}
            disabled={props.cancelDisabled}
            onClick={() => {
              if (props.handleCancel) {
                props.handleCancel();
              }
              handleClose();
            }}>
            {props.cancel || t("button.cancel")}
          </RegularButton>
          {props.additionalClose ? <RegularButton
            className={cx(classes.cancelButton, { [props.cancelButtonStyle]: props.cancelButtonStyle })}
            onClick={() => handleClose()}
          >
            {props.confirm || t("button.close")}
          </RegularButton> : <RegularButton
            {...props.confirmButtonProps}
            className={cx(classes.confirmButton, { [props.confirmButtonStyle]: props.confirmButtonStyle })}
            onClick={handleSubmit}
            disabled={submitting || props.confirmDisabled}
            type="submit"
            autoFocus
          >
            {props.confirm || t("button.submit")}
          </RegularButton>}
        </DialogActions>
      </div>
    );
  };

  const custom = () => {
    return (
      <div className={cx(classes.modal, { [props.modalStyle]: props.modalStyle })}>
        <DialogTitle
          className={cx(classes.title, { [props.titleStyle]: props.titleStyle })}
          id="custom-dialog-title">
          <span style={{ flexGrow: "1" }}>{props.title}</span>
          {props.clearIcon ?
            <IconButton data-testid="closeButton" size="small" onClick={() => handleClose()}>
              <ClearIcon className={classes.clearIcon} />
            </IconButton> :
            null}
        </DialogTitle>
        <DialogContent
          className={cx(classes.content, { [props.contentStyle]: props.contentStyle })}>
          {props.description}
        </DialogContent>
        <DialogActions
          className={cx(classes.action, { [props.actionStyle]: props.actionStyle })}>
          {props.cancelButton ?
            <RegularButton
              className={cx(classes.cancelButton, { [props.cancelButtonStyle]: props.cancelButtonStyle })}
              onClick={() => {
                if (props.handleCancel) {
                  props.handleCancel();
                }
                handleClose();
              }}>
              {props.cancel || t("button.cancel")}
            </RegularButton>
            : null}
          {props.customButton ?
            <RegularButton
              className={cx(classes.customButton, { [props.customButtonStyle]: props.customButtonStyle })}
              onClick={() => {
                props.customAction();
                if (!props.manualClose) {
                  handleClose();
                }
              }}
              type="button"
              disabled={props.customDisabled}
            >
              {props.custom}
            </RegularButton> : null}
          {props.confirmButton ?
            <RegularButton
              className={cx(classes.confirmButton, { [props.confirmButtonStyle]: props.confirmButtonStyle })}
              onClick={() => {
                props.handleSubmit();
                if (!props.manualClose) {
                  handleClose();
                }
              }}
              type="button"
              autoFocus
              disabled={props.confirmDisabled}
            >
              {props.confirm || t("button.ok")}
            </RegularButton> : null}
        </DialogActions>
      </div>
    );
  };

  const getOffset = () => {
    if (props.anchorEl) {
      let rect = props.anchorEl.getBoundingClientRect();
      return rect;
    }
    return;
  };

  const getXPos = (rect) => {
    if (rect.left <= 137.5)
      return rect.x;
    else if (window.innerWidth - rect.x <= 137.5)
      return (window.innerWidth - rect.x) / 2;
    else
      return `${100 * rect.right / (rect.right + rect.left)}%`;
  };

  const customPopover = () => {
    let rect = getOffset();
    return (
      <Menu
        data-testid="customPopover"
        id="active-popover"
        anchorEl={props.anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            "overflow": "visible",
            "filter": "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "mt": 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: rect && inputRef ? window.innerHeight - rect?.y > rect?.y - rect?.height ? 0 : 100 : 100,
              left: rect ? getXPos(rect) : 0,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <DialogTitle
          className={cx(classes.title, { [props.titleStyle]: props.titleStyle })}
          id="custom-dialog-title">
          <div className={classes.header}><div className={classes.calloutTitle}>{props.title}</div><div className={classes.calloutIcon}>{props.calloutIcon}</div></div>
          {props.clearIcon ? <IconButton size="small" onClick={() => handleClose()}><ClearIcon className={classes.clearIcon} /></IconButton> : null}
        </DialogTitle>
        <DialogContent
          className={cx(classes.content, { [props.contentStyle]: props.contentStyle })}>
          {props.description}
        </DialogContent>
        <DialogActions
          className={cx(classes.action, { [props.actionStyle]: props.actionStyle })}>
          {props.cancelButton ?
            <RegularButton
              className={cx(classes.cancelButton, { [props.cancelButtonStyle]: props.cancelButtonStyle })}
              onClick={() => {
                if (props.handleCancel) {
                  props.handleCancel();
                }
                handleClose();
              }}>
              {props.cancel || t("button.cancel")}
            </RegularButton>
            : null}
          {props.customButton ?
            <RegularButton
              className={cx(classes.customButton, { [props.customButtonStyle]: props.customButtonStyle })}
              onClick={() => {
                props.customAction();
                if (!props.manualClose) {
                  handleClose();
                }
              }}
              type="button"
              disabled={props.customDisabled}
            >
              {props.custom}
            </RegularButton> : null}
          {props.confirmButton ?
            <RegularButton
              className={cx(classes.confirmButton, { [props.confirmButtonStyle]: props.confirmButtonStyle })}
              onClick={(e) => {
                props.handleSubmit(e);
                if (!props.manualClose) {
                  handleClose();
                }
              }}
              type="button"
              autoFocus
              disabled={props.confirmDisabled}
            >
              {props.confirm || t("button.ok")}
            </RegularButton> : null}
        </DialogActions>
      </Menu>
    );
  };

  const confirm = () => {
    return (
      <div
        className={cx(classes.modal, { [props.modalStyle]: props.modalStyle })}>
        <DialogTitle
          className={cx(classes.title, { [props.titleStyle]: props.titleStyle })}
          id="confirm-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent
          className={cx(classes.content, { [props.contentStyle]: props.contentStyle })}>
          {props.description}
        </DialogContent>
        <DialogActions
          className={cx(classes.action, { [props.actionStyle]: props.actionStyle })}>
          <RegularButton
            onClick={() => {
              if (props.handleCancel) {
                props.handleCancel();
              }
              handleClose();
            }}
            className={cx(classes.cancelButton, { [props.cancelButtonStyle]: props.cancelButtonStyle })}
          >
            {props.cancel || t("button.cancel")}
          </RegularButton>
          {!props.hideConfirmButton ? <RegularButton
            {...props.confirmButtonProps}
            className={cx(classes.confirmButton, { [props.confirmButtonStyle]: props.confirmButtonStyle })}
            onClick={() => {
              props.handleSubmit();
              if (!props.manualClose) {
                handleClose();
              }
            }}
            type="button"
            autoFocus
          >
            {props.confirm || t("button.ok")}
          </RegularButton> : null}
        </DialogActions>
      </div>
    );
  };

  const alert = () => {
    return (
      <div
        className={cx(classes.modal, { [props.modalStyle]: props.modalStyle })}>
        <DialogTitle
          className={cx(classes.title, { [props.titleStyle]: props.titleStyle })}
          id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent
          className={cx(classes.content, { [props.contentStyle]: props.contentStyle })}
          id="alert-dialog-description">
          {props.description}
        </DialogContent>
        <DialogActions
          className={cx(classes.action, { [props.actionStyle]: props.actionStyle })}>
          <RegularButton
            {...props.confirmButtonProps}
            className={cx(classes.confirmButton, { [props.confirmButtonStyle]: props.confirmButtonStyle })}
            onClick={() => {
              if (props.handleSubmit) {
                props.handleSubmit();
              }
              if (!props.manualClose) {
                handleClose();
              }
            }}
            autoFocus
          >
            {props.confirm || t("button.ok")}
          </RegularButton>
        </DialogActions>
      </div>
    );
  };

  var modal;
  switch (type) {
    case "confirm":
      modal = confirm;
      break;
    case "custom":
      modal = custom;
      break;
    case "customPopover":
      modal = customPopover;
      break;
    case "popoverCreator":
      modal = customPopover;
      break;
    case "form":
      modal = form;
      break;
    case "formCreator":
      modal = form;
      break;
    case "alert":
      modal = alert;
      break;
    default:
      break;
  }
  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
      maxWidth={false}
      hideBackdrop = {["customPopover", "popoverCreator"].includes(type) ? true : false}
    >
      {props ? modal() : null}
    </Dialog>
  );
};

CustomModal.defaultProps = {
  clearErrors: () => {}
};

CustomModal.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  cancel: PropTypes.string,
  confirm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  custom: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  submit: PropTypes.bool,
  clearIcon: PropTypes.bool,
  calloutIcon: PropTypes.object,
  cancelButton: PropTypes.bool,
  customButton: PropTypes.bool,
  confirmButton: PropTypes.bool,
  customAction: PropTypes.func,
  onSubmitFunc: PropTypes.func,
  filterOptions: PropTypes.array,
  handleCancel: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  manualClose: PropTypes.bool,
  modalStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  titleStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  contentStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  actionStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  cancelButtonStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  confirmButtonStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  customButtonStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  cancelButtonProps: PropTypes.object,
  confirmButtonProps: PropTypes.object,
  customButtonProps: PropTypes.object,
  hideConfirmButton: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  customDisabled: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  formOptions: PropTypes.array,
  errors: PropTypes.object,
  clearErrors: PropTypes.func,
  onOpen: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  additionalClose: PropTypes.bool,
  anchorEl: PropTypes.object,
  lastFilters: PropTypes.array,
  applyLastFilter: PropTypes.func
};

export default CustomModal;
