import { useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter } from "_helpers";
import { isLicenseExpiryAlertsOn } from "_helpers/systemSettings";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { licenseService } from "_services/lockstasy";

import { Alert } from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/licenseExpireAlertWidgetStyle";

const useStyles = makeStyles()(styles);

const TIME_UNITS_TRANSLATE_MAP = Object.freeze({
  d: "label.timeUnits.days",
  w: "label.timeUnits.weeks",
  m: "label.timeUnits.months",
  y: "label.timeUnits.years"
});

const ENDS_BEFORE_DEFAULT_VALUES = ["", "30d", "90d"];

const FETCH_INTERVAL = 1000 * 60 * 15; // 15 minutes

function LicenseExpireAlertWidget() {
  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const currentMembership = useSelector((state) => state.memberships?.currentMembership);
  const { orgPreferences } = useContext(LsyAdminDataContext);
  const history = useLsyHistory();
  const showAlert = isLicenseExpiryAlertsOn(orgPreferences);
  const licensesExpiry = useMemo(() => JSON.parse(localStorage.getItem("licensesExpiryAlert")) || {}, []);

  const [ state, setState ] = useReducer(genericReducer, {
    endsBefore: "",
    hasLicenseExpire: false,
    severity: "error"
  });

  const goToLockReport = () => history.push(lsyRouter("reports", "locks"));

  const fetchLicensesWillExpire = useCallback(async () => {
    const isSameOrganization = licensesExpiry.membershipId === currentMembership.id;
    const isInFetchInterval = Date.now() - licensesExpiry.fetchedAt < FETCH_INTERVAL;
    const shouldUseCachedData = !isEmpty(licensesExpiry) && isInFetchInterval && isSameOrganization;
    
    if (shouldUseCachedData) {
      setState({ ...licensesExpiry.data });
      return;
    }

    const license_expiry_alerts = orgPreferences.license_expiry_alerts;

    try {
      const fetchLicenses = async (ends_before) => {
        const response = await licenseService.fetchExpiredLicensesCount({ ends_before });
        return Object.values(response.data.license_types).some(value => value > 0);
      };

      let data = {};

      if (typeof license_expiry_alerts === "string") {
        let hasLicenseExpire = await fetchLicenses(license_expiry_alerts);
        data = { endsBefore: license_expiry_alerts, hasLicenseExpire };
        setState({ endsBefore: license_expiry_alerts, hasLicenseExpire });
      } else {
        for (const ends_before of ENDS_BEFORE_DEFAULT_VALUES) {
          let hasLicenseExpire = await fetchLicenses(ends_before);

          if (hasLicenseExpire) {
            const severity = ends_before === "90d" ? "warning" : "error";
            data = { endsBefore: ends_before, hasLicenseExpire, severity };
            setState({
              endsBefore: ends_before,
              hasLicenseExpire,
              severity: severity
            });
            break;
          }
        }
      }

      localStorage.setItem("licensesExpiryAlert", JSON.stringify({ fetchedAt: Date.now(), data: data, membershipId: currentMembership.id}));
    } catch (e) {
      console.warn("Warning, failed to fetch licenses data", e?.response || e);
    }
  }, [orgPreferences, licensesExpiry, currentMembership.id]);

  useEffect(() => {
    if (!isEmpty(orgPreferences) && !state.endsBefore && showAlert) {
      fetchLicensesWillExpire();
    }
  }, [orgPreferences, showAlert, state.endsBefore, fetchLicensesWillExpire]);

  const params = {
    page: <span className={classes.actionLink} onClick={goToLockReport}>{t("reports.lockReport")}</span>,
    ...state.endsBefore ?
      { expireTime: `${state.endsBefore.slice(0, -1)} ${t(`${TIME_UNITS_TRANSLATE_MAP[state.endsBefore.slice(-1).toLowerCase()]}`).toLowerCase()}` } :
      {}
  };

  return showAlert && state.hasLicenseExpire && 
  <ErrorBoundary>
    <Alert severity={state.severity} className={classes.alert} data-testid="licenseExpireAlertId">
      {`${state.endsBefore ? t("lock.license.expireAlert", params) : t("lock.license.expiredAlert")} `}
      {t("lock.license.expireAlertReport", params)}
    </Alert>
  </ErrorBoundary>;
}

export default LicenseExpireAlertWidget;