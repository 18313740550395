import { useCallback, useEffect, useState, useMemo, createContext, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { lockstasyAbility } from "_abilities/lockstasyAbility";
import { roleService, organizationService, userService } from "_services/lockstasy";
import { adminConstants } from "_constants/admin.constants";
import { config } from "_configs/server-config";
import { memberActions } from "_actions";

import { defaultPreferences } from "_constants/systemSettings.constants";

import { encodePreferences, decodePreferences } from "_helpers/systemSettings";
import { isBlank } from "_helpers";

function isSera4talOrTAP() {
  const path = window.location.pathname;
  return path.startsWith(config.adminUrl) || path.startsWith(config.tapUrl);
}

export const LsyAdminDataContext = createContext();

export const LsyAdminDataProvider = (props) => {
  const [schema, setSchema] = useState(null);
  const [orgPreferences, setOrgPreferences] = useState({});
  const [isScrollToTop, setIsScrollToTop] = useState(false);
  const dispatch = useDispatch();

  const schemaLoaded = useRef(false);
  const currentMembership = useSelector((state) => state.memberships?.currentMembership);
  const isRegularUser = currentMembership?.role_id === adminConstants.LSY_USER;
  const abilityRef = useRef(null);
  const { t } = useTranslation("default");

  const ability = useMemo(() => {
    try {
      if (schema) {
        let caslAbility = lockstasyAbility(schema);
        schemaLoaded.current = true;
        abilityRef.current = caslAbility;
        return caslAbility;
      } else {
        return lockstasyAbility({}, true);
      }
    } catch (e) {
      console.warn("Failed to format schema", e);
      let caslAbility = lockstasyAbility({});
      schemaLoaded.current = true;
      abilityRef.current = caslAbility;
      return caslAbility;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [schema]);

  const userGroupMap = useMemo(() => {
    return {
      "administrator": t("form.administrator"),
      "user": t("widgetField.user"),
      "access.manager": t("form.accessManager"),
      "configuration.manager": t("form.configurationManager")
    };
  }, [t]);

  const fetchRoles = useCallback(async () => {
    try {
      const result = await roleService.fetchRoleSchema({ membershipId: currentMembership.id });
      setSchema(result.data.schema);
    } catch (e) {
      console.warn("Failed to fetch roles", e);
      setSchema({});
    }
  }, [currentMembership]);

  const fetchPreferences = useCallback(async (force = false) => {
    if(!force && orgPreferences) 
      return;

    try {
      let result = await organizationService.fetchPreferences();
      
      if(result?.data?.preferences) {
        const decodedOptions = decodePreferences(result.data.preferences);
        setOrgPreferences({...decodedOptions});
      } else {
        const result = await organizationService.updatePreferences(encodePreferences(defaultPreferences));
        const decodedOptions = decodePreferences(result.data.preferences);
        setOrgPreferences({...decodedOptions});
      }
    } catch (e) {
      console.warn("Warning, failed to fetch organization preferences", e);
      setOrgPreferences({});
    }
  }, [orgPreferences]);

  const fetchUserSettings = useCallback(async (force = false) => {
    if (!force && currentMembership.settings)
      return;

    try {
      const result = await userService.fetchUser({ id: currentMembership.id });
      const settings = { session_timeout: result.data.session_timeout };
      dispatch(memberActions.setSettingsCurrentMembership(settings));
    } catch (e) {
      console.warn("Warning, failed to fetch user settings", e);
    }
  }, [currentMembership, dispatch]);

  useEffect(() => {
    if (currentMembership && !isSera4talOrTAP()) {
      fetchRoles();
      fetchUserSettings();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentMembership?.id]);

  useEffect(() => {
    if(isBlank(isRegularUser) || !currentMembership || isSera4talOrTAP() || !ability)
      return;

    if(!isRegularUser && ability.can("read",  "organizations"))  {
      fetchPreferences(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [ability]);

  const providerValue = useMemo(() => {
    return {
      ability: ability,
      schemaLoaded: schemaLoaded,
      schema: schema,
      abilityRef: abilityRef,
      userGroupMap: userGroupMap,
      fetchPreferences: fetchPreferences,
      fetchUserSettings: fetchUserSettings,
      orgPreferences: orgPreferences,
      isScrollToTop: isScrollToTop,
      setIsScrollToTop: setIsScrollToTop
    };
  }, [ability, schemaLoaded, schema, abilityRef, userGroupMap, fetchPreferences, fetchUserSettings, orgPreferences, isScrollToTop]);

  return (
    <LsyAdminDataContext.Provider value={providerValue}>
      {props.children}
    </LsyAdminDataContext.Provider>
  );
};

LsyAdminDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};