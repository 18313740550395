import { useState, useEffect, useReducer, Fragment, useContext, isValidElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CodeBlock } from "react-code-blocks";

import { alertActions, modalActions } from "_actions";
import { maxAPIKeys } from "_constants";
import { isBlank } from "_helpers";
import { tableReducer } from "_reducers/table.reducer";
import { organizationService, apiTokenService } from "_services";
import { fetchErrorSymbols, fetchStatus } from "_utils";

// @mui/material components
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";

import RegularButton from "_components/Button/RegularButton";
import Divider from "_components/Divider/Divider";
import LoadingPlaceHolder from "_components/Loading";
import CustomModal from "_components/Modal/CustomModal";
import DataTable from "_components/Table/DataTable";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";

import {
  Check as CheckIcon,
  FileCopyOutlined as FileCopyOutlinedIcon
} from "@mui/icons-material";

// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";

import WebhooksInspector from "_containers/Webhooks/WebhooksInspector";
import WebhooksSelector from "_containers/Webhooks/WebhooksSelector";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/developersStyle.js";

const useStyles = makeStyles()(styles);

function LsyDevelopers({ tenantId }) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const currentMembership = useSelector((state) => state.memberships?.currentMembership);
  const user = useSelector((state) => state.auth.user);
  const domain = localStorage.getItem("domain");

  const { handleSubmit, setError, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const [values, setValues] = useState({
    showSigningKey: false
  });

  const [newApiKey, setNewApiKey] = useState();
  const [keyLimitReached, setKeyLimitReached] = useState(false);
  const [orgDataFetched, setOrgDataFetched] = useState(false);
  const [scriptSelected, setScriptSelected] = useState("createSession");
  const [isOpenApiScriptModal, setIsOpenApiScriptModal] = useState(false);
  const openApiScriptModal = () => setIsOpenApiScriptModal(true);

  const [webhookSigningKey, setWebhookSigningKey] = useState();
  const [endpointUrl, setEndpointUrl] = useState();
  const [webhookOptions, setWebhookOptions] = useState({});

  const [disableUpdateSignature, setDisableUpdateSignature] = useState(false);
  const [disableUpdateEndpoint, setDisableUpdateEndpoint] = useState(false);
  const [signatureUpdatedText, setSignatureUpdatedText] = useState();
  const [endpointUpdatedText, setEndpointUpdatedText] = useState();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const canUpdate = ability.can("update", "webhooks");
  const [data, setData] = useReducer(tableReducer, [
    {
      id: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      ),
      account: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      ),
      comment: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      ),
      enabled: false,
      created_at: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      )
    }
  ]);

  const fetchWebhookData = async () => {
    const result = await organizationService.fetchOrganizationData({
      id: tenantId
    });

    const { enabled, url } = result.data?.api_configuration?.webhooks || {};

    setWebhookOptions(result.data?.api_configuration?.webhooks?.options || {});

    setWebhookSigningKey(
      result.data?.api_configuration?.webhooks?.options?.authentication
    );
    setEndpointUrl(url);
    setOrgDataFetched(true);

    setValues((v) => ({
      ...v,
      disableCreateKey: false,
      endpointStatus: enabled && !isBlank(url)
    }));
  };

  const fetchApiTokens = async () => {
    try {
      const result = await apiTokenService.fetchApiTokens({
        page_size: 50
      });

      const elements = result.data.map((element) => {
        const accountName = element.account
          ? `${element.account.first_name} ${element.account.last_name}`
          : null;

        const showUpdateCommentAction = !isBlank(element.comment) ? true : false;

        const { id, reference_id, enabled, created_at } = element;

        return {
          id,
          reference_id,
          enabled,
          created_at,
          account: accountName,
          comment: (
            <Fragment>
              {showUpdateCommentAction ? element.comment : null}
            </Fragment>
          )
        };
      });

      setData({ action: "replace", data: elements });
    } catch (e) {
      setData({ action: "clear" });
    }
  };

  useEffect(() => {
    fetchWebhookData();
    fetchApiTokens();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const createNewApiKey = async () => {
    setValues({ ...values, disableCreateKey: true });

    try {
      const result = await apiTokenService.createApiToken();

      setNewApiKey(result.data.id);
      fetchApiTokens();
    } catch (e) {
      setValues({ ...values, disableCreateKey: true });
      setKeyLimitReached(true);

      console.warn(e);
    }
  };

  const onWebhookEndpointUpdate = async (data) => {
    setDisableUpdateEndpoint(true);

    try {
      await organizationService.updateOrganizationWebhooks({
        bodyParams: { webhook_url: data["webhook_url"] }
      });

      setValues((v) => ({
        ...v,
        endpointStatus: !isBlank(data["webhook_url"])
      }));

      setEndpointUpdatedText(t("actions.updated"));
      setDisableUpdateEndpoint(false);
    } catch (e) {
      const errors = fetchErrorSymbols(e);

      if (fetchStatus(e) === 422) {
        setError("webhook_url", {
          type: "manual",
          message: t(errors[0])
        });
      }
      console.warn(errors);
      setDisableUpdateEndpoint(false);
    }
  };

  const onSignatureUpdate = async (data) => {
    setDisableUpdateSignature(true);
    setSignatureUpdatedText();

    try {
      await organizationService.updateOrganizationWebhooks({
        bodyParams: { options: { authentication: data.signature } }
      });

      setSignatureUpdatedText(t("actions.updated"));
      setDisableUpdateSignature(false);
    } catch (e) {
      setDisableUpdateSignature(false);

      console.warn(e);
    }
  };

  // const deleteAPIKey = async (reference_id) => {
  //   const confirmPhrase = `DELETE ${reference_id}`;

  //   const textConfirm = ({ onChange }) => (
  //     <TextField
  //       fullWidth
  //       id="delete-confirm"
  //       required={true}
  //       variant="outlined"
  //       onChange={onChange}
  //       style={{ marginTop: 10}}
  //     />
  //   );

  //   const customButtonHandler = async () => {
  //     try{
  //       await apiTokenService.deleteApiToken({
  //         serverAlias,
  //         reference_id
  //       });
  //       fetchApiTokens(); // TEMPORARY - refetch all api tokens for now
  //     } catch(e){
  //       console.warn(e);
  //     }
  //   };

  //   const modalProps = {
  //     title: t("confirmation.deleteTitle"),
  //     message: t("confirmation.deleteAPI", { confirmText: confirmPhrase }),
  //     customButtonText: t("actions.delete"),
  //     alertConfirm: true,
  //     FormComponent: textConfirm,
  //     confirmPhrase,
  //     customButtonHandler,
  //   };
  //   dispatch(modalActions.showModal({ modalProps, modalType: "custom" }));
  // };

  const disableAPIKey = async (reference_id, checked) => {
    const keyword = checked ? t("actions.enable") : t("actions.disable");
    const confirmPhrase = `${keyword.toUpperCase()} ${reference_id}`;

    const message = checked ?
      t("confirmation.enableAPI", { confirmText: confirmPhrase }) :
      t("confirmation.disableAPI", { confirmText: confirmPhrase });

    const textConfirm = ({ onChange }) => (
      <TextField
        fullWidth
        id="disable-confirm"
        required={true}
        variant="outlined"
        onChange={onChange}
        style={{ marginTop: 10 }}
      />
    );

    const customButtonHandler = async () => {
      try {
        await apiTokenService.updateApiToken({
          reference_id,
          bodyParams: {
            enabled: checked
          }
        });

        setData({ action: "update", index: reference_id, indexColumn: "reference_id", targetColumn: "enabled", newValue: checked });
      } catch (e) {
        console.warn(e);
      }
    };

    const modalProps = {
      title: keyword,
      message: message,
      customButtonText: keyword.charAt(0).toUpperCase() + keyword.slice(1),
      alertConfirm: true,
      FormComponent: textConfirm,
      confirmPhrase,
      customButtonHandler
    };
    dispatch(modalActions.showModal({ modalProps, modalType: "custom" }));
  };

  // const updateAPIKeyComment = async (data) => {
  //   try {
  //     await apiTokenService.updateApiToken({
  //       serverAlias: serverAlias,
  //       reference_id: data.reference_id,
  //       bodyParams: {
  //         comment: "some comment",
  //       }
  //     });

  //     fetchApiTokens();
  //   } catch (e) {

  //     console.warn(e);
  //   }
  // };

  const showAPIKeyActionBox = () => {
    const actionClass = cx({ [classes.fadeAfterRemoval]: newApiKey });
    let actionButton;

    if (!newApiKey && !keyLimitReached) {
      actionButton = values.disableCreateKey ? (
        <LoadingPlaceHolder title={t("label.generatingToken")} />
      ) : (
        <div className={actionClass}>
          <Button
            disabled={values.disableCreateKey || !canUpdate}
            variant="outlined"
            color="secondary"
            onClick={() => createNewApiKey()}
          >
            {t("button.generateToken")}
          </Button>
        </div>
      );
    } else {
      if (keyLimitReached) {
        return <div className={classes.keyError}>{t("error.apiKeys.keyLimit")}</div>;
      }

      const adornmentIcon = (
        <CopyToClipboard text={newApiKey}>
          <InputAdornment
            classes={{ root: classes.fadeInElement }}
            position="end"
          >
            <IconButton aria-label="copy" size="large">
              <FileCopyOutlinedIcon />
            </IconButton>
          </InputAdornment>
        </CopyToClipboard>
      );
      actionButton = (
        <TextField
          fullWidth
          id="outlined-start-adornment"
          disabled={true}
          className={classes.signatureInput}
          classes={{ root: classes.fadeInElement }}
          InputProps={{
            endAdornment: adornmentIcon
          }}
          value={newApiKey}
          variant="outlined"
        />
      );
    }

    return <Fragment>{actionButton}</Fragment>;
  };

  const showEndpointStatus = () => {
    if (values.endpointStatus) {
      return <CheckIcon classes={{ root: cx(classes.okIcon, classes.textTop, classes.fadeIn) }} />;
    } else {
      return <CheckIcon style={{ opacity: 0 }} />;
    }
  };

  const showEndpointUpdateForm = () => {
    if (!orgDataFetched)
      return null;

    return (
      <div style={{ marginBottom: "40px" }}>
        <form onSubmit={handleSubmit(onWebhookEndpointUpdate)}>
          <Controller
            render={({ field, formState }) =>
              <TextField
                {...field}
                fullWidth
                className={classes.signatureInput}
                error={Boolean(formState.errors["webhook_url"])}
                label={t("label.webhooksURL")}
                InputProps={{
                  endAdornment: disableUpdateEndpoint ? (
                    <InputAdornment position="end">
                      <LoadingPlaceHolder />
                    </InputAdornment>
                  ) : null
                }}
                helperText={
                  formState.errors["webhook_url"]
                    ? formState.errors["webhook_url"].message
                    : endpointUpdatedText
                }
                variant="outlined"
              />
            }
            rules={{
              maxLength: 256
            }}
            defaultValue={endpointUrl}
            control={control}
            name="webhook_url"
            disabled={!canUpdate}
          />
          <RegularButton type="submit" justify={"right"} disabled={!canUpdate}>{t("actions.update")}</RegularButton>
        </form>
      </div>
    );
  };

  const showSignatureUpdateForm = () => {
    if (!orgDataFetched)
      return null;

    return (
      <div>
        <form onSubmit={handleSubmit(onSignatureUpdate)}>
          <Controller
            render={({ field, formState }) =>
              <TextField
                {...field}
                fullWidth
                className={classes.signatureInput}
                error={Boolean(formState.errors.signature)}
                label={t("label.signature")}
                InputProps={{
                  endAdornment: disableUpdateSignature ? (
                    <InputAdornment position="end">
                      <LoadingPlaceHolder />
                    </InputAdornment>
                  ) : null
                }}
                helperText={
                  formState.errors.signature
                    ? t("error.apiKeys.signature.maxLength")
                    : signatureUpdatedText
                }
                variant="outlined"
              />
            }
            rules={{
              maxLength: 36
            }}
            defaultValue={webhookSigningKey}
            control={control}
            name="signature"
            disabled={!canUpdate}
          />
          <RegularButton type="submit" justify={"right"} disabled={!canUpdate}>{t("actions.update")}</RegularButton>
        </form>
      </div>
    );
  };

  const handleOnCopyToClipboard = (_, result) => {
    return result ? dispatch(alertActions.send(t("auth:mfa.clipboardNotif"))) :
      dispatch(alertActions.send(t("auth:mfa.clipboardNotifFail"), "error"));
  };

  const requestScript = `s4_api_GET() {
    local endpoint=\${1:-users}
    local tenant_id=\${2:-${currentMembership?.tenant_id}}
    # This is generated in your portal; please copy and paste the value here or use the environment variable $TWS_API_TOKEN
    local tws_custom_api_token=\${TWS_API_TOKEN:-94ba*******************************};
  
    response=$(curl --silent --write-out "HTTPSTATUS:%{http_code}" --request GET "https://tws-api-\${tenant_id}.${domain}/v3/\${endpoint}" \\
          --header 'Content-Type: application/json' \\
          --header "Authorization: Bearer \${TWS_SESSION_TOKEN}" \\
          --header "tws-membership-id: \${TWS_MEMBERSHIP_ID}" \\
          --header "tws-organization-token: \${tws_custom_api_token}" \\
          --connect-timeout 10 --max-time 30)
  
    # Extract the body and the status from the response
    http_body=$(echo "$response" | sed -e 's/HTTPSTATUS:.*//g')
    http_status=$(echo "$response" | tr -d '\\n' | sed -e 's/.*HTTPSTATUS://')
  
    if [ "$http_status" -eq 000 ]; then
        echo "Error: Unable to connect to the server."
        echo "Response: $response"
        return 1
    elif [ "$http_status" -ge 300 ]; then
        echo "Error: HTTP status $http_status"
        echo "Response: $http_body"
        return 1
    fi
  
    echo "Response:"
    # print the response nicely from json format
    echo "$http_body" | jq
  }`;

  const createSessionScript = `s4_api_login() {
    local username=\${1:-${user.email}}
    local password=\${2:-password}
    local tenant_id=\${3:-${currentMembership?.tenant_id}}
    echo "Using Username: $username for tenant $tenant_id"
  
    response=$(curl --silent --write-out "HTTPSTATUS:%{http_code}" --request POST "https://tws-api-\${tenant_id}.${domain}/v3/sessions" \\
          --header 'Content-Type: application/json' \\
          --data-raw "{\\"username\\": \\"$username\\", \\"password\\": \\"$password\\"}" \\
          --connect-timeout 10 --max-time 30)
  
    # Extract the body and the status from the response
    http_body=$(echo "$response" | sed -e 's/HTTPSTATUS:.*//g')
    http_status=$(echo "$response" | tr -d '\\n' | sed -e 's/.*HTTPSTATUS://')
  
    if [ "$http_status" -eq 000 ]; then
        echo "Error: Unable to connect to the server."
        echo "Response: $response"
        return 1
    elif [ "$http_status" -ne 201 ]; then
        echo "Error: HTTP status $http_status"
        echo "Response: $http_body"
        return 1
    fi
    # Extract tws_token_data from the nested tws_token in the response
    tws_token_data=$(echo "$http_body" | jq -r '.tws_token.tws_token_data')
    # This jq command filters the tws_memberships array to find the object where tenant.id matches the specified value and then extracts the id field of that object.
    tws_membership_id=$(echo "$http_body" | jq -r '.tws_memberships[] | select(.tenant.id == "${currentMembership?.tenant_id}") | .id')
  
    export TWS_SESSION_TOKEN=$tws_token_data
    export TWS_MEMBERSHIP_ID=$tws_membership_id
    echo "Your membership_id is $tws_membership_id"
    echo "You may now access the Sera4 API with the following token: $tws_token_data"
    echo "This value is also accessible via the TWS_SESSION_TOKEN environment variable"
  }`;

  const runRequestScript = `source fileName.sh\n#${t("widget.explanations.apiTokensScriptObs4")}\nexport TWS_API_TOKEN=<your_generated_token>\ns4_api_GET users`;

  const runCreateSessionScript = "source fileName.sh\ns4_api_login \"\" your_password";

  const getScript = () => {
    if (scriptSelected === "createSession") {
      return {
        code: createSessionScript,
        extraInfo: runCreateSessionScript
      };
    } else if (scriptSelected === "getRequest") {
      return {
        code: requestScript,
        extraInfo: runRequestScript
      };
    } else {
      return "";
    }
  };

  const getApiScript = () => {
    const script = getScript();

    return <>
      <Typography variant="caption" className={classes.block}>
        <i>{`*${t("widget.explanations.apiTokensScriptObs1")}`}</i>
      </Typography>
      <Typography variant="caption" className={cx(classes.block, classes.marginTop)}>
        <b>{t("widget.explanations.apiTokensScriptObs2")}</b>
      </Typography>
      <Typography variant="caption" className={classes.block}>
        {t("widget.explanations.apiTokensScriptObs3")}
      </Typography>
      <div className={classes.codeContainer}>
        <CodeBlock
          text={script.extraInfo}
          language={"bash"}
          wrapLines
        />
      </div>
      <div className={classes.codeContainer}>
        <IconButton size={"small"} className={classes.copyButton}>
          <CopyToClipboard
            onCopy={handleOnCopyToClipboard}
            text={script.code}
          >
            <FileCopyOutlinedIcon fontSize={"small"}/>
          </CopyToClipboard>
        </IconButton>
        <CodeBlock
          text={script.code}
          language={"bash"}
          wrapLines
        />
      </div>
    </>;
  };

  const renderOrganizationToken = () => {
    return <Grid item>
      <Typography variant="h6">{t("label.organizationTokens")}</Typography>
      <span className={cx(classes.text, classes.marginTop)}>
        {t("widget.explanations.apiTokensIntro", { count: maxAPIKeys })}

        <p>
          {t("widget.explanations.apiTokensUrl", { url: `https://tws-api-${tenantId}.${domain}/v3` })}
        </p>
      </span>

      <div className={classes.actionBox}>{showAPIKeyActionBox()}</div>
      <div className={classes.tabContent}>
        {t("widget.explanations.apiTokensNote")}
      </div>
    </Grid>;
  };

  const renderWebhooks = () => {
    return <Grid item>
      <Typography variant="h6" className={classes.title}>
        {t("label.webhooks")} {showEndpointStatus()}
      </Typography>

      <span className={cx(classes.text, classes.marginTop)}>
        {t("widget.explanations.webhooksIntro")}
      </span>

      {showEndpointUpdateForm()}

      <Typography variant="h6" style={{ fontSize: 18, marginTop: "20px" }}>
        {t("label.security")}
      </Typography>

      <div className={cx(classes.text, classes.title)}>
        {t("widget.explanations.webhooksSecurity")}
      </div>

      {showSignatureUpdateForm()}
    </Grid>;
  };

  const openCreateSessionScriptModal = () => {
    setScriptSelected("createSession");
    openApiScriptModal();
  };

  const openGetRequestScriptModal = () => {
    setScriptSelected("getRequest");
    openApiScriptModal();
  };

  const renderApiHelp = () => {
    return <Grid item>
      <Typography variant="h6" className={classes.title}>
        {t("label.exampleApi")}
      </Typography>

      <div className={classes.tabContent}>
        {t("widget.explanations.apiTokensScript")}
      </div>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.tabContent}>
        <Grid item xs={12} sm={3} md={5.5} lg={5}>
          <Button variant="outlined" size="small" fullWidth className={classes.button} onClick={openCreateSessionScriptModal}>
            {t("label.createSession")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={8.5} md={6} lg={6.5}>
          {t("widget.explanations.apiTokensCreateSessionScript")}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.tabContent}>
        <Grid item xs={12} sm={3} md={5.5} lg={5}>
          <Button variant="outlined" size="small" fullWidth className={classes.button} onClick={openGetRequestScriptModal}>
            {t("label.getRequest")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={8.5} md={6} lg={6.5}>
          {t("widget.explanations.apiTokensGetRequestScript")}
        </Grid>
      </Grid>
    </Grid>;
  };

  return (
    <div className={classes.lsyBackground} style={{ paddingTop: "15px" }}>
      <CustomModal
        open={isOpenApiScriptModal}
        setOpen={setIsOpenApiScriptModal}
        type="custom"
        title={t("label.apiScripts")}
        description={getApiScript()}
        clearIcon
      />
      <Grid container justifyContent="center">
        <GridItem xs={12} md={5} lg={4}>
          <Card style={{ marginTop: "25px", height: "fit-content" }}>
            <CardBody>
              <Grid container direction="column" alignItems="stretch">
                { renderOrganizationToken() }
                <Divider autoPadded variant="middle" />
                { renderWebhooks() }
                <Divider autoPadded variant="middle" />
                { renderApiHelp() }
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={7} lg={6}>
          <Card style={{ marginTop: "25px", height: "fit-content" }}>
            <DataTable
              data={data}
              striped
              height={100}
              noResultsMessage={t("error.noOrgTokens")}
              columns={[
                {
                  name: t("label.token"),
                  selector: (row) => row.id
                },
                {
                  name: t("widgetField.user"),
                  selector: (row) => row.account
                },
                {
                  name: t("label.created"),
                  selector: (row) => {
                    if (isValidElement(row.created_at)) {
                      return row.created_at;
                    } else {
                      return moment(row.created_at).format("L");
                    }
                  }
                },
                // Commented until this can be implemented properly
                // {
                //   name: "Comment",
                //   selector: (row) => row.comment,
                //   grow: 1
                // },
                {
                  name: "",
                  selector: function apiTokenActions(row) {
                    return <Grid container className={classes.verticalAlign}>
                      {/* <IconButton aria-label="copy" onClick={() => updateAPIKeyComment(element)}>
                        <EditIcon classes={{root: classes.smallIcon}} />
                      </IconButton> */}
                      {/* <IconButton aria-label="copy" onClick={() => deleteAPIKey(row.reference_id)}>
                        <DeleteIcon classes={{root: classes.smallIcon}} />
                      </IconButton> */}
                      {/* remove the div below only when placing back the delete button */}
                      <div />
                      <ToggleSwitch
                        checked={row.enabled || false}
                        handleChange={(checked) => disableAPIKey(row.reference_id, checked)}
                        disabled={!canUpdate}
                      />
                    </Grid>;
                  },
                  grow: 2
                }
              ]}
            />
          </Card>
          <WebhooksSelector webhookOptions={webhookOptions} canUpdate={canUpdate} />
          <WebhooksInspector />
        </GridItem>
      </Grid>
    </div>
  );
}

LsyDevelopers.propTypes = {
  tenantId: PropTypes.string.isRequired
};

export default LsyDevelopers;