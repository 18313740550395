import { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Can } from "@casl/react";

import { notificationTypeConstants } from "_constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { convertToInt, hasAbility, lsyRouter } from "_helpers";
import { notificationService, userService, accessRequestService } from "_services/lockstasy";

//icons
import VpnKeyIcon from "@mui/icons-material/VpnKeyRounded";
import ListIcon from "@mui/icons-material/List";

//moment
import moment from "moment";
import "moment/locale/en-ca";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/fr";

// @mui/material components
import { Grid } from "@mui/material";

//components
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import NotificationCard from "_components/Lockstasy/NotificationCard";
import NotificationCardSkeleton from "_components/Lockstasy/NotificationCardSkeleton";
import LsyRouter from "_components/Navigation/LsyRouter";
import CardListWidget from "_containers/Widgets/CardListWidget";
import LicenseExpireAlertWidget from "_containers/Widgets/LicenseExpireAlertWidget";
import OpenLocksTable from "_containers/Widgets/OpenLocksTable";
import TableWidget from "_containers/Widgets/TableWidget";
import GridItem from "components/Grid/GridItem";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lsyDashboardStyle.js";

const useStyles = makeStyles()(styles);

function LsyDashboard(props) {
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const { classes } = useStyles();
  const { t } = useTranslation(["default", "auth"]);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const includeSites = hasAbility(currentMembership, "view", "lock_collections") && ability.can("read", "lock_collections");

  

  const requestFormatter = (data) => {
    let headers = [
      { id: "Lock", label: t("widgetField.lock") },
      { id: "User", label: t("widgetField.user") },
      { id: "Date", label: t("widgetField.date") }
    ];

    if (includeSites) {
      headers.unshift({
        id: "Site",
        label: t("widgetField.site")
      });
    }

    const tableData = data.map((data) => {
      let tempData = {
        Lock: {
          text: data?.lock?.name
        },
        User: {
          text: data.requestor.first_name + " " + data.requestor.last_name
        },
        Date: {
          text: moment(data.updated_at).format("ll h:mm:ss a")
        },
        rowAction: () => {
          props.history.push(lsyRouter("access_request", data.id));
        }
      };

      if (includeSites) {
        tempData = {
          Site: {
            text: data?.site?.name || t("widgetField.unassigned")
          },
          ...tempData
        };
      }
      return tempData;
    });
    return [headers, tableData];
  };

  const fetchNotifications = async (options) => {
    try {
      if (options.membership_id) {
        options.membership_id = options.membership_id.id;
      }
      if (options.updated_by) {
        options.updated_by = options.updated_by.id;
      }
      if (options.lock_id) {
        let formattedId = options.lock_id;
        formattedId.replace("0x", "");
        formattedId = convertToInt(formattedId);
        formattedId = `${formattedId}`.trim();
        options.lock_id = formattedId;
      }

      if (options.type && Array.isArray(JSON.parse(options.type))) {
        options.types = JSON.stringify(options.type);
        options.type = null;
      }
      options.start_date = moment(options.start_date).startOf("day");
      options.end_date = moment(options.end_date).endOf("day");
      options["ignore_types"] = "[8]";
      const result = await notificationService.fetchNotifications(options);
      return result;
    } catch (e) {
      console.warn(e);
    }
  };

  const fetchUsersWithInput = async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ current_page: 1, page_size: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})` } : null);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
    }
  };

  const notificationFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset, showDismissed) => {
    return data.map((notification) => {
      return (
        <NotificationCard
          key={notification.id}
          alert={notification}
          history={props.history}
          setFilterVariables={setFilterVariables}
          createModal={createModal}
          fetchWidgetData={fetchWidgetData}
          setValue={setValue}
          state={state}
          setState={setState}
          reset={reset}
          showDismissed={showDismissed}
        />
      );
    });
  };

  const getFilterOptions = () => {
    const options = [
      {
        field: "membership_id",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("form.originator"),
        async: true,
        autoFocus: true,
        promiseOptions: fetchUsersWithInput,
        asyncProps: { defaultOptions: true }
      },
      {
        field: "updated_by",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("label.dismissedRestoredBy"),
        async: true,
        promiseOptions: fetchUsersWithInput,
        asyncProps: { defaultOptions: true }
      },
      {
        field: "lock_id",
        type: "textField",
        label: t("form.lockId"),
        submitOnEnter: true,
        validateEntry: (entry) => {
          if (entry.split(",").length > 1) {
            return false;
          } else {
            return true;
          }
        }
      },
      {
        field: "sort_by",
        type: "select",
        label: t("form.sortNotificationsBy"),
        defaultValue: "created_at,desc",
        options: [
          { name: t("label.createTime"), value: "created_at,desc" },
          { name: t("label.eventOccurrence"), value: "timestamp,desc" },
          { name: t("label.lastUpdateTime"), value: "updated_at,desc" }
        ]
      },
      {
        field: "type",
        type: "select",
        label: t("label.notificationType"),
        defaultValue: " ",
        options: [
          { name: t("label.allNotifications"), value: " " },
          { name: t("label.latchSensor"), value: 1 },
          { name: t("label.doorSensor"), value: 2 },
          { name: t("widgetField.lowBattery"), value: 3 },
          { name: t("label.lockNote"), value: 7 },
          { name: t("label.accessRequest"), value: 4 },
          { name: t("label.workSessionNotConfirmed"), value: 5 },
          { name: t("label.workSessionTimeExceeded"), value: 6 },
          { name: t("label.customLock"), value: 9 },
          { name: t("label.customUser"), value: 10 },
          { name: t("mfa.mfa", { ns: "auth" }), value: "[20,21]" },
          { name: t("userNotifications.name.lockReplacement"), value: "[11,12,13]" },
          { name: t("userNotifications.name.lockDeactivation"), value: "[22,23]" }
        ]
      },
      {
        field: "start_date",
        type: "datePicker",
        label: t("label.startDate"),
        defaultValue: moment().subtract(1, "months"),
        disableFuture: true
      },
      {
        field: "end_date",
        type: "datePicker",
        label: t("label.endDate"),
        defaultValue: moment(),
        disableFuture: true
      }];
    return options;
  };

  const renderLicenseExpireAlert = () => {
    return <GridItem xs={12}>
      <LicenseExpireAlertWidget/>
    </GridItem>;
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        { renderLicenseExpireAlert() }
        <Can I="read" a="lock_status" ability={ability}>
          <GridItem xs={12} md={10} lg={6} className={classes.tableContainer}>
            <OpenLocksTable history={props.history}/>
          </GridItem>
        </Can>
        <GridItem xs={12} md={10} lg={ability.can("read", "lock_status") ? 6 : 10}>
          <Can I="read" an="access_requests" ability={ability}>
            <ErrorBoundary>
              <TableWidget
                paginate
                refresh
                icon={<VpnKeyIcon className={classes.widgetIcon} />}
                title={t("widgetField.pendingRequests")}
                titleAction={() => props.history.push(lsyRouter("access_requests"))}
                rowsPerPage={5}
                fetchData={(options) => accessRequestService.fetchRequests({ ...options, status: 0 })}
                dataFormatter={requestFormatter}
                fallbackData={
                  <div>
                    {t("fallbacks.accessRequests1")}
                    <LsyRouter className={classes.actionLink} page="access_requests">
                      {t("fallbacks.accessRequests2")}
                    </LsyRouter>
                  </div>
                }
              />
            </ErrorBoundary>
          </Can>
          <Can I="read" a="notifications" ability={ability}>
            <div className={classes.notificationWidget}>
              <ErrorBoundary>
                <CardListWidget
                  skeletonCard={<NotificationCardSkeleton />}
                  fallbackData={<Placeholder message={t("fallbacks.notifications")} icon={<ListIcon/>}/>}
                  paginate
                  title={t("label.notifications")}
                  enableFilter
                  removeEmptyOptions
                  filterOptions={getFilterOptions()}
                  fielterDefaultValues={{ membership_id: "", updated_by: "", sort_by: "created_at,desc", type: " ", start_date: moment().subtract(1, "months"), end_date: moment(), lock_id: "" }}
                  rowsPerPage={10}
                  secondPaginate={true}
                  fetchData={fetchNotifications}
                  dataFormatter={notificationFormatter}
                  events={["notification"]}
                  eventTypes={Object.values(notificationTypeConstants).filter((type) => type !== notificationTypeConstants.OPEN_LOCK)}
                  appBarSize="sm"
                  widget="notification"
                  baseUrl={props.baseUrl}
                  history={props.history}
                  location={props.location}
                />
              </ErrorBoundary>
            </div>
          </Can>
        </GridItem>
      </Grid>
    </div>
  );
}

LsyDashboard.propTypes = {
  history: PropTypes.object,
  baseUrl: PropTypes.string,
  location: PropTypes.object
};

export default LsyDashboard;
