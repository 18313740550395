/* eslint-disable no-unused-vars */
import api, { get, post } from "_helpers/axios/gw.client";
import { isBlank } from "_helpers";
import { lockdepotUrl } from "_services/helper";

const fetchFirmwares = async (options) => {
  const requestOptions = Object.fromEntries(Object.entries(options).filter(([_, v]) => !isBlank(v)));
  return get(`${lockdepotUrl}/firmwares`, requestOptions);
};

const fetchFirmwareData = async (firmwareId) => {
  const requestOptions = {
    include: "stats"
  };
  return get(`${lockdepotUrl}/firmwares/${firmwareId}`, requestOptions);
};

const createFirmware = async (options) => {
  const { version, hardwareType, lockType, file, fileName, component } = options;
  let formData = new FormData();
  formData.append("data", file);
  formData.append("file_name", fileName);
  formData.append("version", version);
  formData.append("hardware_type", hardwareType);
  formData.append("lock_type", lockType);
  formData.append("component", component);

  return api({ method: "POST", url: `${lockdepotUrl}/firmwares`, data: formData, headers: { "Content-Type": "multipart/form-data" } });
};

const deployFirmware = async (options) => {
  const firmwareId = options.firmwareId;
  delete options.firmwareId;
  const requestOptions = Object.fromEntries(Object.entries(options).filter(([_, v]) => !isBlank(v)));

  return post(`${lockdepotUrl}/firmwares/${firmwareId}/deploy`, requestOptions);
};

const fetchBundles = async (options) => {
  return get(`${lockdepotUrl}/firmware_bundles`, options);
};

const fetchBundlesData = async (bundleId) => {
  const requestOptions = {
    include: "stats"
  };
  return get(`${lockdepotUrl}/firmware_bundles/${bundleId}`, requestOptions);
};

const createBundle = (firmwareIds) => {
  return post(`${lockdepotUrl}/firmware_bundles`, { firmware_ids: firmwareIds });
};

export const firmwareService = {
  fetchFirmwares,
  fetchFirmwareData,
  createFirmware,
  deployFirmware,
  fetchBundles,
  fetchBundlesData,
  createBundle
};
