import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { config } from "_configs/server-config";
import { versionService } from "_services/lockstasy";

import {
  Grid
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";
import LsyRouter from "_components/Navigation/LsyRouter";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/lsyFooterStyle.js";

export default function LsyFooter(props) {
  const useStyles = makeStyles()(styles);
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const { org } = props;
  const [data, setData] = useState(null);

  const fetchFooterData = async () => {
    try {
      const result = await versionService.fetchVersion();
      setData(result.data);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (org) {
      fetchFooterData();
    }
  }, [org]);

  return <ErrorBoundary>
    <Grid className={classes.lsyFooter} container justifyContent="center" alignItems="center" spacing={0.5}>
      <Grid item xs={11} md="auto" className={classes.footer}>
        <span className={classes.footerText}>
          {t("footer.version", {
            version: `${config.swVersion} [${data?.version}/${data?.sha}]`
          })}
        </span>
      </Grid>
      <Grid item xs={11} md="auto" className={classes.footer}>
        <span className={classes.footerText}>
          {t("footer.statement", {
            copyright: data?.copyright
          })}
        </span>
      </Grid>
      <Grid item sx={{ display: { xs: "none", md: "block" } }} className={classes.footer}>
        <span className={classes.footerItems}>|</span>
      </Grid>
      <Grid item className={classes.footer}>
        <a
          className={classes.footerLinks}
          href="http://www.sera4.com/terms-of-use/"
        >
          {t("footer.termsOfService")}
        </a>
      </Grid>
      <Grid item className={classes.footer}>
        <span className={classes.footerItems}>|</span>
        <a
          className={classes.footerLinks}
          href="http://www.sera4.com/privacy-policy/"
        >
          {t("footer.privacy")}
        </a>
      </Grid>
      <Grid item className={classes.footer}>
        <span className={classes.footerItems}>|</span>
        <LsyRouter page="about" className={classes.footerLinks}>
          {t("footer.about")}
        </LsyRouter>
      </Grid>
    </Grid>
  </ErrorBoundary>;
}
LsyFooter.propTypes = {
  org: PropTypes.string
};
